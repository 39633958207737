import { makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IGod } from "../../Gods/Services/GodsService";
import { IQuestionsGroup } from "../../Questions/Services/QuestionsService";
import { IUser } from "../../Settings/Services/SystemUsersService";

export interface IFormula {
    id?: string;
    baziYearTg?: number;
    baziYearDz?: number;
    baziMonthTg?: number;
    baziMonthDz?: number;
    baziDayTg?: number;
    baziDayDz?: number;
    baziHourTg?: number;
    baziHourDz?: number;
    mingGuaM?: number;
    mingGuaF?: number;
    god: number;
    questionGroup: number;
    isDelete?: boolean;
    createAt?: Date;
    updateAt?: Date;
    createBy?: string;
    updateBy?: string;
}

export interface IFormulaViewData {
    id: string;
    baziYearTg: number;
    baziYearDz: number;
    baziMonthTg: number;
    baziMonthDz: number;
    baziDayTg: number;
    baziDayDz: number;
    baziHourTg: number;
    baziHourDz: number;
    mingGuaM: number;
    mingGuaF: number;
    god: IGod;
    questionGroup: IQuestionsGroup;
    isDelete?: boolean;
    createAt?: Date;
    updateAt?: Date;
    createBy?: IUser;
    updateBy?: IUser;
}

export interface IFormulaView {
    datas: IFormulaViewData[];
    all: number;
    limit: number;
    page: number;
    total: number;
}

export interface IFormulaCriteria {
    god: number;
    questionGroup: number;
    page: number;
    limit: number;
}
class FormulasService {
    formulas: IFormulaView = {} as IFormulaView;
    constructor() {
        makeAutoObservable(this);
    }

    async getAll() {
        try {
            const response = await apiService.get("/omg-formula");
            if (response.length > 0) {
                this.formulas = {
                    datas: response,
                    all: response.length,
                    limit: 10,
                    page: 1,
                    total: response.length
                };
            }
        } catch (error) {
            return error;
        }
    }

    async getOne(id: string) {
        try {
            const response = await apiService.get(`/omg-formula/${id}`);
            return response;
        } catch (error) {
            return error;

        }
    }

    async getByCriteria(criteria: IFormulaCriteria) {
        try {
            const response = await apiService.post(`/omg-formula/criteria`, criteria);
            if (response.data) {
                this.formulas = {
                    datas: response.data,
                    all: response.all,
                    limit: response.limit,
                    page: response.page,
                    total: response.total
                };
            }
            return this.formulas;
        } catch (error) {
            return error;
        }
    }

    async create(data: IFormula) {
        try {
            let response = '';
            await apiService.post("/omg-formula", data).then(async (result) => {
                response = result;
            });
            return response;
        } catch (error) {
            return error;
        }
    }

    async update(id: string, data: IFormula) {
        try {
            let result = Object.fromEntries(
                Object.entries(data).map(([key, value]) => [key, value === undefined ? null : value])
            );
            const response = await apiService.put(`/omg-formula/${id}`, result);
            return response;
        } catch (error) {
            return error;
        }
    }

    async delete(id: string) {
        try {
            const response = await apiService.delete(`/omg-formula/${id}`);
            return response;
        } catch (error) {
            return error;
        }
    }
}

const formulasService = new FormulasService();
export default formulasService;