import { makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IUser } from "../../Settings/Services/SystemUsersService";


export interface IPackage {
  id?: string;
  name: string;
  type: string;
  price: number;
  isDelete?: boolean;
  isActive?: boolean;
  store: string;
  createBy?: string;
  updateBy?: string;
  createAt?: Date;
  updateAt?: Date;

  productId?: string;
  quantity?: number;
  quantityRemind?: number;
  description?: string;
}

export interface IPackageType {
  id: string;
  name: string;
  duration: number;
  createBy: string;
  updateBy: string;
  createAt: Date;
  updateAt: Date;
}

export interface IPackageViewData {
  id?: string;
  name: string;
  type: IPackageType;
  price: number;
  isDelete?: boolean;
  isActive?: boolean;
  store: string;
  createBy?: IUser;
  updateBy?: IUser;
  createAt?: Date;
  updateAt?: Date;

  productId?: string;
  quantity?: number;
  quantityRemind?: number;
  description?: string;
}

export interface IPackageCriteria {
  name: string;
  type: number;
  isActive: boolean;
  store: string;
  page: number;
  limit: number;
}

export interface IPackageView {
  datas: IPackageViewData[];
  all: number;
  limit: number;
  page: number;
  total: number;
}

export interface IPackageUpdateIsActiveChecked {
  isActive: boolean;
  updateAt: Date;
}



class PackagesService {
  packages: IPackageView = {} as IPackageView;
  packagesType: IPackageType[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/packages");
      if (response.length > 0) {
        this.packages = {
          datas: response,
          all: response.length,
          limit: 10,
          page: 1,
          total: response.length
        };
      }
      return this.packages;
    } catch (error) {
      throw error;
    }
  }

  async getOne(id: string) {
    try {
      const response = await apiService.get(`/packages/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IPackageCriteria) {
    try {
      const response = await apiService.post("/packages/criteria", criteria);
      this.packages = {
        datas: response.data,
        all: response.length,
        limit: response.limit,
        page: response.page,
        total: response.total
      };
      return this.packages;
    } catch (error) {
      throw error;
    }
  }

  async create(data: IPackage) {
    try {
      let response = '';
      await apiService.post("/packages", data).then(async (result) => {
        response = result;
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async update(id: string, data: IPackage) {
    try {
      const response = await apiService.put(`/packages/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }


  async deletePackage(id: string) {
    try {
      const data = { isDelete: true };
      const response = await apiService.put(`/packages/delete/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getPackageTypes() {
    try {
      const response = await apiService.get("/packages/package-type/all");
      if (response.length > 0) {
        this.packagesType = response;
      } else {
        this.packagesType = [];
      }
      return this.packagesType;
    } catch (error) {
      throw error;
    }
  }


  async updateIsActiveChecked(id: string, data: IPackageUpdateIsActiveChecked) {
    try {
      const response = await apiService.put(`/packages/${id}`, data);
      return response;
    } catch (error) {
      return error;
    }
  }
}

const packagesService = new PackagesService();
export default packagesService;
