import { FC, useEffect, useState } from "react";
import FormulasService, {
  IFormula,
  IFormulaCriteria,
  IFormulaView,
} from "../Services/FormulasService";
import { enqueueSnackbar } from "notistack";
import GodsService, { IGod } from "../../Gods/Services/GodsService";
import FormulasListView from "../Views/FormulasListView";
import { IQuestionsGroup } from "../../Questions/Services/QuestionsService";
import questionsGroupService from "../../Questions/Services/QuestionsGroupService";

interface FormulasListControllerProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการจัดการข้อมูล
}

const FormulasListController: FC<FormulasListControllerProps> = (props) => {
  const [data, setData] = useState<IFormula>();
  const [formulas, setFormulas] = useState<IFormulaView>();
  const [groups, setGroups] = useState<IQuestionsGroup[]>([]);
  const [gods, setGods] = useState<IGod[]>([]);

  useEffect(() => {
    if (!localStorage.getItem("limit")) {
      localStorage.setItem("limit", "10");
    }
    const criteria: IFormulaCriteria = {
      god: 0,
      questionGroup: 0,
      page: 0,
      limit: 10,
    };
    setFormulas({} as IFormulaView);
    searchByCriteria(criteria);
    getGods();
    getQuestionGroup();
  }, []);

  useEffect(() => {
    setData(data);
  }, [data]);

  const getOne = async (id: string) => {
    const res = await FormulasService.getOne(id);
    if (res) {
      setData(res);
    } else {
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };
  const searchByCriteria = async (criteria: IFormulaCriteria) => {
    setFormulas({} as IFormulaView);
    const datas = await FormulasService.getByCriteria(criteria);
    if (datas) {
      setFormulas(datas as IFormulaView);
    } else {
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const getGods = async () => {
    await GodsService.getAll().then((res: any) => {
      if (res.datas) {
        setGods(res.datas);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    });
  };

  const getQuestionGroup = async () => {
    await questionsGroupService.getAll().then((res: any) => {
      if (res) {
        setGroups(res);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    });
  };

  const create = async (data: IFormula) => {
    await FormulasService.create(data).then((res: any) => {
      if (res) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        const _god: number = parseInt(localStorage.getItem("god") || "0");
        const _group: number = parseInt(localStorage.getItem("group") || "0");
        const _limit: number = parseFloat(
          localStorage.getItem("limit") || "10"
        );
        searchByCriteria({
          god: _god,
          questionGroup: _group,
          page: 0,
          limit: _limit,
        });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    });
  };

  const update = async (id: string, data: IFormula) => {
    await FormulasService.update(id, data).then((res) => {
      if (res) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        const _god: number = parseInt(localStorage.getItem("god") || "0");
        const _group: number = parseInt(localStorage.getItem("group") || "0");
        const _limit: number = parseFloat(
          localStorage.getItem("limit") || "10"
        );
        searchByCriteria({
          god: _god,
          questionGroup: _group,
          page: 0,
          limit: _limit,
        });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    });
  };

  const deleteFormula = async (id: string) => {
    await FormulasService.delete(id).then((res) => {
      if (res) {
        enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
        const _god: number = parseInt(localStorage.getItem("god") || "0");
        const _group: number = parseInt(localStorage.getItem("group") || "0");
        const _limit: number = parseFloat(
          localStorage.getItem("limit") || "10"
        );
        searchByCriteria({
          god: _god,
          questionGroup: _group,
          page: 0,
          limit: _limit,
        });
      } else {
        enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    });
  };
  return (
    <FormulasListView
      formulas={formulas ?? ({} as IFormulaView)}
      onSearch={(criteria: IFormulaCriteria) => {
        searchByCriteria(criteria);
      }}
      onDelete={(id: string) => deleteFormula(id)}
      getOne={(id: string) => {
        getOne(id);
      }}
      onSubmit={(data: IFormula) => {
        if (data?.id) {
          update(data?.id, data);
        } else {
          create(data);
        }
      }}
      data={data ?? ({} as IFormula)}
      groups={groups ?? ([] as IQuestionsGroup[])}
      gods={gods ?? ([] as IGod[])}
    />
  );
};

export default FormulasListController;
