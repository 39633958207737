import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FC, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import dayjs from "dayjs";
import {
  IUserCustomer,
  IUserCustomerHistory,
  IUserCustomerHistoryCriteria,
  IUserCustomerHistoryView,
} from "../Services/UserCustomersServices";

interface UserCustomersHistoryProps {
  userHistory: IUserCustomerHistoryView;
  searchByCiteria: (criteria: IUserCustomerHistoryCriteria) => void;
  customer: IUserCustomer;
}

const UserCustomersHistoryView: FC<UserCustomersHistoryProps> = (props) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const searchByCiteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber === 0 ? 0 : pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IUserCustomerHistoryCriteria = { page: pageNumber, limit: rowLimit ? rowLimit : 10 };
    props.searchByCiteria(criteria);
  };
  return (
    <Box component={"div"}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/users-customers-list")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>ผู้ใช้งาน</Typography>
            <Link
              component="button"
              onClick={() => navigate("/users-customers-list")}
            >
              รายชื่อลูกค้า
            </Link>
            <Typography>ประวัติการใช้งาน</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={6} lg={3} >
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <Typography fontWeight={"bold"}>ชื่อลูกค้า:</Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant="body1">
                        {props.customer ? props.customer.name : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} lg={3}>
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <Typography fontWeight={"bold"}>วันเกิด:</Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant="body1">
                        {props.customer
                          ? dayjs(props.customer.birthDate).format("DD/MM/YYYY")
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} lg={3}>
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <Typography fontWeight={"bold"}>ช่วงเวลาเกิด:</Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant="body1">
                        {props.customer
                          ? `${props.customer.birthTimeStart}  -  ${props.customer.birthTimeEnd}`
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} lg={3}>
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <Typography fontWeight={"bold"}>เพศ:</Typography>
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant="body1">
                        {props.customer
                          ? props.customer.gender === "M"
                            ? "ชาย"
                            : "หญิง"
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">วันที่ขอ</TableCell>
                          <TableCell align="center">เทพที่ขอ</TableCell>
                          <TableCell align="center">กลุ่มคำถาม</TableCell>
                          <TableCell align="center">ประเภทคำถามที่ขอ</TableCell>
                          <TableCell align="center">คำถาม</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.userHistory &&
                          props.userHistory.datas.length > 0 ? (
                          props.userHistory.datas.map(
                            (row: IUserCustomerHistory) => (
                              <TableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="center">
                                  {row.createAt
                                    ? dayjs(row.createAt).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                    : "-"}
                                </TableCell>

                                <TableCell align="center">
                                  {/* TODO: uncomment when god is ready */}
                                  {/* {row.god ? (row.god as any).title  : '' } */}
                                  Gods
                                </TableCell>
                                <TableCell align="center">
                                  {row.questionGroup
                                    ? (row.questionGroup as any).name
                                    : "-"}
                                </TableCell>
                                <TableCell align="center">
                                  {row.questionType
                                    ? (row.questionType as any).name
                                    : ""}
                                </TableCell>
                                <TableCell align="center">
                                  {row.question
                                    ? (row.question as any).name
                                    : ""}
                                </TableCell>
                              </TableRow>
                            )
                          )
                        ) : (
                          <TableRow>
                            <TableCell colSpan={5} align="center">
                              ไม่พบข้อมูล
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={props.userHistory ? props.userHistory.total : 0}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        searchByCiteria(newPage);
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setLimit(e.target.value);
                        setPage(0);
                        searchByCiteria(page, e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserCustomersHistoryView;
