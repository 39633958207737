import { makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { ISystemUserViewData, IUser } from "../../Settings/Services/SystemUsersService";

export interface IQuestionType {
    id?: number;
    name: string;
    order:number;
    image?: string;
    isDelete?: boolean;
    createBy?: string;
    updateBy?: string;
    createAt?: Date;
    updateAt?: Date;
}

export interface IQuestionTypeViewData {
    id?: number;
    name: string;
    order:number;
    image?: string;
    isDelete?: boolean;
    createBy?: IUser;
    updateBy?: IUser;
    createAt?: Date;
    updateAt?: Date;
}

export interface IUploadFile{
    file: any;
}
export interface IQuestionTypeCriteria{
    name: string
    page: number   
    limit: number
}

export interface IQuestionTypeView {
    datas: IQuestionTypeViewData[];
    all: number;
    page: number;
    limit: number;
    total: number;
}

class QuestionTypeService {
    questionsType: IQuestionTypeView = {} as IQuestionTypeView;
    user:ISystemUserViewData | null = null;
    constructor() {
        makeAutoObservable(this);
        this.user=JSON.parse(localStorage.getItem("user") || '{}');
    }

    async getAll() {
        try {
            const response = await apiService.get("/questions/questionType");
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getOne(id: string) {
        try {
            const response = await apiService.get(`/questions/questionType/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getByCriteria(criteria: IQuestionTypeCriteria) {
        try {
            const response = await apiService.post("/questions/questionType/criteria", criteria);
            this.questionsType = {
                datas: response.data,
                all: response.length,
                limit: response.limit,
                page: response.page,
                total: response.total
            }
            return this.questionsType
        } catch (error) {
            throw error;
        }
    }

    async create(data: IQuestionType,uploadFile: IUploadFile) {
        try {
           let response = '';
           await apiService.post("/questions/questionType", data).then(async (result) => {
            const formData = new FormData();
            formData.append('file', uploadFile.file);
            formData.append('refId', result.id);
            formData.append('refType', 'questionType');
            formData.append('createBy',  this.user?.id || 'admin');
            formData.append('createAt', new Date(Date.now()).toISOString());
            await apiService.postFormData("/images/upload", formData).then((res) => {
                if(res){
                    response = res
                }
            })
               
           })
           return response;
        } catch (error) {
            throw error;
        }
    }

    async update(id:string,data: IQuestionType) {
        try {
            const response = await apiService.put(`/questions/questionType/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async uploadImage(id: string, uploadFile: IUploadFile) {
        try {
            const formData = new FormData();
            formData.append('file', uploadFile.file);
            formData.append('refId', id);
            formData.append('refType', 'questionType');
            formData.append('createBy',  this.user?.id || 'admin');
            formData.append('createAt', new Date(Date.now()).toISOString());
            const response = await apiService.postFormData("/images/upload", formData);
            return response;
        } catch (error) {
            return error;
        }
    }




    async delete(id: string) {
        try {
            const data = {isDelete:true}
            const response = await apiService.put(`/questions/questionType/delete/${id}`,data);
            return response;
        } catch (error) {
            return error;
        }
      
    }
}

const questionTypeService = new QuestionTypeService();
export default questionTypeService;