import { FC, useEffect, useState } from "react";
import QuestionsGroupCreateView from "../../Views/Question-group/QuestionGroupCreateView";
import { enqueueSnackbar } from "notistack";
import { useParams, useNavigate } from "react-router-dom";
import questionsGroupService, {
  IQuestionsGroupViewData,
  IQuestionsGroup,
} from "../../Services/QuestionsGroupService";

interface QuestionGroupCreateControllerProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการจัดการข้อมูล
}

const QuestionGroupCreateController: FC<
  QuestionGroupCreateControllerProps
> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<IQuestionsGroupViewData>();

  useEffect(() => {
    if (id) {
      getOne(id);
    }
  }, [id]);

  const create = async (data: IQuestionsGroup) => {
    await questionsGroupService
      .create(data)
      .then((res: any) => {
        if (res) {
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
          navigate(`/question-group-edit/ ${res.id}`);
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const update = async (id: string, data: IQuestionsGroup) => {
    await questionsGroupService
      .update(id, data)
      .then((res) => {
        if (res) {
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const getOne = async (id: string) => {
    try {
      const res = await questionsGroupService.getOne(id);
      if (res) {
        setData(res);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const deleteQuestionGroup = async (id: string) => {
    await questionsGroupService
      .delete(id)
      .then((res) => {
        if (res) {
          enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
          navigate("/questions-group-list");
        } else {
          enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  return (
    <QuestionsGroupCreateView
      getOne={getOne}
      onCreate={(data: IQuestionsGroup) => create(data)}
      onUpdate={(id: string, data: IQuestionsGroup) => update(id, data)}
      onDelete={(id: string) => deleteQuestionGroup(id)}
      data={data ?? ({} as IQuestionsGroupViewData)}
      questionGroupId={id ?? ""}
    />
  );
};

export default QuestionGroupCreateController;
