import { FC, useEffect, useState } from "react";
import UserPaymentsHistoryView from "../Views/UserPaymentsHistoryView";
import userPaymentsService, {
  IUserPaymentHistoryCriteria,
  IUserPaymentHistoryView,
} from "../Services/UserPaymentsServices";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { IUserCustomer } from "../Services/UserCustomersServices";

interface UserPaymentsHistoryProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการจัดการข้อมูล
}

const UserPaymentsHistoryController: FC<UserPaymentsHistoryProps> = (props) => {
  const [userPaymentsHistory, setUserPaymentsHistory] = useState<
    IUserPaymentHistoryView | {}
  >();
  const [customer, setCustomer] = useState<IUserCustomer>();
  const { id } = useParams();

  useEffect(() => {
    const criteria: IUserPaymentHistoryCriteria = {
      userId: id ?? "",
      from: undefined,
      to: undefined,
      page: 0,
      limit: 10,
    };
    searchByCriteria(criteria);
    getCustomer(id ?? "");
  }, [id]);

  const searchByCriteria = async (criteria: IUserPaymentHistoryCriteria) => {
    try {
      const datas = await userPaymentsService.getUserPaymentHistoryByCriteria(
        criteria
      );
      if (datas) {
        setUserPaymentsHistory(datas as IUserPaymentHistoryView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const getCustomer = async (id: string) => {
    const res = await userPaymentsService.getCustomerData(id ?? "");
    if (res) {
      setCustomer(res);
    }
  };

  return (
    <UserPaymentsHistoryView
      userId={id ?? ""}
      userPaymentHistory={userPaymentsHistory as IUserPaymentHistoryView}
      onSearch={(criteria: IUserPaymentHistoryCriteria) => {
        searchByCriteria(criteria);
      }}
      customer={customer ?? ({} as IUserCustomer)}
    />
  );
};

export default UserPaymentsHistoryController;
