import { FC, useEffect, useState } from "react";
import NotificationsListView from "../Views/NotificationsListView";
import notificationsService, {
  INotification,
  INotificationCriteria,
  INotificationView,
} from "../Services/NotificationsService";
import { enqueueSnackbar } from "notistack";
import notificationTypesService, {
  INotificationTypes,
} from "../Services/NotificationTypesService";

interface NotificationsListControllerProps {
  // Define your view props here
}

const NotificationsListController: FC<NotificationsListControllerProps> = (
  props
) => {
  const [notifications, setNotifications] = useState<INotificationView>();
  const [types, setTypes] = useState<INotificationTypes[]>();

  useEffect(() => {
    const citeria: INotificationCriteria = {
      title: "",
      content: "",
      type: 0,
      page: 0,
      limit: 10,
    };
    searchByCiteria(citeria);
    getTypes();
  }, []);

  const searchByCiteria = async (criteria: INotificationCriteria) => {
    try {
      const datas = await notificationsService.getByCriteria(criteria);
      if (datas) {
        setNotifications(datas as INotificationView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const getTypes = async () => {
    try {
      const datas = await notificationTypesService.getAll();
      if (datas) {
        setTypes(datas as INotificationTypes[]);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const update = async (id: string, data: INotification) => {
    await notificationsService
      .update(id, data)
      .then((res) => {
        if (res) {
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  return (
    <NotificationsListView
      notifications={notifications ?? ({} as INotificationView)}
      onSearch={(criteria: INotificationCriteria) => {
        searchByCiteria(criteria);
      }}
      onUpdate={update}
      types={types ?? ([] as INotificationTypes[])}
    />
  );
};

export default NotificationsListController;
