import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  IPromotionCriteria,
  IPromotionType,
  IPromotionView,
} from "../Services/PromotionsService";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface PromotionsListViewProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการแสดง UI
  getAll: () => void;
  promotions: IPromotionView;
  onSearch: (criteria: IPromotionCriteria) => void;
  types: IPromotionType[];
  onUpdateIsActive: (id: string, isActive: boolean) => void;
}

const PromotionsListView: FC<PromotionsListViewProps> = (props) => {
  const [promotionName, setPromotionName] = useState("");
  const [type, setType] = useState("all");
  const [isActive, setIsActive] = useState(true);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(
    dayjs().startOf("month")
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs());

  const navigate = useNavigate();

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber === 0 ? 0 : pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IPromotionCriteria = {
      name: promotionName,
      type: type === "all" ? "" : type,
      startDate: startDate?.toDate(),
      endDate: endDate?.toDate(),
      isActive: isActive,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSearch(criteria);
  };

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setEndDate(date);
    }
  };
  return (
    <Box component={"div"}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>โปรโมชั่น</Typography>
        <Typography variant="h6" color="text.primary">
          รายการ
        </Typography>
      </Breadcrumbs>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={2.5}>
                  <TextField
                    fullWidth
                    label="ชื่อโปรโมชั่น"
                    onChange={(e) => setPromotionName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="promotionType-select-label">
                      ประเภท
                    </InputLabel>
                    <Select
                      labelId="promotionType-select-label"
                      value={type}
                      id="promotionType-select"
                      label="ประเภท"
                      onChange={(e) => {
                        setType(e.target.value as string);
                      }}
                    >
                      <MenuItem value="all">ทั้งหมด</MenuItem>
                      {props.types && props.types.length > 0 ? (
                        props.types.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>ไม่พบข้อมูล</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="ตั้งแต่วันที่"
                      sx={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="ถึงวันที่"
                      sx={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      value={endDate}
                      onChange={handleEndDateChange}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={1.5} textAlign={"center"} alignSelf={"center"}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isActive}
                          onChange={(e) => setIsActive(e.target.checked)}
                        />
                      }
                      label="ใช้งานอยู่"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={2} textAlign={"center"} alignSelf={"center"}>
                  <Button
                    variant="contained"
                    onClick={() => searchByCriteria(0)}
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography>
                        ทั้งหมด {props.promotions.total} รายการ
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      alignContent={"flex-end"}
                      textAlign={"end"}
                    >
                      <Button
                        variant="contained"
                        startIcon={<NoteAddIcon />}
                        onClick={() => navigate("/promotion-create")}
                      >
                        สร้าง
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">ชื่อโปรโมชั่น</TableCell>
                          <TableCell align="center">รายละเอียด</TableCell>
                          <TableCell align="center">ประเภท</TableCell>
                          <TableCell align="center">ราคา</TableCell>
                          <TableCell align="center">จำนวนการใช้งาน</TableCell>
                          <TableCell align="center">ตั้งแต่วันที่</TableCell>
                          <TableCell align="center">ถึงวันที่</TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {props.promotions.datas &&
                        props.promotions.datas.length > 0 ? (
                          props.promotions.datas.map((promotion) => (
                            <TableRow
                              key={promotion.id}
                            >
                              <TableCell align="center">
                                {promotion.name}
                              </TableCell>
                              <TableCell align="center">
                                {promotion.description}
                              </TableCell>
                              <TableCell align="center">
                                {promotion.type?.name}
                              </TableCell>
                              <TableCell align="center">
                                {promotion.price}
                              </TableCell>
                              <TableCell align="center">
                                {promotion.duration}
                              </TableCell>
                              <TableCell align="center">
                                {promotion.startDate
                                  ? dayjs(promotion.startDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </TableCell>
                              <TableCell align="center">
                                {promotion.endDate
                                  ? dayjs(promotion.endDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </TableCell>
                              <TableCell align="center">
                                <Switch
                                  checked={promotion.isActive}
                                  onChange={() => {
                                    props.onUpdateIsActive(
                                      promotion.id!,
                                      !promotion.isActive
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    navigate(`/promotion-edit/${promotion.id}`)
                                  }
                                >
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              <Typography>ไม่พบข้อมูล</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={props.promotions.total ?? 0}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        searchByCriteria(newPage);
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setLimit(e.target.value);
                        setPage(0);
                        searchByCriteria(page, e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PromotionsListView;
