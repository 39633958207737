import React, { ReactNode, useEffect, useState } from "react";
import {
    Toolbar,
    Typography,
    Drawer,
    List,
    ListItemText,
    IconButton,
    Menu,
    MenuItem,
    ListItemButton,
    styled,
    Box,
    CssBaseline,
    Divider,
    ListItemIcon,
    Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import OMG_LOGO from "../res/images/omygod_logo.png";
import FlareOutlinedIcon from "@mui/icons-material/FlareOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import loginService from "../features/Login/Services/LoginService";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import FunctionsIcon from "@mui/icons-material/Functions";
interface LayoutProps {
    children: ReactNode;
}

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

const Layout: React.FC<LayoutProps> = observer(({ children }) => {
    const userData = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user") ?? "")
        : "";
    const [appVersion, setAppVersion] = useState("");
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [isCollapse, setIsCollapse] = useState(false);
    const [menuName, setMenuName] = useState("");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    //TODO - Implement logout
    const handleLogout = () => {
        loginService.logout();
        navigate("/login");
    };

    const profileMenuOpen = Boolean(anchorEl);

    const handleMeneClick = (path: string, isChild: boolean = false) => {
        if (!isChild) {
            if (menuName === path) {
                setIsCollapse(!isCollapse);
            } else if (path === menuName.split("-")[0]) {
                setIsCollapse(!isCollapse);
                setMenuName(path);
            } else {
                setIsCollapse(true);
            }
        }
        setMenuName(path);
    };

    useEffect(() => {
        setAppVersion(process.env.REACT_APP_VERSION || "");
    }, []);

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
                open={isDrawerOpen}
                position="fixed"
                elevation={1}
                sx={{ bgcolor: "white" }}
            >
                <Toolbar>
                    <IconButton
                        aria-label="open drawer"
                        onClick={isDrawerOpen ? handleDrawerClose : handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h5" color={"black"} style={{ flexGrow: 1 }}>
                        { }
                    </Typography>
                    <IconButton onClick={handleProfileMenuOpen} sx={{ color: "black" }}>
                        <AccountCircleIcon />
                        <Typography style={{ marginLeft: "8px" }}>
                            {userData ? `${userData?.firstName} ${userData?.lastName}` : ""}
                        </Typography>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={profileMenuOpen}
                        onClose={handleProfileMenuClose}
                    >
                        <MenuItem
                            onClick={() => {
                                navigate("/profile/" + userData?.id);
                                handleProfileMenuClose();
                            }}
                        >
                            โปรไฟล์
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>ออกจากระบบ</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
                variant="persistent"
                anchor="left"
                open={isDrawerOpen}
            >
                <DrawerHeader sx={{ justifyContent: "center" }}>
                    <Box
                        component={"img"}
                        src={OMG_LOGO}
                        sx={{ width: "80%", height: "80%" }}
                    />
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItemButton
                        onClick={() => {
                            navigate("/dashboard");
                            handleMeneClick("dashboard");
                        }}
                        selected={menuName === "dashboard"}
                    >
                        <ListItemIcon>
                            <DashboardOutlinedIcon sx={{ color: "black" }} />
                        </ListItemIcon>
                        <ListItemText primary="ภาพรวม" />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => {
                            handleMeneClick("gods");
                        }}
                    >
                        <ListItemIcon>
                            <FlareOutlinedIcon sx={{ color: "black" }} />
                        </ListItemIcon>
                        <ListItemText primary="เทพเจ้า" />
                        {(menuName === "gods" || menuName.split("-")[0] === "gods") &&
                            isCollapse ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </ListItemButton>
                    <Collapse
                        in={menuName.split("-")[0] === "gods" && isCollapse}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    navigate("/gods-list");
                                    handleMeneClick("gods-list", true);
                                }}
                                selected={menuName === "gods-list"}
                            >
                                <ListItemIcon>
                                    <ArrowRightIcon fontSize="small" sx={{ color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary="รายการ" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <ListItemButton
                        onClick={() => {
                            handleMeneClick("formulas");
                        }}
                    >
                        <ListItemIcon>
                            <FunctionsIcon sx={{ color: "black" }} />
                        </ListItemIcon>
                        <ListItemText primary="สูตรคำนวณ" />
                        {(menuName === "formulas" ||
                            menuName.split("-")[0] === "formulas") &&
                            isCollapse ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </ListItemButton>
                    <Collapse
                        in={menuName.split("-")[0] === "formulas" && isCollapse}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    navigate("/formulas-list");
                                    handleMeneClick("formulas-list", true);
                                }}
                                selected={menuName === "formulas-list"}
                            >
                                <ListItemIcon>
                                    <ArrowRightIcon fontSize="small" sx={{ color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary="รายการ" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <ListItemButton
                        onClick={() => {
                            handleMeneClick("questions");
                        }}
                    >
                        <ListItemIcon>
                            <QuestionAnswerOutlinedIcon sx={{ color: "black" }} />
                        </ListItemIcon>
                        <ListItemText primary="คำถาม" />
                        {(menuName === "questions" ||
                            menuName.split("-")[0] === "questions") &&
                            isCollapse ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </ListItemButton>
                    <Collapse
                        in={menuName.split("-")[0] === "questions" && isCollapse}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    navigate("/questions-list");
                                    handleMeneClick("questions-list", true);
                                }}
                                selected={menuName === "questions-list"}
                            >
                                <ListItemIcon>
                                    <ArrowRightIcon fontSize="small" sx={{ color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary="รายการ" />
                            </ListItemButton>

                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    navigate("/questions-group-list");
                                    handleMeneClick("questions-group-list", true);
                                }}
                                selected={menuName === "questions-group-list"}
                            >
                                <ListItemIcon>
                                    <ArrowRightIcon fontSize="small" sx={{ color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary="กลุ่มคำถาม" />
                            </ListItemButton>

                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    navigate("/questions-type-list");
                                    handleMeneClick("questions-type-list", true);
                                }}
                                selected={menuName === "questions-type-list"}
                            >
                                <ListItemIcon>
                                    <ArrowRightIcon fontSize="small" sx={{ color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary="ประเภทคำถาม" />
                            </ListItemButton>
                        </List>
                    </Collapse>

                    <ListItemButton
                        onClick={() => {
                            handleMeneClick("articles");
                        }}
                    >
                        <ListItemIcon>
                            <ArticleOutlinedIcon sx={{ color: "black" }} />
                        </ListItemIcon>
                        <ListItemText primary="บทความ" />
                        {(menuName === "articles" ||
                            menuName.split("-")[0] === "articles") &&
                            isCollapse ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </ListItemButton>
                    <Collapse
                        in={menuName.split("-")[0] === "articles" && isCollapse}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    navigate("/articles-list");
                                    handleMeneClick("articles-list", true);
                                }}
                                selected={menuName === "articles-list"}
                            >
                                <ListItemIcon>
                                    <ArrowRightIcon fontSize="small" sx={{ color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary="รายการ" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <ListItemButton
                        onClick={() => {
                            handleMeneClick("notifications");
                        }}
                    >
                        <ListItemIcon>
                            <CampaignOutlinedIcon sx={{ color: "black" }} />
                        </ListItemIcon>
                        <ListItemText primary="การแจ้งเตือน" />
                        {(menuName === "notifications" ||
                            menuName.split("-")[0] === "notifications") &&
                            isCollapse ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </ListItemButton>
                    <Collapse
                        in={menuName.split("-")[0] === "notifications" && isCollapse}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    navigate("/notifications-list");
                                    handleMeneClick("notifications-list", true);
                                }}
                                selected={menuName === "notifications-list"}
                            >
                                <ListItemIcon>
                                    <ArrowRightIcon fontSize="small" sx={{ color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary="รายการ" />
                            </ListItemButton>

                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    navigate("/notification-types-list");
                                    handleMeneClick("notifications-types", true);
                                }}
                                selected={menuName === "notifications-types"}
                            >
                                <ListItemIcon>
                                    <ArrowRightIcon fontSize="small" sx={{ color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary="ประเภทการแจ้งเตือน" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <ListItemButton
                        onClick={() => {
                            handleMeneClick("packages");
                        }}
                    >
                        <ListItemIcon>
                            <LocalAtmOutlinedIcon sx={{ color: "black" }} />
                        </ListItemIcon>
                        <ListItemText primary="แพ็คเกจ" />
                        {(menuName === "packages" ||
                            menuName.split("-")[0] === "packages") &&
                            isCollapse ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </ListItemButton>
                    <Collapse
                        in={menuName.split("-")[0] === "packages" && isCollapse}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    navigate("/packages-list");
                                    handleMeneClick("packages-list", true);
                                }}
                                selected={menuName === "packages-list"}
                            >
                                <ListItemIcon>
                                    <ArrowRightIcon fontSize="small" sx={{ color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary="รายการ" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <ListItemButton
                        onClick={() => {
                            handleMeneClick("promotions");
                        }}
                    >
                        <ListItemIcon>
                            <AutoGraphOutlinedIcon sx={{ color: "black" }} />
                        </ListItemIcon>
                        <ListItemText primary="โปรโมชั่น" />
                        {(menuName === "promotions" ||
                            menuName.split("-")[0] === "promotions") &&
                            isCollapse ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </ListItemButton>
                    <Collapse
                        in={menuName.split("-")[0] === "promotions" && isCollapse}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    navigate("/promotions-list");
                                    handleMeneClick("promotions-list", true);
                                }}
                                selected={menuName === "promotions-list"}
                            >
                                <ListItemIcon>
                                    <ArrowRightIcon fontSize="small" sx={{ color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary="รายการ" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <ListItemButton
                        onClick={() => {
                            handleMeneClick("users");
                        }}
                    >
                        <ListItemIcon>
                            <GroupsOutlinedIcon sx={{ color: "black" }} />
                        </ListItemIcon>
                        <ListItemText primary="ผู้ใช้งาน" />
                        {(menuName === "users" || menuName.split("-")[0] === "users") &&
                            isCollapse ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </ListItemButton>
                    <Collapse
                        in={menuName.split("-")[0] === "users" && isCollapse}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    navigate("/users-customers-list");
                                    handleMeneClick("users-customers-list", true);
                                }}
                                selected={menuName === "users-customers-list"}
                            >
                                <ListItemIcon>
                                    <ArrowRightIcon fontSize="small" sx={{ color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary="รายชื่อลูกค้า" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <ListItemButton
                        onClick={() => {
                            handleMeneClick("payments");
                        }}
                    >
                        <ListItemIcon>
                            <PaymentsOutlinedIcon sx={{ color: "black" }} />
                        </ListItemIcon>
                        <ListItemText primary="การชำระเงิน" />
                        {(menuName === "payments" ||
                            menuName.split("-")[0] === "payments") &&
                            isCollapse ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </ListItemButton>
                    <Collapse
                        in={menuName.split("-")[0] === "payments" && isCollapse}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    navigate("/payments-list");
                                    handleMeneClick("payments-list", true);
                                }}
                                selected={menuName === "payments-list"}
                            >
                                <ListItemIcon>
                                    <ArrowRightIcon fontSize="small" sx={{ color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary="รายการ" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <ListItemButton
                        onClick={() => {
                            handleMeneClick("settings");
                        }}
                    >
                        <ListItemIcon>
                            <SettingsOutlinedIcon sx={{ color: "black" }} />
                        </ListItemIcon>
                        <ListItemText primary="การจัดการ" />
                        {(menuName === "settings" ||
                            menuName.split("-")[0] === "settings") &&
                            isCollapse ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </ListItemButton>
                    <Collapse
                        in={menuName.split("-")[0] === "settings" && isCollapse}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    navigate("/system-users-list");
                                    handleMeneClick("settings-users", true);
                                }}
                                selected={menuName === "settings-users"}
                            >
                                <ListItemIcon>
                                    <ArrowRightIcon fontSize="small" sx={{ color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary="ผู้ใช้ระบบ" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    navigate("/profile/" + userData?.id);
                                    handleMeneClick("settings-profile", true);
                                }}
                                selected={menuName === "settings-profile"}
                            >
                                <ListItemIcon>
                                    <ArrowRightIcon fontSize="small" sx={{ color: "black" }} />
                                </ListItemIcon>
                                <ListItemText primary="โปรไฟล์" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </List>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant="caption" color={"black"} textAlign={"center"}>
                    v {appVersion}
                </Typography>
            </Drawer>
            <Main open={isDrawerOpen} sx={{ minWidth: 0 }}>
                <DrawerHeader />
                <div style={{ padding: "16px" }}>{children}</div>
            </Main>
        </Box>
    );
});

export default Layout;
