import apiService from "../../../services/ApiService";

export interface IUserData {
    totalUser: number;
    newUser: number;
    userGrowth: { month: string; users: number }[];
}


export interface IBlessingData {
    blessingType: { name: string; qty: number }[];
    blessingByTime: { time: string; qty: number }[];
}

export interface IRevenueOvertimeData {
    totalRevenue: number;
    revenueOverTime: { month: string; revenue: number }[];
}


export interface IRevenueOverviewData {
    revenueOrderByMonth: {
        month: string; users: number, subscriptions: number,
        credits: number,
    }[];
}

export interface IDashboardData {
    userData: IUserData;
    blessingData: IBlessingData;
    revenueOvertimeData: IRevenueOvertimeData;
    revenueOverviewData: IRevenueOverviewData;
}


class DashboardService {
    dashboardData: IDashboardData = {} as IDashboardData
    async getDashboardData(month: number | 'all', year: number) {
        try {
            const response = await apiService.get(`/dashboards?month=${month}&year=${year}`);
            this.dashboardData = response;
            return this.dashboardData;
        } catch (error) {
            throw error;
        }
    }
}

const dashboardService = new DashboardService();
export default dashboardService;