import { makeAutoObservable } from "mobx";
import { IUser } from "../../Settings/Services/SystemUsersService";
import apiService from "../../../services/ApiService";

export interface IPromotion{
    id?: string;
    name: string;
    type: string;
    description: string;
    price: number;
    duration: number;
    startDate: Date;
    endDate:Date;
    path?:string;
    isDelete?: boolean;
    isActive?: boolean;
    createAt?: Date;
    updateAt?: Date;
    createBy?: string;
    updateBy?: string;
}

export interface IPromotionType{
    id: string;
    name: string;
    createBy: string;
    updateBy: string;
    createAt: Date;
    updateAt: Date;
}

export interface IPromotionViewData{
    id?: string;
    name: string;
    type: IPromotionType;
    description:string;
    price: number;
    duration: number;
    startDate: Date;
    endDate:Date;
    path:string;
    isDelete?:boolean;
    isActive?: boolean;
    createBy?: IUser;
    updateBy?: IUser;
    createAt?: Date;
    updateAt?: Date;
}

export interface IPromotionCriteria {
    name: string;
    type: string;
    startDate?: Date;
    endDate?: Date;
    isActive: boolean;
    page: number;
    limit: number;
}

export interface IPromotionView{
    datas: IPromotionViewData[];
    all: number;
    limit: number;
    page: number;
    total: number;
}

export interface IPromotionUpdateIsActiveChecked {
    isActive: boolean;
    updateAt: Date;
}

class PromotionsService {
    promotions: IPromotionView = {} as IPromotionView;
    promotionsType: IPromotionType[] = [];
    constructor() {
        makeAutoObservable(this);
    }

    async getAll() {
        try {
            const response = await apiService.get("/promotions");
            if (response.length > 0) {
                this.promotions = {
                    datas: response,
                    all: response.length,
                    limit: 10,
                    page: 1,
                    total: response.length
                };
            }
            return this.promotions;
        } catch (error) {
            throw error;
        }
    }

    async getOne(id: string) {
        try {
            const response = await apiService.get(`/promotions/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getByCriteria(criteria: IPromotionCriteria) {
        try {
            const response = await apiService.post("/promotions/criteria", criteria);
            this.promotions = {
                datas: response.data,
                all: response.length,
                limit: response.limit,
                page: response.page,
                total: response.total
            };
            return this.promotions;
        } catch (error) {
            throw error;
        }
    }

    async create(data: IPromotion) {
        try {
            let response = '';
            await apiService.post("/promotions", data).then(async (result) => {
                response = result;
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    async update(id: string, data: IPromotion) {
        try {
            const response = await apiService.put(`/promotions/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async deletePromotion(id: string) {
        try {
            const data = {isDelete:true}
            const response = await apiService.put(`/promotions/delete/${id}`,data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getPromotionTypes() {
        try{
          const response = await apiService.get("/promotions/promotion-type/all");
          if(response.length > 0){
            this.promotionsType = response;
          }else{
              this.promotionsType = []
            }
          return this.promotionsType
        } catch (error) {
          return error;
        }
    }

    async updateIsActiveChecked(id: string, data: IPromotionUpdateIsActiveChecked) {
        try {
          const response = await apiService.put(`/promotions/${id}`, data);
          return response;
        } catch (error) {
          return error;
        }
      }
}

const promotionsService = new PromotionsService();
export default promotionsService;