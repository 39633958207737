import {
  Box,
  Breadcrumbs,
  Button,
  ButtonProps,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  NoteAdd as NoteAddIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  INotification,
  INotificationCriteria,
  INotificationView,
  INotificationViewData,
} from "../Services/NotificationsService";
import dayjs from "dayjs";
import { INotificationTypes } from "../Services/NotificationTypesService";
import loginService from "../../Login/Services/LoginService";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DialogConfirm from "../../../components/DialogConfirm";
interface NotificationsListViewProps {
  notifications: INotificationView;
  onSearch: (criteria: INotificationCriteria) => void;
  onUpdate: (id: string, data: INotification) => void;
  types: INotificationTypes[];
}

const NotificationsListView: FC<NotificationsListViewProps> = (props) => {
  const navigate = useNavigate();
  const user = loginService.user;
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [type, setType] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [notifications, setNotifications] = useState<INotificationViewData[]>();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
    id: "",
    index: 0,
    isPublish: false,
  });
  const searchByCiteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber === 0 ? 0 : pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: INotificationCriteria = {
      title: title,
      content: content,
      type: type ?? 0,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSearch(criteria);
  };

  const truncate = (input: string) =>
    input.length > 20 ? `${input.substring(0, 20)}...` : input;

  const handlePublish = (id: string, status: boolean, index: number) => {
    if (id) {
      props.onUpdate(id, {
        title: notifications![index].title,
        content: notifications![index].content,
        updateAt: new Date(),
        updateBy: user?.id,
        isPublish: [status],
      });
    }
    setNotifications((prevNotifications) => {
      const _notifications = prevNotifications ?? [];
      const updatedNotifications = [..._notifications];
      if (index >= 0 && index < _notifications.length) {
        const updatedNotification = {
          ...updatedNotifications[index],
          isPublish: status,
        };
        updatedNotifications[index] = updatedNotification;
      }
      return updatedNotifications;
    });
  };

  useEffect(() => {
    setNotifications(props.notifications.datas);
  }, [props.notifications.datas]);

  return (
    <Box component={"div"}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>แจ้งเตือน</Typography>
        <Typography variant="h6" color="text.primary">
          รายการ
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="หัวเรื่อง"
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="เนื้อหา"
                    onChange={(e) => {
                      setContent(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="notificationGroup-select-label">
                      ประเภท
                    </InputLabel>
                    <Select
                      labelId="notificationGroup-select-label"
                      id="notificationGroup-select"
                      value={type}
                      label="ตำแหน่ง"
                      onChange={(e: any) => {
                        const typeName = e.target.value;
                        setType(typeName);
                      }}
                    >
                      <MenuItem value={0}>ทั้งหมด</MenuItem>
                      {props.types && props.types.length > 0 ? (
                        props.types.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>ไม่พบข้อมูล</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} textAlign={"center"} alignSelf={"center"}>
                  <Button
                    variant="contained"
                    onClick={() => searchByCiteria(0)}
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography>
                        ทั้งหมด {props.notifications.total} รายการ
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        startIcon={<NoteAddIcon />}
                        onClick={() => navigate("/notification-create")}
                      >
                        สร้าง
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">หัวเรื่อง</TableCell>
                          <TableCell align="center">เนื้อหา</TableCell>
                          <TableCell align="center">ประเภท</TableCell>
                          <TableCell align="center">หน้าเพจ</TableCell>
                          <TableCell align="center">
                            แจ้งเตือนผู้ใช้งาน
                          </TableCell>
                          <TableCell align="center">บันทึก</TableCell>
                          <TableCell align="center">แก้ไข</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {notifications && notifications.length > 0 ? (
                          notifications.map((notification, index) => (
                            <TableRow
                              key={notification.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center">
                                {notification.title}
                              </TableCell>
                              <TableCell
                                align="center"
                                dangerouslySetInnerHTML={{
                                  __html: truncate(notification.content ?? ""),
                                }}
                              ></TableCell>
                              <TableCell align="center">
                                {notification.type?.name}
                              </TableCell>
                              <TableCell align="center">
                                {notification.path}
                              </TableCell>
                              <TableCell align="center">
                                {notification.isPublish ? (
                                  <IconButton
                                    aria-label="isPublish"
                                    size="large"
                                    onClick={() =>
                                      setOpenConfirm({
                                        ...openConfirm,
                                        open: true,
                                        title: "แก้ไขแจ้งเตือน",
                                        message:
                                          "คุณต้องการแก้ไขแจ้งเตือนนี้ใช่หรือไม่?",
                                        index: index,
                                        id: notification.id || "",
                                        type: "edit",
                                        isPublish: false,
                                      })
                                    }
                                  >
                                    <CheckCircleIcon
                                      fontSize="inherit"
                                      color="success"
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    aria-label="isNotPublish"
                                    size="large"
                                    onClick={() =>
                                      setOpenConfirm({
                                        ...openConfirm,
                                        open: true,
                                        title: "แก้ไขแจ้งเตือน",
                                        message:
                                          "คุณต้องการแก้ไขแจ้งเตือนนี้ใช่หรือไม่?",
                                        index: index,
                                        id: notification.id || "",
                                        type: "edit",
                                        isPublish: true,
                                      })
                                    }
                                  >
                                    <CheckCircleOutlineIcon fontSize="inherit" />
                                  </IconButton>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                <Typography fontWeight={"bold"}>
                                  {notification.createBy
                                    ? notification.createBy.firstName
                                    : ""}
                                </Typography>
                                <Typography>
                                  {notification.createAt
                                    ? dayjs(notification.createAt).format(
                                        "DD/MM/YYYY HH:mm"
                                      )
                                    : ""}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography fontWeight={"bold"}>
                                  {notification.updateBy
                                    ? notification.updateBy.firstName
                                    : ""}
                                </Typography>
                                <Typography>
                                  {notification.updateAt
                                    ? dayjs(notification.updateAt).format(
                                        "DD/MM/YYYY HH:mm"
                                      )
                                    : ""}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/notification-edit/${notification.id}`
                                    )
                                  }
                                >
                                  <ChevronRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              <Typography>ไม่พบข้อมูล</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={props.notifications.total ?? 0}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        searchByCiteria(newPage);
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setLimit(e.target.value);
                        setPage(0);
                        searchByCiteria(page, e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "edit") {
            handlePublish(
              openConfirm.id || "",
              openConfirm.isPublish,
              openConfirm.index
            );
          }
        }}
      />
    </Box>
  );
};

export default NotificationsListView;
