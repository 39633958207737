import React, { useEffect, useState } from "react";
import CustomersListView from "../Views/UserCustomersListView";
import {
  IUserCustomerCriteria,
  IUserCustomerView,
} from "../Services/UserCustomersServices";
import CustomersService from "../Services/UserCustomersServices";
import { enqueueSnackbar } from "notistack";
import { sortOptions } from "../../../constants/sortOptions";

interface UserCustomersListControllerProps {
  // Define your view props here
}

const UserCustomersListController: React.FC<
  UserCustomersListControllerProps
> = (props) => {
  const [customers, setCustomers] = useState<IUserCustomerView>();

  useEffect(() => {
    const criteria: IUserCustomerCriteria = {
      name: "",
      page: 0,
      limit: 10,
      sortBy: sortOptions[0],
    };
    searchByCiteria(criteria);
  }, []);

  const fetchCustomers = async () => {
    try {
      const datas = await CustomersService.getAll();
      if (datas) {
        setCustomers(datas as IUserCustomerView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const searchByCiteria = async (criteria: IUserCustomerCriteria) => {
    try {
      const datas = await CustomersService.getCustomerByCriteria(criteria);
      if (datas) {
        setCustomers(datas as IUserCustomerView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  return (
    <CustomersListView
      getAll={fetchCustomers}
      customers={customers ?? ({} as IUserCustomerView)}
      searchByCiteria={(criteria: IUserCustomerCriteria) => {
        searchByCiteria(criteria);
      }}
    />
  );
};

export default UserCustomersListController;
