import { FC, useEffect, useState } from "react";
import PackageCreateView from "../Views/PackageCreateView";
import { useNavigate, useParams } from "react-router-dom";
import PackagesService, {
  IPackageType,
  IPackageViewData,
} from "../Services/PackagesService";
import { IPackage } from "../Services/PackagesService";
import { enqueueSnackbar } from "notistack";

interface PackageControllerProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการจัดการข้อมูล
}
const PackageCreateController: FC<PackageControllerProps> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<IPackageViewData>();
  const [types, setTypes] = useState<IPackageType[]>();

  useEffect(() => {
    if (id) {
      getOne(id);
    }
    getType();
  }, [id]);

  const create = async (data: IPackage) => {
    await PackagesService.create(data)
      .then((res: any) => {
        if (res) {
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
          navigate(`/package-edit/${res.id}`);
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const getType = async () => {
    await PackagesService.getPackageTypes()
      .then((res: any) => {
        if (res) {
          setTypes(res);
        } else {
          enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      });
  };

  const update = async (id: string, data: IPackage) => {
    await PackagesService.update(id, data)
      .then((res) => {
        if (res) {
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const getOne = async (id: string) => {
    try {
      const res = await PackagesService.getOne(id);
      if (res) {
        setData(res);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const deletePackage = async (id: string) => {
    await PackagesService.deletePackage(id).then((res) => {
      if (res) {
        enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
        navigate("/packages-list");
      } else {
        enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    }).catch((err) => {
      console.error(err);
      enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
    })
  };
  return (
    <PackageCreateView
      getOne={getOne}
      onCreate={(data: IPackage) => {
        create(data);
      }}
      onUpdate={(id: string, data: IPackage) => {
        update(id, data);
      }}
      onDelete={(id: string) => {
        deletePackage(id);
      }}
      data={data ?? ({} as IPackageViewData)}
      packageId={id ?? ""}
      types={types ?? ([] as IPackageType[])}
    />
  );
};

export default PackageCreateController;
