import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  InputLabel,
  Link,
  Select,
  TextField,
  Typography,
  Stack,
  ButtonProps,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  IPackage,
  IPackageType,
  IPackageViewData,
} from "../Services/PackagesService";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogConfirm from "../../../components/DialogConfirm";
import { checkValidation, handleOnKeyDown } from "../../../utils";
import { enqueueSnackbar } from "notistack";
import loginService from "../../Login/Services/LoginService";

interface PackgeCreateViewProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการแสดง UI
  getOne: (id: string) => void;
  onCreate: (data: IPackage) => void;
  onUpdate: (id: string, data: IPackage) => void;
  onDelete: (id: string) => void;
  data: IPackageViewData;
  packageId: string;
  types: IPackageType[];
}

const PackageCreateView: FC<PackgeCreateViewProps> = (props) => {
  const user = loginService.user;
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [price, setPrice] = useState("0");
  const [type, setType] = useState("select-type");
  const [store, setStore] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [productId, setProductId] = useState("");
  const [blessingQuantity, setBlessingQuantity] = useState(0);
  const [blessingQuantityRemind, setBlessingQuantityRemind] = useState(0);
  const [description, setDescription] = useState("");

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  useEffect(() => {
    if (props.data) {
      setName(props.data.name ?? "");
      setPrice(props.data?.price ? props.data?.price.toString() : "0");
      if (props.data.type) {
        setType(props.data?.type.id ?? "select-type");
      }
      setIsActive(props.data.isActive ?? true);
      setStore(props.data.store ?? "");
      setProductId(props.data.productId ?? "");
      setBlessingQuantity(props.data.quantity ?? 0);
      setBlessingQuantityRemind(props.data.quantityRemind ?? 0);
      setDescription(props.data.description ?? "");
    }
  }, [props.data]);

  const handleSave = () => {
    if (checkValidate()) {
      props.onCreate({
        name,
        price: price === "" || price === "." ? 0 : parseFloat(price),
        type,
        store,
        createAt: new Date(),
        createBy: user?.id,
        productId: productId,
        quantity: blessingQuantity,
        quantityRemind: blessingQuantityRemind,
        description: description,
      });
    }
  };

  const handleEdit = () => {
    if (checkValidate()) {
      props.onUpdate(props.packageId ?? "", {
        name,
        price: price === "" || price === "." ? 0 : parseFloat(price),
        type,
        store,
        isActive: isActive,
        updateAt: new Date(),
        updateBy: user?.id,
        productId: productId,
        quantity: blessingQuantity,
        quantityRemind: blessingQuantityRemind,
        description: description,
      });
    }
  };

  const handleDelete = () => {
    props.onDelete(props.packageId ?? "");
  };

  const handleStoreChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStore(event.target.value);
  };

  const checkValidate = () => {
    if (!checkValidation(name, "กรุณากรอกชื่อ")) return false;
    /*     if (!checkValidation(price, "กรุณาใส่ราคา")) return false; */
    if (!checkValidation(type, "กรุณาระบุประเภท")) return false;
    if (type === "select-type") {
      enqueueSnackbar("กรุณาระบุประเภท", { variant: "error" });
      return false;
    }
    if (!checkValidation(store, "กรุณาเลือก store")) return false;
    if (!checkValidation(productId, "กรุณาใส่ Product Id")) return false;
    if (!checkValidation(blessingQuantity, "กรุณาใส่จำนวนการขอพร")) return false;
    // if (!checkValidation(blessingQuantityRemind, "กรุณาใส่จำนวนวันในการใช้งาน")) return false;

    return true;
  };

  const handleChangePriceValue = (value: string) => {
    if (value.substring(0, 1) === "0") {
      setPrice(value.substring(1));
    } else {
      setPrice(value);
    }
  };

  return (
    <Box component={"div"}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/packages-list")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>แพ็คเกจ</Typography>
            <Link component="button" onClick={() => navigate("/packages-list")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.packageId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"end"}>
          {props.packageId && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setOpenConfirm({
                  open: true,
                  title: "ลบข้อมูล",
                  message: "คุณต้องการลบแพ็คเกจนี้ใช่หรือไม่",
                  color: "error",
                  type: "delete",
                });
              }}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={store}
                      onChange={handleStoreChange}
                    >
                      <FormControlLabel
                        value="APP_STORE"
                        control={<Radio />}
                        label="App Store"
                      />
                      <FormControlLabel
                        value="PLAY_STORE"
                        control={<Radio />}
                        label="Play Store"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="ชื่อแพ็คเกจ"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="ราคา"
                    value={price}
                    type="text"
                    onChange={(e) => handleChangePriceValue(e.target.value)}
                    onKeyDown={handleOnKeyDown}
                  />
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="packageTypeGroup-select-label">
                      ประเภท
                    </InputLabel>
                    <Select
                      labelId="packageType-select-label"
                      value={type}
                      id="packageType-select"
                      label="ประเภท"
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    >
                      <MenuItem value="select-type">กรุณาเลือกประเภท</MenuItem>
                      {props.types && props.types.length > 0 ? (
                        props.types.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>ไม่พบข้อมูล</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Product Id"
                    value={productId}
                    type="text"
                    helperText="id จาก Store"
                    onChange={(e) => setProductId(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    label="จำนวนการขอพร"
                    value={blessingQuantity}
                    type="number"
                    onChange={(e) => setBlessingQuantity(parseInt(e.target.value))}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    label="จำนวนวันในการใช้งาน"
                    value={blessingQuantityRemind}
                    type="number"
                    onChange={(e) => setBlessingQuantityRemind(parseInt(e.target.value))}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    multiline
                    label="รายละเอียด"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={8}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isActive}
                          onChange={(e) => {
                            setIsActive(e.target.checked);
                          }}
                        />
                      }
                      label="ใช้งาน"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack spacing={2} direction="row">
                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={() => navigate("/packages-list")}
                        >
                          ยกเลิก
                        </Button>
                        {
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              if (props.packageId) {
                                setOpenConfirm({
                                  open: true,
                                  title: "แก้ไขแพ็คเกจ",
                                  message:
                                    "คุณต้องการแก้ไขแพ็คเกจนี้ใช่หรือไม่",
                                  color: "primary",
                                  type: "edit",
                                });
                              } else {
                                setOpenConfirm({
                                  open: true,
                                  title: "บันทึกแพ็คเกจ",
                                  message:
                                    "คุณต้องการบันทึกแพ็คเกจนี้ใช่หรือไม่",
                                  color: "primary",
                                  type: "create",
                                });
                              }
                            }}
                          >
                            บันทึก
                          </Button>
                        }
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            handleDelete();
          } else if (openConfirm.type === "create") {
            handleSave();
          } else if (openConfirm.type === "edit") {
            handleEdit();
          }
        }}
      />
    </Box>
  );
};

export default PackageCreateView;
