import { Box, Breadcrumbs, Button, Card, CardContent, Grid, Link, Typography, TextField, Stack, ButtonProps, InputAdornment, FormControl, InputLabel, MenuItem, Select, Divider } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import DialogConfirm from "../../../components/DialogConfirm";
import { ISystemUser, ISystemUserViewData } from "../Services/SystemUsersService";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { IRole } from "../Services/RolesService";
import { checkValidation } from "../../../utils";
import loginService from "../../Login/Services/LoginService";


interface SystemUserCreateProps {
    systemUser: ISystemUserViewData;
    onDelete: () => void;
    onCreate: (data: ISystemUser) => void;
    onUpdate: (data: ISystemUser) => void;
    systemUserId: string;
    roles: IRole[];
}


const SystemUserCreate: FC<SystemUserCreateProps> = (props) => {
    const navigate = useNavigate();
    const user = loginService.user;
    const [openConfirm, setOpenConfirm] = useState({ open: false, title: '', message: '', color: 'primary' as ButtonProps['color'], type: 'create' as 'create' | 'edit' | 'delete' });
    // const [systemUser, setSystemUser] = useState<ISystemUser>({} as ISystemUser);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState(0);
    const pathName = useLocation().pathname.split('/')[1];




    useEffect(() => {

        if (props.systemUser) {
            setFirstName(props.systemUser.firstName ?? '');
            setLastName(props.systemUser.lastName ?? '');
            setEmail(props.systemUser.email ?? '');
            setTel(props.systemUser.tel ?? '');
            setUsername(props.systemUser.username ?? '');
            // setPassword(props.systemUser.password ?? '');
            if (props.systemUser.password) {
                setPassword(props.systemUser.password);
                setCurrentPassword(props.systemUser.password);
            }
        }
        if (props.systemUser.role) {
            const roleId = props.roles.find((item) => item.id === props.systemUser.role.id);
            setRole(roleId?.id ?? 1);
        }
        setConfirmPassword('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.systemUser]);



    const handleDelete = () => {
        props.onDelete();
    };

    const handleSave = () => {
        if (checkValidate()) {
            const data: ISystemUser = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                tel: tel,
                username: username,
                password: password,
                role: role,
                createAt: new Date(),
                createBy: user?.id ?? ''
            };
            props.onCreate(data);
        }
    };

    const handleEdit = () => {
        if (checkValidate()) {
            const updateData: ISystemUser = {
                id: props.systemUser.id,
                firstName: firstName,
                lastName: lastName,
                email: email,
                tel: tel,
                username: username,
                password: password,
                role: role,
                updateAt: new Date(),
                updateBy: user?.id ?? ''
            };
            if (password === currentPassword) {
                const data = updateData;
                data.password = '';
                props.onUpdate(data as ISystemUser);
            } else {
                props.onUpdate(updateData as ISystemUser);
            }
        }
    };

    const checkValidate = () => {
        if (!checkValidation(firstName, 'กรุณากรอกชื่อ')) return false;
        if (!checkValidation(lastName, 'กรุณากรอกนามสกุล')) return false;
        if (!checkValidation(email, 'กรุณากรอกอีเมล')) return false;
        if (!checkValidation(tel, 'กรุณากรอกเบอร์โทร')) return false;
        if (!checkValidation(username, 'กรุณากรอกชื่อผู้ใช้')) return false;
        if (password !== currentPassword) {
            if (!checkValidation(password, 'กรุณากรอกรหัสผ่าน')) return false;
            if (!checkValidation(confirmPassword, 'กรุณายืนยันรหัสผ่าน')) return false;
        }
        if (!checkValidation(role, 'กรุณาเลือกตำแหน่ง')) return false;

        return true;
    };

    const handleChangeRole = (value: any) => {
        setRole(value);
    };

    const handleClickShowPassword = (show: string) => {
        show === 'password' ? setShowPassword((show) => !show) : setShowConfirmPassword((show) => !show);
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };


    return (
        <Box component={'div'}>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={0.5}>
                    <IconButton aria-label="delete" onClick={() => navigate('/system-users-list')}>
                        <ArrowBackIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={8.5} display={'flex'} alignItems={'center'}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        {pathName === 'profile' ? ([
                            <Typography key="profile">โปรไฟล์</Typography>,
                            <Typography key="action" variant="h6" color="text.primary">แก้ไข</Typography>
                        ]) : ([
                            <Typography key="management">การจัดการ</Typography>,
                            <Link key="userLink" component="button" onClick={() => navigate('/system-users-list')}>ผู้ใช้ระบบ</Link>,
                            <Typography key="action" variant="h6" color="text.primary">{props.systemUserId ? 'แก้ไข' : 'สร้าง'}</Typography>
                        ])}
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={3} display={'flex'} justifyContent={'end'}>
                    {props.systemUserId && <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => {
                        setOpenConfirm({
                            open: true,
                            title: 'ลบข้อมูล',
                            message: 'คุณต้องการลบผู้ใช้งานนี้ใช่หรือไม่',
                            color: 'error',
                            type: 'delete'
                        });
                    }}>ลบข้อมูล</Button>}
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextField fullWidth label="ชื่อ" value={firstName} onChange={(e) => {
                                        setFirstName(e.target.value);
                                    }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth label="นามสกุล" value={lastName} onChange={(e) => {
                                        setLastName(e.target.value);
                                    }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth label="อีเมล" value={email} onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth label="เบอร์โทร" value={tel} onChange={(e) => {
                                        setTel(e.target.value);
                                    }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth label="ชื่อผู้ใช้" value={username} onChange={(e) => {
                                        setUsername(e.target.value);
                                    }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        label="รหัสผ่าน"
                                        variant="outlined"
                                        fullWidth
                                        value={password === currentPassword ? '' : password}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword('password')}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="outlined-adornment-confirm-password"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        label="ยืนยันรหัสผ่าน"
                                        variant="outlined"
                                        fullWidth
                                        value={confirmPassword}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword('confirm')}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth>
                                        <InputLabel id="userGroup-select-label">ตำแหน่ง</InputLabel>
                                        <Select
                                            labelId="userGroup-select-label"
                                            id="userGroup-select"
                                            value={role}
                                            label="ตำแหน่ง"
                                            disabled={props.systemUserId === user?.id ? true : false}
                                            onChange={(e: any) => handleChangeRole(e.target.value)}>
                                            <MenuItem value={0}>กรุณาเลือกตำแหน่ง</MenuItem>
                                            {props.roles && props.roles.length > 0 ? props.roles.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            )) : []}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}><Divider /></Grid>
                                <Grid item xs={8}></Grid>
                                <Grid item xs={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} >
                                            <Stack spacing={2} direction="row">
                                                <Button variant="outlined" fullWidth onClick={() => navigate('/system-users-list')}>ยกเลิก</Button>
                                                {<Button variant="contained" fullWidth onClick={() => {
                                                    if (props.systemUserId) {
                                                        setOpenConfirm({
                                                            open: true,
                                                            title: 'แก้ไขข้อมูลผู้ใช้',
                                                            message: 'คุณต้องการแก้ไขแก้ไขข้อมูลผู้ใช้นี้ใช่หรือไม่',
                                                            color: 'primary',
                                                            type: 'edit'
                                                        });
                                                    } else {
                                                        setOpenConfirm({
                                                            open: true,
                                                            title: 'บันทึกข้อมูลผู้ใช้',
                                                            message: 'คุณต้องการบันทึกข้อมูลผู้ใช้นี้ใช่หรือไม่',
                                                            color: 'primary',
                                                            type: 'create'
                                                        });
                                                    }
                                                }}>บันทึก</Button>}
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <DialogConfirm open={openConfirm.open} type={openConfirm.color} title={openConfirm.title} message={openConfirm.message} onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
                onSubmit={() => {
                    setOpenConfirm({ ...openConfirm, open: false });
                    if (openConfirm.type === 'delete') {
                        handleDelete();
                    } else if (openConfirm.type === 'create') {
                        handleSave();
                    } else if (openConfirm.type === 'edit') {
                        handleEdit();
                    }
                }}
            />
        </Box>
    );
};

export default SystemUserCreate;