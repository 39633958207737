import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import CustomersHistoryView from "../Views/UserCustomersHistoryView";
import {
  IUserCustomerHistoryView,
  IUserCustomerHistoryCriteria,
  IUserCustomer,
} from "../Services/UserCustomersServices";
import CustomersService from "../Services/UserCustomersServices";
import userPaymentsService from "../Services/UserPaymentsServices";

interface UserCustomersHistoryControllerProps {
  // Add any props you need for the controller
}

const UserCustomersHistoryController: React.FC<
  UserCustomersHistoryControllerProps
> = () => {
  const [customerHistory, setCustomerHistory] = useState<
    IUserCustomerHistoryView | {}
  >();
  const [customer, setCustomer] = useState<IUserCustomer>();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const criteria: IUserCustomerHistoryCriteria = {
        page: 0,
        limit: 10
      };
      getHistoryByCriteria(criteria);
      getCustomer(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getHistoryByCriteria = async (
    criteria: IUserCustomerHistoryCriteria
  ) => {
    try {
      criteria.userId = id ?? "";
      const res = await CustomersService.getCustomerHistoryByCriteria(criteria);
      if (res) {
        setCustomerHistory(res);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const getCustomer = async (id: string) => {
    const res = await userPaymentsService.getCustomerData(id ?? "");
    if (res) {
      setCustomer(res);
    }
  };

  return (
    <CustomersHistoryView
      userHistory={customerHistory as IUserCustomerHistoryView}
      searchByCiteria={(criteria: IUserCustomerHistoryCriteria) => {
        getHistoryByCriteria(criteria);
      }}
      customer={customer ?? ({} as IUserCustomer)}
    />
  );
};

export default UserCustomersHistoryController;
