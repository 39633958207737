import {
  Box,
  Breadcrumbs,
  Button,
  ButtonProps,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography,
  Link,
  Divider,
  Stack,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  IQuestionsGroup,
  IQuestionsGroupViewData,
} from "../../Services/QuestionsGroupService";
import loginService from "../../../Login/Services/LoginService";
import { useNavigate } from "react-router-dom";
import { checkValidation } from "../../../../utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogConfirm from "../../../../components/DialogConfirm";

interface QuestionGroupCreateViewProps {
  getOne: (id: string) => void;
  onCreate: (data: IQuestionsGroup) => void;
  onUpdate: (id: string, data: IQuestionsGroup) => void;
  onDelete: (id: string) => void;
  data: IQuestionsGroupViewData;
  questionGroupId: string;
}

const QuestionGroupCreateView: FC<QuestionGroupCreateViewProps> = (props) => {
  const user = loginService.user;
  const navigate = useNavigate();

  const [questionGroupName, setQuestionGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  useEffect(() => {
    if (props.data) {
      setQuestionGroupName(props.data.name ?? "");
      setDescription(props.data.description ?? "");
    }
  }, [props.data]);

  const handleSave = () => {
    if (checkValidate()) {
      props.onCreate({
        name: questionGroupName,
        description: description,
        createAt: new Date(),
        createBy: user?.id,
      });
    }
  };

  const handleEdit = () => {
    if (checkValidate()) {
      props.onUpdate(props.questionGroupId ?? "", {
        name: questionGroupName,
        description: description,
        updateAt: new Date(),
        updateBy: user?.id,
      });
    }
  };

  const handleDelete = () => {
    props.onDelete(props.questionGroupId ?? "");
  };

  const checkValidate = () => {
    if (!checkValidation(questionGroupName, "กรุณากรอกชื่อ")) return false;
    if (!checkValidation(description, "กรุณากรอกรายละเอียด")) return false;
    return true;
  };
  return (
    <Box component={"div"}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/questions-group-list")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>คำถาม</Typography>
            <Link
              component="button"
              onClick={() => navigate("/questions-group-list")}
            >
              กลุ่มคําถาม
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.questionGroupId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"end"}>
          {props.questionGroupId && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setOpenConfirm({
                  open: true,
                  title: "ลบข้อมูล",
                  message: "คุณต้องการลบกลุ่มคําถามนี้ใช่หรือไม่",
                  color: "error",
                  type: "delete",
                });
              }}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="ชื่อ"
                    value={questionGroupName}
                    onChange={(e) => setQuestionGroupName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="รายละเอียด"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack spacing={2} direction="row">
                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={() => navigate("/questions-group-list")}
                        >
                          ยกเลิก
                        </Button>
                        {
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              if (props.questionGroupId) {
                                setOpenConfirm({
                                  open: true,
                                  title: "แก้ไขกลุ่มคำถาม",
                                  message:
                                    "คุณต้องการแก้ไขกลุ่มคำถามนี้ใช่หรือไม่",
                                  color: "primary",
                                  type: "edit",
                                });
                              } else {
                                setOpenConfirm({
                                  open: true,
                                  title: "บันทึกกลุ่มคำถาม",
                                  message:
                                    "คุณต้องการบันทึกกลุ่มคำถามนี้ใช่หรือไม่",
                                  color: "primary",
                                  type: "create",
                                });
                              }
                            }}
                          >
                            บันทึก
                          </Button>
                        }
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            handleDelete();
          } else if (openConfirm.type === "create") {
            handleSave();
          } else if (openConfirm.type === "edit") {
            handleEdit();
          }
        }}
      />
    </Box>
  );
};

export default QuestionGroupCreateView;
