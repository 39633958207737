import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import notificationsService, {
  INotification,
  INotificationViewData,
} from "../Services/NotificationsService";
import NotificationCreateView from "../Views/NotificationCreateView";
import { enqueueSnackbar } from "notistack";
import notificationTypesService, {
  INotificationTypes,
} from "../Services/NotificationTypesService";
import userCustomersService, {
  IUserCustomerCriteria,
  IUserCustomerView,
} from "../../Customers/Services/UserCustomersServices";
import notificationUsersService from "../Services/NotificationUsersService";
interface NotificationControllerProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการจัดการข้อมูล
}

const NotificationCreateController: FC<NotificationControllerProps> = (
  props
) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState<INotificationViewData>();
  const [types, setTypes] = useState<INotificationTypes[] | {}>();
  const [users, setUsers] = useState<IUserCustomerView>();
  useEffect(() => {
    if (id) {
      getOne(id);
    }
    getTypes();
    if (!id) {
      getUsers({
        name: "",
        gender: "",
        platform: "",
        limit: 10,
        page: 0,
        isAll: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const create = async (data: INotification) => {
    await notificationsService
      .create(data)
      .then(async (res: any) => {
        if (res) {
          await getOne(res.id);
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
          if (
            data.isPublish &&
            data.isPublish[data.isPublish?.length - 1] === true
          ) {
            navigate(`/notifications-list`);
          } else {
            navigate(`/notification-edit/${res.id}`);
          }
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const update = async (id: string, data: INotification) => {
    await notificationsService
      .update(id, data)
      .then(async (res) => {
        if (res) {
          await getOne(id);
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
          if (
            data.isPublish &&
            data.isPublish[data.isPublish?.length - 1] === true
          ) {
            navigate(`/notifications-list`);
          } else {
            navigate(`/notification-edit/${id}`);
          }
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const getOne = async (id: string) => {
    const res = await notificationsService.getOne(id);
    if (res) {
      setData(res);
      setUsers(res.users);
    }
  };

  const deleteNotification = async (id: string) => {
    await notificationsService
      .deleteNotification(id)
      .then((res) => {
        if (res) {
          enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
          navigate("/notifications-list");
        } else {
          enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const getTypes = async () => {
    try {
      const res = await notificationTypesService.getAll();
      if (res) {
        setTypes(res);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const getUsers = async (criteria: IUserCustomerCriteria) => {
    if (!id) {
      userCustomersService.getCustomerByCriteria(criteria).then((res) => {
        if (res) {
          setUsers(res);
        }
      });
    } else {
      getUsersNotification(criteria);
    }
  };

  const getUsersNotification = async (criteria: IUserCustomerCriteria) => {
    try {
      const res =
        await notificationUsersService.getUserInNotificationUsersByCriteria(
          criteria
        );
      if (res) {
        setUsers(res);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  return (
    <NotificationCreateView
      getOne={getOne}
      onCreate={create}
      onUpdate={update}
      onDelete={deleteNotification}
      onSearch={getUsers}
      data={data ?? ({} as INotificationViewData)}
      notificationId={id ?? ""}
      types={types ?? ({} as INotificationTypes[])}
      users={users ?? ({} as IUserCustomerView)}
      id={id ?? ""}
    />
  );
};

export default NotificationCreateController;
