import { makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IPackage } from "../../Packages/Services/PackagesService";
import { IUserCustomer } from "./UserCustomersServices";
import { ISortBy } from "../../Gods/Services/GodsService";



export interface IUserPaymentCriteria {
    name: string;
    page: number;
    limit: number;
    sortBy?: ISortBy;
}

export interface IUserPaymentView {
    datas: IUserPaymentList[];
    all: number;
    limit: number;
    page: number;
    total: number;
}

export interface IUserPaymentList{
    userId: string;
    name: string;
    store: string;
    package: string;
    createAt: Date;
    expireAt: Date;
    birthDate: string;
    birthTimeStart: string;
    birthTimeEnd: string;
}

export interface IUserPaymentHistory{
    id: string;
    user: IUserCustomer;
    store: string;
    package: IPackage;
    createAt: Date;
    expireAt: Date;
}

export interface IUserPaymentHistoryCriteria {
    userId: string;
    from?: Date;
    to?: Date;
    page: number;
    limit: number;
    sortBy?: ISortBy;
}

export interface IUserPaymentHistoryView {
    datas: IUserPaymentHistory[];
    all: number;
    limit: number;
    page: number;
    total: number;
}

class UserPaymentsServices {
    payments: IUserPaymentView = {} as IUserPaymentView;
    paymentsHistory: IUserPaymentHistoryView = {} as IUserPaymentHistoryView;
    
    constructor() {
        makeAutoObservable(this);
    }

    async getAll() {
        try {
            const response = await apiService.get("/user-payments");
            if (response.length > 0) {
                this.payments = {
                    datas: response,
                    all: response.length,
                    limit: 10,
                    page: 1,
                    total: response.length
                };
            }
            return this.payments;
        } catch (error) {
            throw error;
        }
    }

    async getCustomerData(id: string) {
        try {
            const response = await apiService.get(`/users/payment-history/customer/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getUserPaymentHistory(id: string) {
        try {
            const response = await apiService.get(`/user-payment-histories/${id}`);
            if (response.length > 0) {
                this.paymentsHistory = {
                    datas: response,
                    all: response.length,
                    limit: 10,
                    page: 1,
                    total: response.length
                };
            } else {
                this.paymentsHistory = {
                    datas: [],
                    all: 0,
                    limit: 10,
                    page: 1,
                    total: 0
                };
            }
            return this.paymentsHistory;
        } catch (error) {
            throw error;
        }
    }

    async getUserPaymentByCriteria(criteria: IUserPaymentCriteria) {
        try {
            const response = await apiService.post("users/criteria/payment-history", criteria);
            this.payments = {
                datas: response.data,
                all: response.length,
                limit: response.limit,
                page: response.page,
                total: response.total
            };
            return this.payments;
        } catch (error) {
            throw error;
        }
    }
    async getUserPaymentHistoryByCriteria(criteria: IUserPaymentHistoryCriteria) {
        try {
            const response = await apiService.post("users/criteria/payment-history-by-user", criteria);
            if(response.data && response.data.length > 0) {

            this.paymentsHistory = {
                datas: response.data,
                all: response.length,
                limit: response.limit,
                page: response.page,
                total: response.total
            };
        } else{
                this.paymentsHistory = {
                    datas: [],
                    all: 0,
                    limit: 10,
                    page: 1,
                    total: 0
                };
            }
            return this.paymentsHistory;
        } catch (error) {
            throw error;
        }
    }



}

const userPaymentsService = new UserPaymentsServices();
export default userPaymentsService;
  