import React, { FC, useEffect, useState } from 'react';
import DashboardView from '../Views/DashboardView';
import dashboardService, { IDashboardData } from '../Services/DashboardService';
import { enqueueSnackbar } from 'notistack';

interface DashboardControllerProps {}

const DashboardController: FC<DashboardControllerProps> = () => {
    const [dashboardData, setDashboardData] = useState<IDashboardData | null>(null);

    const fetchDashboardData = async (month: number | 'all', year: number) => {
        try {
            const data = await dashboardService.getDashboardData(month, year); 
            setDashboardData(data);
        } catch (error) {
            console.error(error);
            enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
        }
    };

    useEffect(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();
        
        fetchDashboardData(currentMonth, currentYear); 
    }, []);

    return <DashboardView dashboardData={dashboardData} onFetchDashboardData={fetchDashboardData} />;
};

export default DashboardController;
