import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { IFormula } from "../features/Formulas/Services/FormulasService";
import { useEffect, useState } from "react";
import { IGod } from "../features/Gods/Services/GodsService";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogConfirm from "./DialogConfirm";
import { checkValidation } from "../utils";
import { IQuestionsGroup } from "../features/Questions/Services/QuestionsService";
import loginService from "../features/Login/Services/LoginService";
interface DialogCreateFormulaProps {
  open: boolean;
  data?: IFormula;
  onClose: () => void;
  gods: IGod[];
  groups: IQuestionsGroup[];
  onSubmit: (data: IFormula) => void;
  onDelete: (id: string) => void;
}

const DialogCreateFormula: React.FC<DialogCreateFormulaProps> = (
  props: DialogCreateFormulaProps
) => {
  const user = loginService.user;
  const [baziYearTg, setBaziYearTg] = useState<number | undefined>();
  const [baziYearDz, setBaziYearDz] = useState<number | undefined>();
  const [baziMonthTg, setBaziMonthTg] = useState<number | undefined>();
  const [baziMonthDz, setBaziMonthDz] = useState<number | undefined>();
  const [baziDayTg, setBaziDayTg] = useState<number | undefined>();
  const [baziDayDz, setBaziDayDz] = useState<number | undefined>();
  const [baziHourTg, setBaziHourTg] = useState<number | undefined>();
  const [baziHourDz, setBaziHourDz] = useState<number | undefined>();
  const [mingGuaM, setMingGuaM] = useState<number | undefined>();
  const [mingGuaF, setMingGuaF] = useState<number | undefined>();
  const [god, setGod] = useState(0);
  const [group, setGroup] = useState(0);
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  const resetValue = () => {
    setBaziYearTg(undefined);
    setBaziYearDz(undefined);
    setBaziMonthTg(undefined);
    setBaziMonthDz(undefined);
    setBaziDayTg(undefined);
    setBaziDayDz(undefined);
    setBaziHourTg(undefined);
    setBaziHourDz(undefined);
    setMingGuaM(undefined);
    setMingGuaF(undefined);
  };

  const handleSave = () => {
    if (!checkValidate() && god !== 0 && group !== 0) {
      props.onSubmit({
        baziYearTg: baziYearTg === undefined ? undefined : baziYearTg,
        baziYearDz: baziYearDz === undefined ? undefined : baziYearDz,
        baziMonthTg: baziMonthTg === undefined ? undefined : baziMonthTg,
        baziMonthDz: baziMonthDz === undefined ? undefined : baziMonthDz,
        baziDayTg: baziDayTg === undefined ? undefined : baziDayTg,
        baziDayDz: baziDayDz === undefined ? undefined : baziDayDz,
        baziHourTg: baziHourTg === undefined ? undefined : baziHourTg,
        baziHourDz: baziHourDz === undefined ? undefined : baziHourDz,
        mingGuaM: mingGuaM === undefined ? undefined : mingGuaM,
        mingGuaF: mingGuaF === undefined ? undefined : mingGuaF,
        god: god,
        questionGroup: group,
        createAt: new Date(),
        createBy: user?.id ?? "",
      });

      resetValue();
    }
  };

  const handleEdit = () => {
    if (!checkValidate()) {
      props.onSubmit({
        id: props.data?.id ? props.data?.id : undefined,
        baziYearTg: baziYearTg === undefined ? undefined : baziYearTg,
        baziYearDz: baziYearDz === undefined ? undefined : baziYearDz,
        baziMonthTg: baziMonthTg === undefined ? undefined : baziMonthTg,
        baziMonthDz: baziMonthDz === undefined ? undefined : baziMonthDz,
        baziDayTg: baziDayTg === undefined ? undefined : baziDayTg,
        baziDayDz: baziDayDz === undefined ? undefined : baziDayDz,
        baziHourTg: baziHourTg === undefined ? undefined : baziHourTg,
        baziHourDz: baziHourDz === undefined ? undefined : baziHourDz,
        mingGuaM: mingGuaM === undefined ? undefined : mingGuaM,
        mingGuaF: mingGuaF === undefined ? undefined : mingGuaF,
        god: god,
        questionGroup: group,
        updateAt: new Date(),
        updateBy: user?.id ?? "",
      });
    }
  };

  const handleDelete = () => {
    if (props.data?.id) {
      props.onDelete(props.data?.id);
    }
  };



  const handleChange = (value: string, state: string) => {
    if (/^\d*$/.test(value)) {
      const numericValue = value === "" ? undefined : parseInt(value);

      switch (state) {
        case "baziYearTg":
          setBaziYearTg(numericValue);
          break;
        case "baziYearDz":
          setBaziYearDz(numericValue);
          break;
        case "baziMonthTg":
          setBaziMonthTg(numericValue);
          break;
        case "baziMonthDz":
          setBaziMonthDz(numericValue);
          break;
        case "baziDayTg":
          setBaziDayTg(numericValue);
          break;
        case "baziDayDz":
          setBaziDayDz(numericValue);
          break;
        case "baziHourTg":
          setBaziHourTg(numericValue);
          break;
        case "baziHourDz":
          setBaziHourDz(numericValue);
          break;
        case "mingGuaM":
          setMingGuaM(numericValue);
          break;
        case "mingGuaF":
          setMingGuaF(numericValue);
          break;
      }
    }
  };

  const checkValidate = () => {
    if (
      !checkValidation(group, "กรุณาเลือกกลุ่มคำถาม") ||
      !checkValidation(god, "กรุณากรอกเทพเจ้า") ||
      god !== 0 ||
      group !== 0
    )
      return false;
  };

  useEffect(() => {
    const god: any = props.data?.god || 0;
    const questionGroup: any = props.data?.questionGroup || 0;
    setBaziYearTg(props.data?.baziYearTg ?? undefined);
    setBaziYearDz(props.data?.baziYearDz ?? undefined);
    setBaziMonthTg(props.data?.baziMonthTg ?? undefined);
    setBaziMonthDz(props.data?.baziMonthDz ?? undefined);
    setBaziDayTg(props.data?.baziDayTg ?? undefined);
    setBaziDayDz(props.data?.baziDayDz ?? undefined);
    setBaziHourTg(props.data?.baziHourTg ?? undefined);
    setBaziHourDz(props.data?.baziHourDz ?? undefined);
    setMingGuaM(props.data?.mingGuaM ?? undefined);
    setMingGuaF(props.data?.mingGuaF ?? undefined);
    setGod(god.id || 0);
    setGroup(questionGroup.id || 0);
  }, [props.data]);

  return (
    <Dialog open={props.open} fullWidth maxWidth={"lg"}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          เพิ่มสูตร
          <Grid item xs={3} display={"flex"} justifyContent={"end"}>
            {props?.data?.id && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setOpenConfirm({
                    open: true,
                    title: "ลบข้อมูล",
                    message: "คุณต้องการลบข้อมูลนี้ใช่หรือไม่",
                    color: "error",
                    type: "delete",
                  });
                }}
              >
                ลบข้อมูล
              </Button>
            )}
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 0.5 }}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="promotionType-select-label">
                กลุ่มคําถาม
              </InputLabel>
              <Select
                labelId="promotionType-select-label"
                value={group}
                id="promotionType-select"
                label="กลุ่มคําถาม"
                onChange={(e) => {
                  setGroup(e.target.value as number);
                  localStorage.setItem("group", JSON.stringify(e.target.value));
                }}
              >
                <MenuItem value={0}>กรุณาเลือกกลุ่มคำถาม</MenuItem>
                {props.groups && props.groups.length > 0 ? (
                  props.groups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>ไม่พบข้อมูล</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="promotionType-select-label">เทพเจ้า</InputLabel>
              <Select
                labelId="promotionType-select-label"
                value={god}
                id="promotionType-select"
                label="เทพเจ้า"
                onChange={(e) => {
                  setGod(e.target.value as number);
                  localStorage.setItem("god", JSON.stringify(e.target.value));
                }}
              >
                <MenuItem value={0}>กรุณาเลือกเทพเจ้า</MenuItem>
                {props.gods && props.gods.length > 0 ? (
                  props.gods.map((god) => (
                    <MenuItem key={god.id} value={god.id}>
                      {god.title}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>ไม่พบข้อมูล</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              id="outlined-basic"
              label="Bazi Year Tg"
              variant="outlined"
              type="text"
              fullWidth
              onChange={(event) =>
                handleChange(event.target.value, "baziYearTg")
              }
              value={baziYearTg ?? ""}
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              id="outlined-basic"
              label="Bazi Year Dz"
              variant="outlined"
              type="text"
              fullWidth
              onChange={(event) =>
                handleChange(event.target.value, "baziYearDz")
              }
              value={baziYearDz ?? ""}
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              id="outlined-basic"
              label="Bazi Month Tg"
              variant="outlined"
              type="text"
              fullWidth
              onChange={(event) =>
                handleChange(event.target.value, "baziMonthTg")
              }
              value={baziMonthTg ?? ""}
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              id="outlined-basic"
              label="Bazi Month Dz"
              variant="outlined"
              type="text"
              fullWidth
              onChange={(event) =>
                handleChange(event.target.value, "baziMonthDz")
              }
              value={baziMonthDz ?? ""}
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              id="outlined-basic"
              label="Bazi Day Tg"
              variant="outlined"
              type="text"
              fullWidth
              onChange={(event) =>
                handleChange(event.target.value, "baziDayTg")
              }
              value={baziDayTg ?? ""}
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              id="outlined-basic"
              label="Bazi Day Dz"
              variant="outlined"
              type="text"
              fullWidth
              onChange={(event) =>
                handleChange(event.target.value, "baziDayDz")
              }
              value={baziDayDz ?? ""}
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              id="outlined-basic"
              label="Bazi Hour Tg"
              variant="outlined"
              type="text"
              fullWidth
              onChange={(event) =>
                handleChange(event.target.value, "baziHourTg")
              }
              value={baziHourTg ?? ""}
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              id="outlined-basic"
              label="Bazi Hour Dz"
              variant="outlined"
              type="text"
              fullWidth
              onChange={(event) =>
                handleChange(event.target.value, "baziHourDz")
              }
              value={baziHourDz ?? ""}
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              id="outlined-basic"
              label="Ming Gua M"
              variant="outlined"
              type="text"
              fullWidth
              onChange={(event) => handleChange(event.target.value, "mingGuaM")}
              value={mingGuaM ?? ""}
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextField
              id="outlined-basic"
              label="Ming Gua F"
              variant="outlined"
              type="text"
              fullWidth
              onChange={(event) => handleChange(event.target.value, "mingGuaF")}
              value={mingGuaF ?? ""}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => props.onClose()}>
          ยกเลิก
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (props.data?.id) {
              setOpenConfirm({
                open: true,
                title: "แก้ไขสูตร",
                message: "คุณต้องการแก้ไขสูตรนี้ใช่หรือไม่",
                color: "primary",
                type: "edit",
              });
            } else {
              setOpenConfirm({
                open: true,
                title: "บันทึกสูตร",
                message: "คุณต้องการบันทึกสูตรนี้ใช่หรือไม่",
                color: "primary",
                type: "create",
              });
            }
          }}
        >
          {props.data?.id ? "แก้ไข" : "บันทึก"}
        </Button>
      </DialogActions>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            handleDelete();
          } else if (openConfirm.type === "create") {
            handleSave();
          } else if (openConfirm.type === "edit") {
            handleEdit();
          }
        }}
      />
    </Dialog>
  );
};

export default DialogCreateFormula;
