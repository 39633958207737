import { FC, useEffect, useState } from "react";
import NotificationTypeCreateView from "../../Views/notificationsType/NotificationTypeCreateView";
import notificationTypesService, {
  INotificationTypes,
} from "../../Services/NotificationTypesService";
import { enqueueSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";

interface NotificationTypeCreateControllerProps {}

const NotificationTypeCreateController: FC<NotificationTypeCreateControllerProps> = (
  props
) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<INotificationTypes>();

  useEffect(() => {
    if (id) {
      getOne(id);
    }
  }, [id]);

  const create = async (data: INotificationTypes) => {
    await notificationTypesService
      .create(data)
      .then((res: any) => {
        if (res) {
          if (res.message) {
            enqueueSnackbar("ชื่อประเภทการแจ้งเตือนนี้มีอยู่แล้ว", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
            navigate(`/notification-type-edit/${res.id}`);
          }
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const update = async (id: string, data: INotificationTypes) => {
    await notificationTypesService
      .update(id, data)
      .then((res) => {
        if (res) {
          if (res.message) {
            enqueueSnackbar("ชื่อประเภทการแจ้งเตือนนี้มีอยู่แล้ว", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
          }
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const deleteNotificationType = async (id: string) => {
    await notificationTypesService
      .deleteNotificationType(id)
      .then((res) => {
        if (res) {
          enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
          navigate("/notification-types-list");
        } else {
          enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const getOne = async (id: string) => {
    try {
      const res = await notificationTypesService.getOne(id);
      if (res) {
        setData(res);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  return (
    <NotificationTypeCreateView
      onCreate={create}
      onUpdate={update}
      onDelete={deleteNotificationType}
      data={data ?? ({} as INotificationTypes)}
      notificationTypeId={id ?? ""}
    />
  );
};

export default NotificationTypeCreateController;
