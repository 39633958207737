import { makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";

// COMMENT : ไว้ส่งค่าไป create, update
export interface ISystemUser {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    tel?: string;
    username?: string;
    password?: string;
    role?: number;
    isActive?: boolean;
    createBy?: string;
    updateBy?: string;
    createAt?: Date;
    updateAt?: Date;
}

export interface ISystemUserCriteria {
    name: string;
    email: string;
    username: string;
    role: number;
    page: number;
    limit: number;
}

export interface ISystemUserViewData {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    tel?: string;
    username?: string;
    password?: string;
    role: IUserRole;
    createAt: Date;
    updateAt: Date;
    createBy: IUser;
    updateBy: IUser;
    access_token: string;
    refresh_token: string;
}


export interface IUser {
    firstName: string;
    lastName: string;
    email: string;
    tel: string;
    username: string;
}

export interface IUserRole {
    id: number;
    name: string;
    createAt: Date;
    updateAt: Date;
}


export interface IsystemUserView {
    datas: ISystemUserViewData[];
    all: number;
    limit: number;
    page: number;
    total: number;
}

class SystemUserService {
    systemUsers: IsystemUserView = {} as IsystemUserView;

    constructor() {
        makeAutoObservable(this);
    }

    async getAll() {
        try {
            const response = await apiService.get("/system-users");
            if (response.length > 0) {
                this.systemUsers = {
                    datas: response,
                    all: response.length,
                    limit: 10,
                    page: 1,
                    total: response.length
                };
            } else {
                this.systemUsers = {
                    datas: [],
                    all: 0,
                    limit: 10,
                    page: 1,
                    total: 0
                };
            }
            return this.systemUsers;
        } catch (error) {
            throw error;
        }
    }

    async getOne(id: string) {
        try {
            const response = await apiService.get(`/system-users/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getByCriteria(criteria: ISystemUserCriteria) {
        try {
            const response = await apiService.post("/system-users/criteria", criteria);
            if (response.data.length > 0) {
                this.systemUsers = {
                    datas: response.data,
                    all: response.length,
                    limit: response.limit,
                    page: response.page,
                    total: response.total
                };
            } else {
                this.systemUsers = {
                    datas: [],
                    all: 0,
                    limit: 10,
                    page: 1,
                    total: 0
                };
            }
            return this.systemUsers;
        } catch (error) {
            this.systemUsers = {
                datas: [],
                all: 0,
                limit: 10,
                page: 1,
                total: 0
            };
            return this.systemUsers;
        }
    }

    async create(data: ISystemUser) {
        try {
            const response = await apiService.post("/system-users", data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async update(id: string, data: ISystemUser) {
        try {
            const response = await apiService.put(`/system-users/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async delete(id: string) {
        try {
            const data = {
                isActive: false,
                updateAt: new Date(),
            };
            const response = await apiService.put(`/system-users/delete/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

const systemUserService = new SystemUserService();
export default systemUserService;