import React, { useEffect, useState } from "react";
import {
  ISystemUser,
  ISystemUserViewData,
} from "../Services/SystemUsersService";
import SystemUsersService from "../Services/SystemUsersService";
import RoleService from "../Services/RolesService";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import SystemUserCreate from "../Views/SystemUserCreateView";
import { IRole } from "../Services/RolesService";

interface SystemUserCreateControllerProps {
  // Define your view props here
}

const SystemUserCreateController: React.FC<SystemUserCreateControllerProps> = (
  props
) => {
  const { id } = useParams();
  const [systemUsers, setSystemUsers] = useState<ISystemUserViewData>();
  const [roles, setRoles] = useState<IRole[]>();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getOne(id);
    }
    getRole();
  }, [id]);

  const getOne = async (id: string) => {
    try {
      const res = await SystemUsersService.getOne(id);
      if (res) {
        setSystemUsers(res);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const create = async (data: ISystemUser) => {
    await SystemUsersService.create(data).then((res) => {
      if (res) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        navigate(`/system-user-edit/${res.id}`);
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    }).catch((err) => {
        console.error(err);
      enqueueSnackbar(err.response.data, { variant: "error" });
    })
  };

  const getRole = async () => {
    try {
      const res = await RoleService.getAll();
      if (res) {
        setRoles(res);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const update = async (data: ISystemUser) => {
    await SystemUsersService.update(id ?? "", data).then((res) => {
      if (res) {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        navigate("/system-users-list");
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    }).catch((err) => {
        console.error(err);
      enqueueSnackbar(err.response.data, { variant: "error" });
    })
  };

  const deleteSystemUser = async () => {
    await SystemUsersService.delete(id ?? "").then((res) => {
      if (res) {
        enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
        navigate("/system-users-list");
      } else {
        enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    }).catch((err) => {
        console.error(err);
      enqueueSnackbar(err.response.data, { variant: "error" });
    })
  };

  return (
    <SystemUserCreate
      systemUser={systemUsers ?? ({} as ISystemUserViewData)}
      onCreate={(data: ISystemUser) => {
        create(data);
      }}
      onUpdate={(data: ISystemUser) => {
        update(data);
      }}
      onDelete={() => {
        deleteSystemUser();
      }}
      systemUserId={id ?? ""}
      roles={roles ?? []}
    />
  );
};

export default SystemUserCreateController;
