import { makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IUser } from '../../Settings/Services/SystemUsersService';
import { INotificationTypesViewData } from "./NotificationTypesService";
import { IUserCustomer, IUserCustomerCriteria } from "../../Customers/Services/UserCustomersServices";

export interface INotification {
    id?: string;
    title?: string;
    content?: string;
    type?: number;
    path?: string;
    isDelete?: boolean;
    createAt?: Date;
    updateAt?: Date;
    createBy?: string;
    updateBy?: string;
    users?:IUserNotification[];
    criteria?: IUserCustomerCriteria;
    isAll?:boolean[];
    isDevice?:boolean;
    isPublish?:boolean[];
    detail?:INotificationDetail[];
}

export interface INotificationViewData {
    id?: string;
    title?: string;
    content?: string;
    type?: INotificationTypesViewData;
    path?: string;
    isPublish?:boolean;
    isDelete?: boolean;
    createAt?: Date;
    updateAt?: Date;
    createBy?: IUser;
    updateBy?: IUser;
    detail?:INotificationDetail[];
}

export interface INotificationCriteria {
    title: string;
    content: string;
    type: number;
    page: number;
    limit: number;
}

export interface INotificationView {
    datas: INotificationViewData[];
    all: number;
    limit: number;
    page: number;
    total: number;
}

export interface IUserNotificationView {
    datas: IUserNotification[];
    all: number;
    limit: number;
    page: number;
    total: number;
}

export interface IUserNotification{
    user: IUserCustomer;
notification?: INotificationViewData ;
createAt?: Date;
createBy?: string;
updateAt?: Date;
updateBy?: string;
}

export interface INotificationDetail{
    id?: string;
    title?: string;
    lang?: string;
    content?: string;
    createAt?: Date;
    updateAt?: Date;
    createBy?: string;
    updateBy?: string;
}

class NotificationsService {
    notifications: INotificationView = {} as INotificationView;

    constructor() {
        makeAutoObservable(this);
    }

    async getAll() {
        try {
            const response = await apiService.get("/notifications");
            if (response.length > 0) {
                this.notifications = {
                    datas: response.filter((x: INotification) => !x.isDelete),
                    all: response.length,
                    limit: 10,
                    page: 1,
                    total: response.length
                };
            }
            return this.notifications;
        } catch (error) {
            throw error;
        }
    }

    async getOne(id: string) {
        try {
            const response = await apiService.get(`/notifications/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getByCriteria(criteria: INotificationCriteria) {
        try {
            const response = await apiService.post("/notifications/criteria", criteria);
            this.notifications = {
                datas: response.data.filter((x: INotification) => !x.isDelete),
                all: response.length,
                limit: response.limit,
                page: response.page,
                total: response.total
            };
            return this.notifications;
        } catch (error) {
            throw error;
        }
    }

    async create(data: INotification) {
        try {
            const response = await apiService.post(`/notifications/`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async update(id: string, data: INotification) {
        try {
            const response = await apiService.put(`/notifications/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async deleteNotification(id: string) {
        try {
            const data = { isDelete: true };
            const response = await apiService.put(`/notifications/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

}


const notificationsService = new NotificationsService();
export default notificationsService;