const config = {
    readonly: false,
    placeholder:'',
    buttons: [
        'bold',
        'italic',
        'underline',
        '|',
        'ul',
        'ol',
        '|',
        'outdent',
        'indent',
        '|',
        'font',
        'fontsize',
        'brush',
        'paragraph',
        '|',
        'table',
        'link',
        '|',
        'align',
        'undo',
        'redo',
        '\n',
        'cut',
        'hr',
        'eraser',
        'copyformat',
        '|',
        'symbol',
        'fullsize',
        'selectall',
        'print',
        'about',
    ],
    style: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal'
        
    }
};

export default config;