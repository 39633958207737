import {
  Box,
  Breadcrumbs,
  Button,
  ButtonProps,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogConfirm from "../../../../components/DialogConfirm";
import { checkValidation } from "../../../../utils";
import {
  IQuestion,
  IQuestionDetail,
  IQuestionType,
  IQuestionViewData,
  IQuestionsGroup,
} from "../../Services/QuestionsService";
import { enqueueSnackbar } from "notistack";
import loginService from "../../../Login/Services/LoginService";
import langs from "../../../../constants/langs";
import JoditConfig from "../../../../constants/joditConfig";
import JoditEditor from "jodit-react";
import { TabContext, TabList, TabPanel } from "@mui/lab";


interface QuestionViewProps {
  questionId: string;
  questionTypes: IQuestionType[];
  questionGroups: IQuestionsGroup[];
  data: IQuestionViewData;
  onCreate: (data: IQuestion) => void;
  onUpdate: (id: string, data: IQuestion) => void;
  onDelete: (id: string, data: IQuestion) => void;
}

const QuestionCreateView: FC<QuestionViewProps> = (props) => {
  const navigate = useNavigate();
  const user = loginService.user;
  const [questionName, setQuestionName] = useState("");
  const [groupName, setGroupName] = useState("select-group");
  const [typeName, setTypeName] = useState("select-type");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [value, setValue] = useState("th");
  const [details, setDetails] = useState<IQuestionDetail[]>([]);
  const [index, setIndex] = useState(0);

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  useEffect(() => {
    
    if (props.data) {
      setQuestionName(props.data.name ?? "");
      setDescription(props.data.description ?? "");
      if (props.data.questionGroup) {
        setGroupName(props.data?.questionGroup.id ?? "select-group");
      }
      if (props.data.questionType) {
        setTypeName(props.data?.questionType.id ?? "select-type");
      }
      setIsActive(props.data.isActive ?? true);
    }
    setIndex(0);
    setValue("th");
    if (props.data?.detail && props.data?.detail?.length > 0) {
      props.data.detail.forEach((detail: any) => {
        const findNotificationIndex = langs.findIndex(
          (lang) => lang.value === detail.lang
        );
        const findDetailIndex = details.findIndex(
          (data) => data.lang === detail.lang
        );
        if (findDetailIndex !== -1) {
          setDetails((prevDetails) => {
            prevDetails[findDetailIndex] = {
              id: detail.id,
              ...prevDetails[findDetailIndex],
              name: detail.name,
              description: detail.description,
            };
            return prevDetails;
          });
        } else {
          details.push({
            lang: langs[findNotificationIndex].value,
            name: "",
            description: "",
          });
        }
      });
    } else {
      langs.forEach((data) => {
        if (details.length < langs.length) {
          details.push({
            lang: data.value,
            name: "",
            description: "",
          });
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const handleSave = () => {
    if (checkValidate()) {
      const detail: IQuestionDetail[] = details.filter(
        (data: IQuestionDetail) => data.name !== "" 
      );
      props.onCreate({
        name: questionName,
        description: description,
        questionGroup: groupName,
        questionType: typeName,
        isActive: isActive,
        createAt: new Date(),
        createBy: user?.id ?? "",
        detail: detail,
      });
    }
  };

  const handleEdit = () => {
    if (checkValidate()) {
      const detail: IQuestionDetail[] = details.filter(
        (data: IQuestionDetail) => data.name !== "" 
      );
      props.onUpdate(props.questionId ?? "", {
        name: questionName,
        description: description,
        questionGroup: groupName,
        questionType: typeName,
        isActive: isActive,
        updateAt: new Date(),
        updateBy: user?.id ?? "",
        detail: detail,
      });
    }
  };
  const handleDelete = () => {
    const detail: IQuestionDetail[] = details.filter(
      (data: IQuestionDetail) => data.name !== ""
    );
    props.onDelete(props.questionId ?? "",{isDelete:true,detail:detail});
  };

  const decodeHtml =(html: string): string=> {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  const checkValidate = () => {
    const isQuestion = details.filter((data: any) => {
      if (typeof data.name !== 'string') {
        return false; // Skip if name is not a string
      }
      const decodedName = decodeHtml(data.name);
      const strippedName = decodedName.replace(/<[^>]+>/g, "").trim();
      return strippedName.length > 0; // Check if the stripped name is not empty
    });
    const _detail = details.filter(
      (data: IQuestionDetail) => data.name !== ""  
    );
    if (groupName === "select-group") {
      enqueueSnackbar("กรุณาระบุกลุ่ม", { variant: "error" });
      return false;
    }
    if (typeName === "select-type") {
      enqueueSnackbar("กรุณาระบุประเภท", { variant: "error" });
      return false;
    }
    if (
      !checkValidation(_detail.length > 0, "กรุณากรอกคำถามอย่างน้อย 1 ภาษา")
    ) {
      return false;
    }
    if (isQuestion.length === 0 ) {
      enqueueSnackbar("กรุณาระบุคำถาม", { variant: "error" });
      return false;
    }
    return true;
  };

  const config = JoditConfig;
  const editorNotification = useMemo(
    () => {
      return (
        <JoditEditor
          config={config}
          value={details[index]?.name || ""}
          onBlur={(e) => {
            setDetails((pre: any) => {
              const _pre = [...pre];
              _pre[index].name = e;
              return _pre;
            });
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [index, details[index]?.name]
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setIndex((prevIndex: number) => {
      const findNotificationIndex = details.findIndex(
        (data) => data.lang === newValue
      );
      if (findNotificationIndex !== -1) {
        return findNotificationIndex;
      } else {
        details.push({ lang: newValue, name: "", description: "" });
        return details.length - 1;
      }
    });
  };
  return (
    <Box component={"div"}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/questions-list")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>คำถาม</Typography>
            <Link
              component="button"
              onClick={() => navigate("/questions-list")}
            >
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.questionId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"end"}>
          {props.questionId && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setOpenConfirm({
                  open: true,
                  title: "ลบรายการ",
                  message: "คุณต้องการลบรายการนี้ใช่หรือไม่",
                  color: "error",
                  type: "delete",
                });
              }}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="questionGroup-select-label">
                      กลุ่ม
                    </InputLabel>
                    <Select
                      labelId="questionGroup-select-label"
                      id="questionGroup-select"
                      value={groupName}
                      label="ตำแหน่ง"
                      onChange={(e: any) => {
                        setGroupName(e.target.value);
                      }}
                    >
                      <MenuItem value="select-group">กรุณาเลือกกลุ่ม</MenuItem>
                      {props.questionGroups &&
                      props.questionGroups.length > 0 ? (
                        props.questionGroups.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>ไม่พบข้อมูล</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="questionType-select-label">
                      ประเภท
                    </InputLabel>
                    <Select
                      labelId="questionType-select-label"
                      id="questionType-select"
                      value={typeName}
                      label="ตำแหน่ง"
                      onChange={(e: any) => {
                        setTypeName(e.target.value);
                      }}
                    >
                      <MenuItem value="select-type">กรุณาเลือกประเภท</MenuItem>
                      {props.questionTypes && props.questionTypes.length > 0 ? (
                        props.questionTypes.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>ไม่พบข้อมูล</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
 <Grid item xs={12}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        {langs.map((item) => (
                          <Tab
                            key={item.value}
                            label={item.name}
                            value={item.value}
                          />
                        ))}
                      </TabList>
                    </Box>
                    {langs.map((item) => (
                      <TabPanel value={item.value}>
                        <Grid item xs={12}>
                          {editorNotification}
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          <TextField
                            id="name"
                            label="รายละเอียดคำถาม"
                            variant="outlined"
                            fullWidth
                            value={details[index]?.description || ""}
                            onChange={(e) => {
                              setDetails((pre: any) => {
                                const _pre = [...pre];
                                _pre[index].description = e.target.value;
                                return _pre;
                              });
                            }}
                          />
                        </Grid>
                      </TabPanel>
                    ))}
                  </TabContext>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={8}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isActive}
                          onChange={(e) => {
                            setIsActive(e.target.checked);
                          }}
                        />
                      }
                      label="ใช้งาน"
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack spacing={2} direction="row">
                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={() => navigate("/questions-list")}
                        >
                          ยกเลิก
                        </Button>
                        {
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              if (props.questionId) {
                                setOpenConfirm({
                                  open: true,
                                  title: "แก้ไขแพ็คเกจ",
                                  message:
                                    "คุณต้องการแก้ไขแพ็คเกจนี้ใช่หรือไม่",
                                  color: "primary",
                                  type: "edit",
                                });
                              } else {
                                setOpenConfirm({
                                  open: true,
                                  title: "บันทึกแพ็คเกจ",
                                  message:
                                    "คุณต้องการบันทึกแพ็คเกจนี้ใช่หรือไม่",
                                  color: "primary",
                                  type: "create",
                                });
                              }
                            }}
                          >
                            บันทึก
                          </Button>
                        }
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            handleDelete();
          } else if (openConfirm.type === "create") {
            handleSave();
          } else if (openConfirm.type === "edit") {
            handleEdit();
          }
        }}
      />
    </Box>
  );
};

export default QuestionCreateView;
