import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QuestionsTypeService, {
  IQuestionTypeViewData,
  IQuestionType,
  IUploadFile,
} from "../../Services/QuestionTypesService";

import { enqueueSnackbar } from "notistack";
import QuestionTypeCreateView from "../../Views/Question-type/QuestionTypeCreateView";
import { deleteFileImage, deletePreviousImage } from "../../../../utils";

interface QuestionTypeCreateControllerProps {}

const QuestionTypeCreateController: FC<QuestionTypeCreateControllerProps> = (
  props
) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<IQuestionTypeViewData>();
  const [previousImage, setPreviousImage] = useState<any>();

  useEffect(() => {
    if (id) {
      getOne(id);
    }
  }, [id]);

  const create = async (data: IQuestionType, uploadFile: IUploadFile) => {
    await QuestionsTypeService.create(data, uploadFile)
      .then((res: any) => {
        if (res.createdImage) {
          const questionTypeId = res.createdImage.refId;
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
          navigate(`/question-type-edit/${questionTypeId}`);
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const update = async (
    id: string,
    data: IQuestionType,
    uploadFile: IUploadFile
  ) => {
    let response = "";
    const newUploadFile = uploadFile !== previousImage;
    await QuestionsTypeService.update(id, data)
      .then(async (res) => {
        if (res) {
          response = res;
          if (newUploadFile) {
            if (previousImage && uploadFile) {
              const deleteResponse =
                await deletePreviousImage(
                  previousImage.id
                );
              await deleteFileImage(previousImage.name);
              if (deleteResponse) {
                response = deleteResponse;
              }
            }
            if (response && uploadFile) {
              const imageResponse = await QuestionsTypeService.uploadImage(id, {
                file: uploadFile,
              });
              response = imageResponse;
              setPreviousImage(imageResponse.createdImage);
            }
          }
          if (response) {
            enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
          } else {
            enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
          }
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const getOne = async (id: string) => {
    try {
      const res = await QuestionsTypeService.getOne(id);
      if (res) {
        setData(res);
        setPreviousImage(res.image);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const deleteQuestionType = async (id: string) => {
    await QuestionsTypeService.delete(id)
      .then((res) => {
        if (res) {
          enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
          navigate("/questions-type-list");
        } else {
          enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  return (
    <QuestionTypeCreateView
      getOne={getOne}
      onCreate={create}
      onUpdate={update}
      onDelete={(id: string) => deleteQuestionType(id)}
      data={data ?? ({} as IQuestionTypeViewData)}
      questionTypeId={id ?? ""}
      previousImage={previousImage}
    />
  );
};

export default QuestionTypeCreateController;
