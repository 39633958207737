import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  NoteAdd as NoteAddIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  IQuestionCriteria,
  IQuestionType,
  IQuestionView,
} from "../../Services/QuestionsService";
import { IQuestionsGroup } from "../../Services/QuestionsGroupService";

interface QuestionsListViewProps {
  questions: IQuestionView;
  questionTypes: IQuestionType[];
  questionGroups: IQuestionsGroup[];
  onSearch: (criteria: IQuestionCriteria) => void;
  onUpdateIsActive: (id: string, isActive: boolean) => void;
}

const QuestionsListView: FC<QuestionsListViewProps> = (props) => {
  const navigate = useNavigate();
  const [questionName, setQuestionName] = useState("");
  const [groupName, setGroupName] = useState("all");
  const [typeName, setTypeName] = useState("all");
  const [isActive, setIsActive] = useState(true);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber === 0 ? 0 : pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IQuestionCriteria = {
      name: questionName,
      questionGroup: groupName === "all" ? "" : groupName,
      questionType: typeName === "all" ? "" : typeName,
      isActive: isActive,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSearch(criteria);
  };

  return (
    <Box component={"div"}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>คำถาม</Typography>
        <Typography variant="h6" color="text.primary">
          รายการ
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="questionGroup-select-label">
                      กลุ่ม
                    </InputLabel>
                    <Select
                      labelId="questionGroup-select-label"
                      id="questionGroup-select"
                      value={groupName}
                      label="ตำแหน่ง"
                      onChange={(e: any) => {
                        const groupName = e.target.value;
                        setGroupName(groupName);
                      }}
                    >
                      <MenuItem value="all">ทั้งหมด</MenuItem>
                      {props.questionGroups &&
                      props.questionGroups.length > 0 ? (
                        props.questionGroups.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>ไม่พบข้อมูล</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="questionType-select-label">
                      ประเภท
                    </InputLabel>
                    <Select
                      labelId="notificationGroup-select-label"
                      id="notificationGroup-select"
                      value={typeName}
                      label="ตำแหน่ง"
                      onChange={(e: any) => {
                        const typeName = e.target.value;
                        setTypeName(typeName);
                      }}
                    >
                      <MenuItem value="all">ทั้งหมด</MenuItem>
                      {props.questionTypes && props.questionTypes.length > 0 ? (
                        props.questionTypes.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>ไม่พบข้อมูล</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="คำถาม"
                    onChange={(e) => {
                      setQuestionName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={2} textAlign={"center"} alignSelf={"center"}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isActive}
                          onChange={(e) => {
                            setIsActive(e.target.checked);
                          }}
                        />
                      }
                      label="ใช้งานอยู่"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={2} textAlign={"center"} alignSelf={"center"}>
                  <Button
                    variant="contained"
                    onClick={() => searchByCriteria(0)}
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography>
                        ทั้งหมด {props.questions?.total} รายการ
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        startIcon={<NoteAddIcon />}
                        onClick={() => navigate("/question-create")}
                      >
                        สร้าง
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">กลุ่ม</TableCell>
                          <TableCell align="center">ประเภท</TableCell>
                          <TableCell align="center">คำถาม</TableCell>
                          <TableCell align="center">รายละเอียด</TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.questions?.datas &&
                        props.questions?.datas?.length > 0 ? (
                          props.questions?.datas.map((question:any, index) => (
                            <TableRow
                              key={question.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center">
                                {question.questionGroup?.name}
                              </TableCell>
                              <TableCell align="center">
                                {question.questionType?.name}
                              </TableCell>
                              <TableCell align="center">
                                {question?.detail?.name?.replace(/<[^>]+>/g, "")  ||""}
                              </TableCell>
                              <TableCell align="center">
                                {question?.detail?.description ||""}
                              </TableCell>
                              <TableCell align="center">
                                <Switch
                                  checked={question.isActive}
                                  onChange={() => {
                                    props.onUpdateIsActive(
                                      question.id!,
                                      !question.isActive
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    navigate(`/question-edit/${question.id}`)
                                  }
                                >
                                  <ChevronRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              <Typography>ไม่พบข้อมูล</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={props.questions?.total ?? 0}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        searchByCriteria(newPage);
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setLimit(e.target.value);
                        setPage(0);
                        searchByCriteria(page, e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuestionsListView;
