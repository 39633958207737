import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import questionsService, {
  IQuestion,
  IQuestionType,
  IQuestionViewData,
  IQuestionsGroup,
} from "../../Services/QuestionsService";
import QuestionCreateView from "../../Views/Question/QuestionCreateView";
import questionsGroupService from "../../Services/QuestionsGroupService";
import questionTypeService from "../../Services/QuestionTypesService";
interface QuestionControllerProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการจัดการข้อมูล
}

const QuestionCreateController: FC<QuestionControllerProps> = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<IQuestionViewData>();
  const [typeName, setTypeName] = useState<IQuestionType[]>();
  const [groupName, setGroupName] = useState<IQuestionsGroup[]>();

  useEffect(() => {
    if (id) {
      getOne(id);
    }
    getQuestionTypes();
    getQuestionGroups();
  }, [id]);

  const create = async (data: IQuestion) => {
    await questionsService
      .create(data)
      .then((res: any) => {
        if (res) {
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
          navigate(`/question-edit/${res.id}`);
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const update = async (id: string, data: IQuestion) => {
    await questionsService
      .update(id, data)
      .then((res) => {
        if (res) {
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const getOne = async (id: string) => {
    const res = await questionsService.getOne(id);
    if (res) {
      setData(res);
    }
  };

  const getQuestionTypes = async () => {
    await questionTypeService
      .getAll()
      .then((res: any) => {
        if (res) {
          setTypeName(res);
        } else {
          enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      });
  };

  const getQuestionGroups = async () => {
    await questionsGroupService
      .getAll()
      .then((res: any) => {
        if (res) {
          setGroupName(res);
        } else {
          enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      });
  };

  return (
    <QuestionCreateView
      questionId={id ?? ""}
      questionTypes={typeName ?? ([] as IQuestionType[])}
      questionGroups={groupName ?? ([] as IQuestionsGroup[])}
      data={data ?? ({} as IQuestionViewData)}
      onCreate={(data: IQuestion) => {
        create(data);
      }}
      onUpdate={(id: string, data: IQuestion) => {
        update(id, data);
      }}
      onDelete={(id: string, data: IQuestion) => {
        update(id,data);
      }}
    />
  );
};

export default QuestionCreateController;
