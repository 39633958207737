import { makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { ISystemUserViewData } from "../../Settings/Services/SystemUsersService";

export interface ILogin {
    username: string;
    password: string;
    isRemember: boolean;
}


class LoginService {
    isAuthenticated = false;
    user: ISystemUserViewData | null = null;
    construnctor() {
        makeAutoObservable(this);
    }

    async login(login: ILogin) {
        const response = await apiService.post("/auth/login/system", login);
        if (response.access_token) {
            this.isAuthenticated = true;
            this.user = response.user;
            if (this.user) {
                this.user.access_token = response.access_token;
                this.user.refresh_token = response.refresh_token;
            }
            localStorage.setItem("user", JSON.stringify(this.user));
            return response;
        } else {
            throw new Error("An error occurred while logging in");
        }
    }


    async checkSessionExpired(token: string) {
        try {
            const response = await apiService.post("/auth/check-session-expired", { token });
            return response;
        } catch (error) {
            return false;
        }
    }

    logout() {
        this.isAuthenticated = false;
        this.user = null;
        localStorage.removeItem("user");
    }

    setUser(user: ISystemUserViewData) {
        this.user = user;
    }
}

const loginService = new LoginService();
export default loginService;