import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  IQuestionsGroupCriteria,
  IQuestionsGroupView,
} from "../../Services/QuestionsGroupService";
import dayjs from "dayjs";

interface QuestionsGroupListViewProps {
  questionsGroup: IQuestionsGroupView;
  onSearch: (criteria: IQuestionsGroupCriteria) => void;
}

const QuestionsGroupListView: FC<QuestionsGroupListViewProps> = (props) => {
  const navigate = useNavigate();
  const [questionGroupName, setQuestionGroupName] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber === 0 ? 0 : pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IQuestionsGroupCriteria = {
      name: questionGroupName,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSearch(criteria);
  };

  return (
    <Box component={"div"}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>คำถาม</Typography>
        <Typography variant="h6" color="text.primary">
          กลุ่มคำถาม
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    label="ชื่อ"
                    onChange={(e) => setQuestionGroupName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2} textAlign={"center"} alignSelf={"center"}>
                  <Button
                    variant="contained"
                    onClick={() => searchByCriteria(0)}
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography>
                        ทั้งหมด {props.questionsGroup.total} รายการ
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        startIcon={<NoteAddIcon />}
                        onClick={() => navigate("/question-group-create")}
                      >
                        สร้าง
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">ชื่อ</TableCell>
                          <TableCell align="center">รายละเอียด</TableCell>
                          <TableCell align="center">บันทึก</TableCell>
                          <TableCell align="center">แก้ไข</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {props.questionsGroup.datas &&
                        props.questionsGroup.datas.length > 0 ? (
                          props.questionsGroup.datas.map((questionGroup) => (
                            <TableRow
                              key={questionGroup.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center">
                                {questionGroup.name}
                              </TableCell>
                              <TableCell align="center">
                                {questionGroup.description}
                              </TableCell>
                              <TableCell align="center">
                                <Typography fontWeight={"bold"}>
                                  {questionGroup.createBy
                                    ? questionGroup.createBy.firstName
                                    : ""}
                                </Typography>
                                <Typography>
                                  {questionGroup.createAt
                                    ? dayjs(questionGroup.createAt).format(
                                        "DD/MM/YYYY HH:mm"
                                      )
                                    : ""}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography fontWeight={"bold"}>
                                  {questionGroup.updateBy
                                    ? questionGroup.updateBy.firstName
                                    : ""}
                                </Typography>
                                <Typography>
                                  {questionGroup.updateAt
                                    ? dayjs(questionGroup.updateAt).format(
                                        "DD/MM/YYYY HH:mm"
                                      )
                                    : ""}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/question-group-edit/${questionGroup.id}`
                                    )
                                  }
                                >
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              <Typography>ไม่พบข้อมูล</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={props.questionsGroup.total ?? 0}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        searchByCriteria(newPage);
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setLimit(e.target.value);
                        setPage(0);
                        searchByCriteria(page, e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuestionsGroupListView;
