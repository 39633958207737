import { Box, Container, CssBaseline, Typography } from "@mui/material";
import { FC } from 'react';
import LoginForm from "../../../components/LoginForm";
import OMG_LOGO from "../../../res/images/omygod_logo.png";

interface LoginViewProps {
    // รับ props ต่างๆที่จำเป็นสำหรับการแสดง UI
    onSubmit: (username: string, password: string, isRemember: boolean) => void;
    hasError: boolean;
    hasSuccess: boolean;
}

const LoginView: FC<LoginViewProps> = (props) => {

    const handleLoginFormSubmit = async (username: string, password: string, isRemember: boolean) => {
        props.onSubmit(username, password, isRemember);
    };
    return (
        <Container component="main" maxWidth="xs" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <CssBaseline />
            <div>
                <Box component={'div'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                    <Box component={'img'} src={OMG_LOGO} sx={{ width: '80%', height: '80%' }} />
                </Box>
                <Typography component="h1" variant="h5" textAlign={'center'}>
                    เข้าสู่ระบบ
                </Typography>
                <LoginForm onSubmit={handleLoginFormSubmit} hasError={props.hasError} hasSuccess={props.hasSuccess} />
            </div>
        </Container>
    );
};

export default LoginView;