import { FC, useState } from "react";
import LoginView from "../Views/LoginView";
import loginService from "../Services/LoginService";
import { useNavigate } from "react-router-dom";

interface LoginControllerProps {

}
const LoginController: FC<LoginControllerProps> = (props) => {
    const navigate = useNavigate();
    const [hasError, setHasError] = useState(false);
    const [hasSuccess, setHasSuccess] = useState(false);
    const onSubmit = (username: string, password: string, isRemember: boolean) => {
        //please write login
        setTimeout(async () => {
            if (username && password) {
                const user = {
                    username,
                    password,
                    isRemember
                };
                await loginService.login(user).then((response) => {
                    if (response.access_token) {
                        setHasSuccess(true);
                        setHasError(false);
                        // ทำการ redirect หน้าไปยังหน้า Dashboard
                        setTimeout(() => {
                            navigate("/dashboard");
                        }, 1000);
                    } else {
                        setHasSuccess(false);
                        setHasError(true);
                    }
                }
                ).catch((error) => {
                    setHasSuccess(false);
                    setHasError(true);
                });
            } else {
                setHasSuccess(false);
                setHasError(true);
            }
        }, 1000);
    };

    return <LoginView
        onSubmit={(username, password, isRemember) => { onSubmit(username, password, isRemember); }}
        hasError={hasError}
        hasSuccess={hasSuccess} />;
};

export default LoginController;
