import { FC, useEffect, useState } from "react";
import QuestionsGroupListView from "../../Views/Question-group/QuestionsGroupListView";
import QuestionsGroupService, {
  IQuestionsGroupCriteria,
  IQuestionsGroupView,
} from "../../Services/QuestionsGroupService";

import { enqueueSnackbar } from "notistack";

interface QuestionsGroupListControllerProps {
  // Define your view props here
}

const QuestionsGroupListController: FC<QuestionsGroupListControllerProps> = (
  props
) => {
  const [questionsGroup, setQuestionsGroup] = useState<IQuestionsGroupView>();

  useEffect(() => {
    const criteria: IQuestionsGroupCriteria = {
      page: 0,
      limit: 10,
      name: "",
    };
    searchByCriteria(criteria);
  }, []);

  const searchByCriteria = async (criteria: IQuestionsGroupCriteria) => {
    try {
      const datas = await QuestionsGroupService.getByCriteria(criteria);
      if (datas) {
        setQuestionsGroup(datas as IQuestionsGroupView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  return (
    <QuestionsGroupListView
      questionsGroup={questionsGroup ?? ({} as IQuestionsGroupView)}
      onSearch={(criteria: IQuestionsGroupCriteria) => {
        searchByCriteria(criteria);
      }}
    />
  );
};

export default QuestionsGroupListController;
