import { Box, Breadcrumbs, Button, Card, CardContent, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import { IsystemUserView, ISystemUserCriteria } from "../Services/SystemUsersService";
import { IRole } from "../Services/RolesService";

interface SystemUsersListViewProps {
    systemUsers: IsystemUserView;
    searchByCiteria: (criteria: any) => void;
    roles: IRole[];
}

const SystemUsersListView: FC<SystemUsersListViewProps> = (props) => {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [role, setRole] = useState(0);

    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);


    const searchByCiteria = (pageNumber: number = 0, rowLimit?: number) => {
        setPage(pageNumber === 0 ? 0 : pageNumber);
        setLimit(rowLimit ? rowLimit : 10);
        const criteria: ISystemUserCriteria = { name: name, email: email, username: username, role: role, page: pageNumber, limit: limit ?? 10 };
        props.searchByCiteria(criteria);
    };

    return (
        <Box component={'div'}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                <Typography>การจัดการ</Typography>
                <Typography variant="h6" color="text.primary">ผู้ใช้ระบบ</Typography>
            </Breadcrumbs>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField fullWidth label="ชื่อ-นามสกุล" onChange={(e) => {
                                        setName(e.target.value);
                                    }} />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField fullWidth label="อีเมล" onChange={(e) => {
                                        setEmail(e.target.value);
                                    }} />
                                </Grid>

                                <Grid item xs={2}>
                                    <TextField fullWidth label="ชื่อผู้ใช้" onChange={(e) => {
                                        setUsername(e.target.value);
                                    }} />

                                </Grid>

                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="userGroup-select-label">ตำแหน่ง</InputLabel>
                                        <Select
                                            labelId="userGroup-select-label"
                                            id="userGroup-select"
                                            value={role}
                                            label="ตำแหน่ง"
                                            onChange={(e: any) => {
                                                const roleName = e.target.value;
                                                setRole(roleName);
                                            }}
                                        >
                                            <MenuItem value={0}>
                                                ทั้งหมด
                                            </MenuItem>
                                            {props.roles && props.roles.length > 0 ? props.roles.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            )) : []}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} textAlign={'center'} alignSelf={'center'}>
                                    <Button variant="contained" onClick={() => searchByCiteria(0)} startIcon={<SearchIcon />}>ค้นหา</Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container >
                                        <Grid item xs={10}>
                                            <Typography>ทั้งหมด {props.systemUsers.total} รายการ</Typography>
                                        </Grid>
                                        <Grid item xs={2} textAlign={'end'}>
                                            <Button variant="contained" startIcon={<NoteAddIcon />} onClick={() => navigate('/system-user-create')}>สร้าง</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">ชื่อ-นามสกุล</TableCell>
                                                    <TableCell align="center">ชื่อผู้ใช้</TableCell>
                                                    <TableCell align="center">อีเมล</TableCell>
                                                    <TableCell align="center">ตำแหน่ง</TableCell>
                                                    <TableCell align="center">บันทึก</TableCell>
                                                    <TableCell align="center">แก้ไข</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.systemUsers && props.systemUsers.datas && props.systemUsers.datas.length > 0 ? props.systemUsers.datas.map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell>{`${row.firstName ?? ''} ${row.lastName ?? ''}`}</TableCell>
                                                        <TableCell width={200}>
                                                            {row.username}
                                                        </TableCell>
                                                        <TableCell>{row.email}</TableCell>
                                                        <TableCell align="center" width={250}>{
                                                            row.role ? row.role.name : ''
                                                        }</TableCell>
                                                        <TableCell>
                                                            <Typography fontWeight={'bold'}>{row.createBy ? row.createBy.firstName : '-'}</Typography>
                                                            <Typography>
                                                                {row.createAt ? dayjs(row.createAt).format('DD/MM/YYYY HH:mm') : '-'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography fontWeight={'bold'}>{row.updateBy ? row.updateBy.firstName : '-'}</Typography>
                                                            <Typography>
                                                                {row.updateAt ? dayjs(row.updateAt).format('DD/MM/YYYY HH:mm') : '-'}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <IconButton onClick={() => navigate(`/system-user-edit/${row.id}`)}><KeyboardArrowRightIcon /></IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                )) : <TableRow>
                                                    <TableCell colSpan={7} align="center">ไม่พบข้อมูล</TableCell>
                                                </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box mt={3}>
                                        <TablePagination
                                            component="div"
                                            rowsPerPageOptions={[10, 25, 50, 100, { label: 'All', value: -1 }]}
                                            count={props.systemUsers.total ? props.systemUsers.total : 0}
                                            onPageChange={(e, newPage) => {
                                                setPage(newPage);
                                                searchByCiteria(newPage);
                                            }}
                                            page={page}
                                            rowsPerPage={limit ? limit : 10}
                                            onRowsPerPageChange={(e: any) => {
                                                setLimit(e.target.value);
                                                setPage(0);
                                                searchByCiteria(0);
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box >

    );
};

export default SystemUsersListView;