import { enqueueSnackbar } from 'notistack';
import apiService from '../services/ApiService';

export default function checkPermission(
    user: any,
    menu: string,
    crud?: string
): boolean {

    if (!crud) {
        return user.some((role: any) => role.menu.name === menu);
    } else {
        return user.some((role: any) => role.menu.name === menu).some((role: any) => role.crud);
    }
}


export function checkValidation(value: any, message: string): any {
    if (!value) {
        enqueueSnackbar(message, { variant: 'error' });
        return false;
    } else {
        return true;
    }
}

export function checkValidationDate(start: any,end:any) {
    if (start > end) {
        enqueueSnackbar('วันที่สิ้นสุดต้องไม่น้อยกว่าวันที่เริ่มต้น', { variant: 'error' });
        return false;
    } else{
        return true;
    }
}

export function handleOnKeyDown(e:any){
    if (e.key !== "0" && e.key !== "1" && e.key !== "2" && e.key !== "3" && e.key !== "4" && e.key !== "5" && e.key !== "6" && e.key !== "7" && e.key !== "8" && e.key !== "9" && e.key !== "Backspace" && e.key !== "." && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
        e.preventDefault();
      }
}

//#region services with backend
export async function deleteFileImage(name: string) {
    try{
        const data = { filename:name };
          await apiService.post('/images/delete', data).then((res) => {
            if (res) {
              return res;
            }
          });
    } catch (error) {
        throw error;
    }
}

export async function deletePreviousImage(id: string) {
    try {
        const response = await apiService.delete(`/images/${id}`);
        return response;
    } catch (error) {
        return error;
    }
} 
//#endregion


