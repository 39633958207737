import { makeAutoObservable } from "mobx";
import { IUser } from "../../Settings/Services/SystemUsersService";
import apiService from "../../../services/ApiService";

export interface INotificationTypes {
    id?: number;
    name?: string;
    isDelete?: boolean;
    createBy?: string;
    updateBy?: string;
    createAt?: Date;
    updateAt?: Date;
}

export interface INotificationTypesViewData {
    id?: number;
    name?: string;
    isDelete?: boolean;
    createBy?: IUser;
    updateBy?: IUser;
    createAt?: Date;
    updateAt?: Date;
}

export interface INotificationTypesCriteria {
    name: string;
    page: number;
    limit: number;
}

export interface INotificationTypesView {
    datas: INotificationTypesViewData[];
    all: number;
    limit: number;
    page: number;
    total: number;
}


class NotificationTypesService {
    notificationTypes: INotificationTypesView = { datas: [], all: 0, limit: 0, page: 0, total: 0 };
    constructor() {
        makeAutoObservable(this);
    }

    async getAll() {
        try {
            const response = await apiService.get("/notifications/notifications-type/all");
            this.notificationTypes = response;
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getOne(id: string) {
        try {
            const response = await apiService.get(`/notifications/notifications-type/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    }


    async getByCriteria(criteria: INotificationTypesCriteria) {
        try {
            const response = await apiService.post("/notifications/notifications-type/criteria", criteria);
            this.notificationTypes = {
                datas: response.data.filter((x: INotificationTypes) => !x.isDelete),
                all: response.length,
                limit: response.limit,
                page: response.page,
                total: response.total
            };
            return this.notificationTypes;
        } catch (error) {
            throw error;
        }
    }

    async create(data: INotificationTypes) {
        try {
            const response = await apiService.post(`/notifications/notifications-type`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async update(id: string, data: INotificationTypes) {
        try {
            const response = await apiService.put(`/notifications/notifications-type/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async deleteNotificationType(id: string) {
        try {
            const data = { isDelete: true };
            const response = await apiService.put(`/notifications/notifications-type/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }



}


const notificationTypesService = new NotificationTypesService();
export default notificationTypesService;