import {
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  Typography,
  Link,
  Card,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Divider,
  Stack,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  ButtonProps,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  IPromotion,
  IPromotionType,
  IPromotionViewData,
} from "../Services/PromotionsService";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  checkValidation,
  checkValidationDate,
  handleOnKeyDown,
} from "../../../utils";
import DialogConfirm from "../../../components/DialogConfirm";
import { enqueueSnackbar } from "notistack";
import loginService from "../../Login/Services/LoginService";

interface PromotionCreateViewProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการแสดง UI
  getOne: (id: string) => void;
  onCreate: (data: IPromotion) => void;
  onUpdate: (id: string, data: IPromotion) => void;
  onDelete: (id: string) => void;
  data: IPromotionViewData;
  promotionId: string;
  types: IPromotionType[];
}

const PromotionCreateView: FC<PromotionCreateViewProps> = (props) => {
  const user = loginService.user;
  const [promotionName, setPromotionName] = useState("");
  const [type, setType] = useState("select-type");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("0");
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs());
  const [isActive, setIsActive] = useState(true);
  const [path, setPath] = useState("");
  const [duration, setDuration] = useState(0);

  const navigate = useNavigate();

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  const handleChangeTextFieldValue = (value: string, state: string) => {
    if (state === "price") {
      if (value.substring(0, 1) === "0") {
        setPrice(value.substring(1));
      } else {
        setPrice(value);
      }
    } else if (state === "duration") {
      setDuration(value === "" ? 0 : parseInt(value));
    }
  };

  useEffect(() => {
    if (props.data) {
      setPromotionName(props.data.name ?? "");
      setDescription(props.data.description ?? "");
      if (props.data.type) {
        setType(props.data?.type.id ?? "select-type");
      }
      setPrice(props.data?.price ? props.data?.price.toString() : "0");
      setDuration(props.data.duration ?? 0);
      setPath(props.data.path ?? "");
      setStartDate(dayjs(props.data.startDate));
      setEndDate(dayjs(props.data.endDate));
      setIsActive(props.data.isActive ?? true);
    }
  }, [props.data]);

  const handleSave = () => {
    if (checkValidate()) {
      props.onCreate({
        name: promotionName,
        description: description,
        type: type,
        price:  price === "" || price === "." ? 0 : parseFloat(price),
        duration: duration,
        path: path,
        startDate: startDate?.toDate(),
        endDate: endDate?.toDate(),
        isActive: isActive,
        createAt: new Date(),
        createBy: user?.id,
      });
    }
  };

  const handleEdit = () => {
    if (checkValidate()) {
      props.onUpdate(props.promotionId ?? "", {
        name: promotionName,
        description: description,
        type: type,
        price:  price === "" || price === "." ? 0 : parseFloat(price),
        duration: duration,
        path: path,
        startDate: startDate?.toDate(),
        endDate: endDate?.toDate(),
        isActive: isActive,
        updateAt: new Date(),
        updateBy: user?.id,
      });
    }
  };

  const handleDelete = () => {
    props.onDelete(props.promotionId ?? "");
  };

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      checkValidationDate(date, endDate);
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      checkValidationDate(startDate, date);
      setEndDate(date);
    }
  };

  const checkValidate = () => {
    if (!checkValidation(promotionName, "กรุณากรอกชื่อโปรโมชั่น")) return false;
    if (!checkValidation(description, "กรุณากรอกรายละเอียดโปรโมชั่น"))
      return false;
    if (type === "select-type") {
      enqueueSnackbar("กรุณาระบุประเภท", { variant: "error" });
      return false;
    }
    if (!checkValidation(type, "กรุณาระบุประเภท")) return false;
    /*  if (!checkValidation(price, "กรุณาใส่ราคา")) return false; */
    if (!checkValidation(duration, "กรุณาใส่จำนวนการใช้งาน")) return false;
    if (!checkValidation(path, "กรุณาใส่ path")) return false;
    if (startDate > endDate) {
      enqueueSnackbar("วันที่สิ้นสุดต้องไม่น้อยกว่าวันที่เริ่มต้น", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  return (
    <Box component="div">
      <Grid container spacing={2} mt={1}>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/promotions-list")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>โปรโมชั่น</Typography>
            <Link
              component="button"
              onClick={() => navigate("/promotions-list")}
            >
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.promotionId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"end"}>
          {props.promotionId && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setOpenConfirm({
                  open: true,
                  title: "ลบข้อมูล",
                  message: "คุณต้องการลบโปรโมชั่นนี้ใช่หรือไม่",
                  color: "error",
                  type: "delete",
                });
              }}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="ชื่อโปรโมชั่น"
                    value={promotionName}
                    onChange={(e) => setPromotionName(e.target.value)}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="รายละเอียดโปรโมชั่น"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="promotionTypeGroup-select-label">
                      ประเภท
                    </InputLabel>
                    <Select
                      labelId="promotionType-select-label"
                      value={type}
                      id="promotionType-select"
                      label="ประเภท"
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                    >
                      <MenuItem value="select-type">กรุณาเลือกประเภท</MenuItem>
                      {props.types && props.types.length > 0 ? (
                        props.types.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>ไม่พบข้อมูล</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="path"
                    value={path}
                    onChange={(e) => setPath(e.target.value)}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="ราคา"
                    value={price}
                    type="text"
                    onChange={(e) => {
                      handleChangeTextFieldValue(e.target.value, "price");
                    }}
                    onKeyDown={handleOnKeyDown}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="จำนวนการใช้งาน"
                    value={duration}
                    type="text"
                    onChange={(e) => {
                      handleChangeTextFieldValue(e.target.value, "duration");
                    }}
                  />
                </Grid>

                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="ตั้งแต่วันที่"
                      sx={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="ถึงวันที่"
                      sx={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      value={endDate}
                      onChange={handleEndDateChange}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={8}>
                  {
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isActive}
                            onChange={(e) => setIsActive(e.target.checked)}
                          />
                        }
                        label="ใช้งาน"
                      />
                    </FormGroup>
                  }
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Stack spacing={2} direction="row">
                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={() => navigate("/promotions-list")}
                        >
                          ยกเลิก
                        </Button>
                        {
                          <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              if (props.promotionId) {
                                setOpenConfirm({
                                  open: true,
                                  title: "แก้ไขโปรโมชั่น",
                                  message:
                                    "คุณต้องการแก้ไขโปรโมชั่นนี้ใช่หรือไม่",
                                  color: "primary",
                                  type: "edit",
                                });
                              } else {
                                setOpenConfirm({
                                  open: true,
                                  title: "บันทึกโปรโมชั่น",
                                  message:
                                    "คุณต้องการบันทึกโปรโมชั่นนี้ใช่หรือไม่",
                                  color: "primary",
                                  type: "create",
                                });
                              }
                            }}
                          >
                            บันทึก
                          </Button>
                        }
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            handleDelete();
          } else if (openConfirm.type === "create") {
            handleSave();
          } else if (openConfirm.type === "edit") {
            handleEdit();
          }
        }}
      />
    </Box>
  );
};

export default PromotionCreateView;
