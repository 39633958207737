import React, { useEffect, useState } from "react";
import SystemUsersListView from "../Views/SystemUsersListView";
import {
  IsystemUserView,
  ISystemUserCriteria,
} from "../Services/SystemUsersService";
import SystemUsersService from "../Services/SystemUsersService";
import RoleService from "../Services/RolesService";
import { enqueueSnackbar } from "notistack";
import { IRole } from "../Services/RolesService";

interface SystemUsersListControllerProps {
  // Define your view props here
}

const SystemUsersListController: React.FC<SystemUsersListControllerProps> = (
  props
) => {
  const [systemUsers, setSystemUsers] = useState<IsystemUserView>();
  const [roles, setRoles] = useState<IRole[]>();

  useEffect(() => {
    const criteria = {
      name: "",
      email: "",
      username: "",
      role: 0,
      page: 0,
      limit: 10,
    };
    searchByCiteria(criteria);
    getRoles();
  }, []);

  const searchByCiteria = async (criteria: ISystemUserCriteria) => {
    const datas = await SystemUsersService.getByCriteria(criteria);
    if (datas) {
      setSystemUsers(datas as IsystemUserView);
    } else {
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const getRoles = async () => {
    try {
      const datas = await RoleService.getAll();
      if (datas) {
        setRoles(datas as IRole[]);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  return (
    <SystemUsersListView
      systemUsers={systemUsers ?? ({} as IsystemUserView)}
      searchByCiteria={(criteria: ISystemUserCriteria) => {
        searchByCiteria(criteria);
      }}
      roles={roles ?? ([] as IRole[])}
    />
  );
};

export default SystemUsersListController;
