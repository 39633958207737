import { FC, useEffect, useState } from "react";
import QuestionsTypeListView from "../../Views/Question-type/QuestionsTypeListView";
import QuestionsTypeService, {
  IQuestionTypeCriteria,
  IQuestionTypeView,
} from "../../Services/QuestionTypesService";
import { enqueueSnackbar } from "notistack";

interface QuestionsTypeListControllerProps {}

const QuestionsTypeListController: FC<QuestionsTypeListControllerProps> = (
  props
) => {
  const [questionsType, setQuestionsType] = useState<IQuestionTypeView>();

  useEffect(() => {
    const criteria: IQuestionTypeCriteria = {
      page: 0,
      limit: 10,
      name: "",
    };
    searchByCriteria(criteria);
  }, []);
  const searchByCriteria = async (criteria: IQuestionTypeCriteria) => {
    try {
      const datas = await QuestionsTypeService.getByCriteria(criteria);
      if (datas) {
        setQuestionsType(datas as IQuestionTypeView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  return (
    <QuestionsTypeListView
      questionsType={questionsType ?? ({} as IQuestionTypeView)}
      onSearch={(criteria: IQuestionTypeCriteria) => searchByCriteria(criteria)}
    />
  );
};

export default QuestionsTypeListController;
