import React, { FC, useState } from 'react';
import { Box, Grid, Typography, Paper, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar } from 'recharts';
import { IDashboardData } from '../Services/DashboardService';

interface DashboardViewProps {
    dashboardData: IDashboardData | null;
    onFetchDashboardData: (month: number | 'all', year: number) => void;
}

const DashboardView: FC<DashboardViewProps> = (props) => {
    const [selectedMonth, setSelectedMonth] = useState<number | 'all'>((new Date()).getMonth() + 1); 
    const [selectedYear, setSelectedYear] = useState<number>((new Date()).getFullYear()); 
    const years = Array.from(new Array(11), (_, index) => 2024 + index);

    const handleMonthChange = (event: SelectChangeEvent<number | 'all'>) => {
        const month = event.target.value === 'all' ? 'all' : event.target.value as number;
        setSelectedMonth(month);
        props.onFetchDashboardData(month, selectedYear);
    };

    const handleYearChange = (event: SelectChangeEvent<number>) => {
        const year = event.target.value as number;
        setSelectedYear(year);
        props.onFetchDashboardData(selectedMonth, year);
    };

    if (!props.dashboardData) return null;

    const { userData, blessingData, revenueOvertimeData, revenueOverviewData } = props.dashboardData;

    return (
        <Box sx={{ padding: '20px' }}>
            <Grid container spacing={2}>
                {/* Dropdown for Month and Year */}
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                        <FormControl sx={{ minWidth: 120, marginRight: '10px' }}>
                            <InputLabel id="select-month-label">เดือน</InputLabel>
                            <Select
                                labelId="select-month-label"
                                id="select-month"
                                label="เดือน"
                                value={selectedMonth}
                                onChange={handleMonthChange}
                            >
                                <MenuItem value="all">ทั้งหมด</MenuItem>
                                {Array.from({ length: 12 }, (_, index) => index + 1).map(month => (
                                    <MenuItem key={month} value={month}>{month}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="select-year-label">ปี</InputLabel>
                            <Select
                                labelId="select-year-label"
                                id="select-year"
                                label="ปี"
                                value={selectedYear}
                                onChange={handleYearChange}
                            >
                                {years.map(year => (
                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>

                {/* Overview */}
                <Grid item xs={12} md={4}>
                    <Paper sx={{ padding: '20px', textAlign: 'center' }}>
                        <Typography variant="h6">Total Users</Typography>
                        <Typography variant="h4">{userData.totalUser}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ padding: '20px', textAlign: 'center' }}>
                        <Typography variant="h6">New Users</Typography>
                        <Typography variant="h4">{userData.newUser}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ padding: '20px', textAlign: 'center' }}>
                        <Typography variant="h6">Total Revenue</Typography>
                        <Typography variant="h4">{revenueOvertimeData.totalRevenue}</Typography>
                    </Paper>
                </Grid>
                {/* Revenue OverTime */}
                <Grid item xs={12}>
                    <Paper sx={{ padding: '20px', textAlign: 'center' }}>
                        <Typography variant="h6" sx={{ marginBottom: '20px' }}>Revenue Over Time</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <LineChart width={900} height={400} data={revenueOvertimeData.revenueOverTime}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip />
                                <Legend align='center' />
                                <Line type="monotone" dataKey="revenue" stroke="#FF8042" strokeWidth={3} activeDot={{ r: 8 }} />
                            </LineChart>
                        </Box>
                    </Paper>
                </Grid>

                {/* Revenue Overview */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ padding: '20px', textAlign: 'center' }}>
                        <Typography variant="h6" sx={{ marginBottom: '20px' }}>Revenue Overview</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <BarChart width={700} height={400} data={revenueOverviewData.revenueOrderByMonth}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip />
                                <Legend align='center'/>
                                <Bar dataKey="users" fill="#8884d8" />
                                <Bar dataKey="subscriptions" fill="#15c590" />
                                <Bar dataKey="credits" fill="#ec8625" />
                            </BarChart>
                        </Box>
                    </Paper>
                </Grid>

                {/* User Insights */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ padding: '20px', textAlign: 'center' }}>
                        <Typography variant="h6" sx={{ marginBottom: '20px' }}>User Growth</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <LineChart width={700} height={400} data={userData.userGrowth}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip />
                                <Legend align='center'/>
                                <Line type="monotone" dataKey="users" stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </Box>
                    </Paper>
                </Grid>

                {/* Blessing Insights */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ padding: '20px', textAlign: 'center' }}>
                        <Typography variant="h6" sx={{ marginBottom: '20px' }}>Popular Blessing Types</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <BarChart width={700} height={400} data={blessingData.blessingType}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend align='center'/>
                                <Bar dataKey="qty" fill="#8884d8" />
                            </BarChart>
                        </Box>
                    </Paper>
                </Grid>

                {/* Blessings by Time */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ padding: '20px', textAlign: 'center' }}>
                        <Typography variant="h6" sx={{ marginBottom: '20px' }}>Blessings by Time</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <BarChart width={700} height={400} data={blessingData.blessingByTime}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="time" />
                                <YAxis />
                                <Tooltip />
                                <Legend align='center'/>
                                <Bar dataKey="qty" fill="#8884d8" />
                            </BarChart>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DashboardView;
