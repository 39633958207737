import { makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";


export interface IRole {
    id: number;
    name: string;
    isActive: boolean;
}

class RolesService {
    roles: IRole[] = [];
    constructor() {

        makeAutoObservable(this);
    }

    async getAll() {
        try {
            if (this.roles && this.roles.length > 0) {
                return this.roles;
            } else {
                const response = await apiService.get("/roles");
                this.roles = response;
                return response;
            }
        } catch (error) {
            throw error;
        }
    }

    // TODO : uncomment when handle task Roles
    // async getOne(id: number) {
    //     try {
    //         const response = await apiService.get(`/roles/${id}`);
    //         return response;
    //     } catch (error) {
    //         return error;
    //     }
    // }

    // TODO : uncomment when handle task Roles
    // async create(data: IRole) {
    //     try {
    //         const response = await apiService.post("/roles", data);
    //         return response;
    //     } catch (error) {
    //         return error;
    //     }
    // }


    // TODO : uncomment when handle task Roles
    // async update(data: IRole) {
    //     try {
    //         const response = await apiService.put(`/roles/${data.id}`, data);
    //         return response;
    //     } catch (error) {
    //         return error;
    //     }
    // }


    // TODO : uncomment when handle task Roles
    // async delete(id: number) {
    //     try {
    //         const response = await apiService.delete(`/roles/${id}`);
    //         return response;
    //     } catch (error) {
    //         return error;
    //     }
    // }
}

const rolesService = new RolesService();
export default rolesService;