import React, { useEffect } from "react";
import "./App.css";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Layout from "./layout";
import DashboardController from "./features/Dashboard/Controllers/DashboardController";
import LoginController from "./features/Login/Controllers/LoginController";
import GodsListController from "./features/Gods/Controllers/GodsListController";
import GodCreateController from "./features/Gods/Controllers/GodCreateController";
import ArticlesListController from "./features/Articles/Controllers/ArticlesListController";
import ArticleCreateController from "./features/Articles/Controllers/ArticleCreateController";
import CustomersListController from "./features/Customers/Controllers/UserCustomersListController";
import CustomersHistoryController from "./features/Customers/Controllers/UserCustomersHistoryControllers";
import SystemUsersListController from "./features/Settings/Controllers/SystemUsersController";
import SystemUserCreateController from "./features/Settings/Controllers/SystemUserCreateController";
import NotificationsListController from "./features/Notifications/Controllers/NotificationsListController";
import NotificationCreateController from "./features/Notifications/Controllers/NotificationCreateController";
import PackagesListController from "./features/Packages/Controllers/PackagesListController";
import PackageCreateController from "./features/Packages/Controllers/PackageCreateController";
import { observer } from "mobx-react-lite";
import loginService from "./features/Login/Services/LoginService";
import UserPaymentsListController from "./features/Customers/Controllers/UserPaymentsListController";
import UserPaymentsHistoryController from "./features/Customers/Controllers/UserPaymentsHistoryController";
import PromotionsListController from "./features/Promotions/Controllers/PromotionsListController";
import PromotionCreateController from "./features/Promotions/Controllers/PromotionCreateController";
import FormulasListController from "./features/Formulas/Controllers/FormulasListController";
import QuestionsListController from "./features/Questions/Controllers/Question/QuestionsListController";
import QuestionCreateController from "./features/Questions/Controllers/Question/QuestionCreateController";
import QuestionsGroupListController from "./features/Questions/Controllers/Question-group/QuestionsGroupListController";
import QuestionGroupCreateController from "./features/Questions/Controllers/Question-group/QuestionGroupCreateController";
import QuestionsTypeListController from "./features/Questions/Controllers/Question-type/QuestionsTypeListController";
import QuestionTypeCreateController from "./features/Questions/Controllers/Question-type/QuestionTypeCreateController";
import NotificationTypesListController from "./features/Notifications/Controllers/notificationsType/NotificationTypesListController";
import NotificationTypeCreateController from "./features/Notifications/Controllers/notificationsType/NotificationTypeCreateController";

const App: React.FC = observer(() => {
  const user = localStorage.getItem("user");
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      const currentUser = JSON.parse(user);
      if (currentUser.access_token) {
        loginService
          .checkSessionExpired(currentUser.access_token)
          .then((response) => {
            if (response) {
              loginService.setUser(currentUser);
            } else {
              alert("เซสชั่นหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง");
              loginService.logout();
              window.location.href = "/login";
            }
          })
          .catch(() => {
            loginService.logout();
          });
      }
      // loginService.setUser(JSON.parse(user));
    }
  }, [user]);
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            user ? (
              <Layout>
                <DashboardController />
              </Layout>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            <Layout>
              <DashboardController />
            </Layout>
          }
        />
        <Route path="/login" element={<LoginController />} />

        {/* gods */}
        <Route
          path="/gods-list"
          element={
            <Layout>
              <GodsListController />
            </Layout>
          }
        />
        <Route
          path="/god-create"
          element={
            <Layout>
              <GodCreateController />
            </Layout>
          }
        />
        <Route
          path="/god-edit/:id"
          element={
            <Layout>
              <GodCreateController />
            </Layout>
          }
        />

        {/* Formula */}
        <Route
          path="/formulas-list"
          element={
            <Layout>
              <FormulasListController />
            </Layout>
          }
        />

        {/* notifications */}
        <Route
          path="/notifications-list"
          element={
            <Layout>
              <NotificationsListController />
            </Layout>
          }
        />
        <Route
          path="/notification-create"
          element={
            <Layout>
              <NotificationCreateController />
            </Layout>
          }
        />
        <Route
          path="/notification-edit/:id"
          element={
            <Layout>
              <NotificationCreateController />
            </Layout>
          }
        />

        {/* Articles */}
        <Route
          path="/articles-list"
          element={
            <Layout>
              <ArticlesListController />
            </Layout>
          }
        />
        <Route
          path="/article-create"
          element={
            <Layout>
              <ArticleCreateController />
            </Layout>
          }
        />
        <Route
          path="/article-edit/:id"
          element={
            <Layout>
              <ArticleCreateController />
            </Layout>
          }
        />

        {/* Packages */}
        <Route
          path="/packages-list"
          element={
            <Layout>
              <PackagesListController />
            </Layout>
          }
        />
        <Route
          path="/package-create"
          element={
            <Layout>
              <PackageCreateController />
            </Layout>
          }
        />
        <Route
          path="/package-edit/:id"
          element={
            <Layout>
              <PackageCreateController />
            </Layout>
          }
        />

        {/* Promotions */}
        <Route
          path="/promotions-list"
          element={
            <Layout>
              <PromotionsListController />
            </Layout>
          }
        />
        <Route
          path="/promotion-create"
          element={
            <Layout>
              <PromotionCreateController />
            </Layout>
          }
        />
        <Route
          path="/promotion-edit/:id"
          element={
            <Layout>
              <PromotionCreateController />
            </Layout>
          }
        />
        {/* Customers */}
        <Route
          path="/users-customers-list"
          element={
            <Layout>
              <CustomersListController />
            </Layout>
          }
        />
        <Route
          path="/user-customers-history/:id"
          element={
            <Layout>
              <CustomersHistoryController />
            </Layout>
          }
        />

        {/* Questions */}
        <Route
          path="/questions-list"
          element={
            <Layout>
              <QuestionsListController />
            </Layout>
          }
        />
        <Route
          path="/question-create"
          element={
            <Layout>
              <QuestionCreateController />
            </Layout>
          }
        />
        <Route
          path="/question-edit/:id"
          element={
            <Layout>
              <QuestionCreateController />
            </Layout>
          }
        />

        <Route
          path="/questions-group-list"
          element={
            <Layout>
              <QuestionsGroupListController />
            </Layout>
          }
        />
        <Route
          path="/question-group-create"
          element={
            <Layout>
              <QuestionGroupCreateController />
            </Layout>
          }
        />
        <Route
          path="/question-group-edit/:id"
          element={
            <Layout>
              <QuestionGroupCreateController />
            </Layout>
          }
        />
        <Route
          path="/questions-type-list"
          element={
            <Layout>
              <QuestionsTypeListController />
            </Layout>
          }
        />
        <Route
          path="/question-type-create"
          element={
            <Layout>
              <QuestionTypeCreateController />
            </Layout>
          }
        />
        <Route
          path="/question-type-edit/:id"
          element={
            <Layout>
              <QuestionTypeCreateController />
            </Layout>
          }
        />

        {/* User Payments */}
        <Route
          path="/payments-list"
          element={
            <Layout>
              <UserPaymentsListController />
            </Layout>
          }
        />
        <Route
          path="payments-history/:id"
          element={
            <Layout>
              <UserPaymentsHistoryController />
            </Layout>
          }
        />

        {/* Settings */}
        <Route
          path="/system-users-list"
          element={
            <Layout>
              <SystemUsersListController />
            </Layout>
          }
        />
        <Route
          path="/system-user-create"
          element={
            <Layout>
              <SystemUserCreateController />
            </Layout>
          }
        />
        <Route
          path="/system-user-edit/:id"
          element={
            <Layout>
              <SystemUserCreateController />
            </Layout>
          }
        />

        {/* Notification Type */}
        <Route
          path="/notification-types-list"
          element={
            <Layout>
              <NotificationTypesListController />
            </Layout>
          }
        />
        <Route
          path="/notification-type-create"
          element={
            <Layout>
              <NotificationTypeCreateController />
            </Layout>
          }
        />
        <Route
          path="/notification-type-edit/:id"
          element={
            <Layout>
              <NotificationTypeCreateController />
            </Layout>
          }
        />

        {/* Settings Profile */}
        <Route
          path="/profile/:id"
          element={
            <Layout>
              <SystemUserCreateController />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
});

export default App;
