import React, { useEffect, useState } from "react";
import ArticleCreateView from "../Views/ArticleCreateView";
import { IArticle, IUploadFile } from "../Services/ArticlesService";
import ArticlesService from "../Services/ArticlesService";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { deleteFileImage, deletePreviousImage } from "../../../utils";

interface ArticleCreateControllerProps {
  // Add any props you need for the controller
}

const ArticleCreateController: React.FC<ArticleCreateControllerProps> = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState<IArticle>();
  const [previousImage, setPreviousImage] = useState<any>();

  useEffect(() => {
    if (id) {
      getOne(id);
    }
  }, [id]);

  const create = async (data: IArticle, uploadFile: IUploadFile) => {
    await ArticlesService.create(data, uploadFile)
      .then((res: any) => {
        if (res.createdImage) {
          const articleId = res.createdImage.refId;
          navigate(`/article-edit/${articleId}`);
          getOne(articleId);
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const update = async (
    id: string,
    data: IArticle,
    uploadFile: IUploadFile
  ) => {
    let response = "";
    const newUploadFile = uploadFile !== previousImage;
    await ArticlesService.update(id, data)
      .then(async (res) => {
        if (res) {
          response = res;
          if (newUploadFile) {
            if (previousImage && uploadFile) {
              const deleteResponse = await deletePreviousImage(
                previousImage.id
              );
              await deleteFileImage(previousImage.name);
              if (deleteResponse) {
                response = deleteResponse;
              }
            }
            if (response && uploadFile) {
              response = await ArticlesService.uploadImage(id, {
                file: uploadFile,
              });
              getOne(id);
            }
          }
          if (response) {
            enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
          } else {
            enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
          }
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const getOne = async (id: string) => {
    try {
      const res = await ArticlesService.getOne(id);
      if (res) {
        setData(res);
        setPreviousImage(res.image);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const deleteArticle = async (id: string) => {
    try {
      await ArticlesService.deleteArticle(id).then((res) => {
        if (res) {
          enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
          navigate("/articles-list");
        } else {
          enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
    }
  };

  return (
    <ArticleCreateView
      getOne={getOne}
      onCreate={create}
      onUpdate={update}
      onDelete={deleteArticle}
      data={data ?? ({} as IArticle)}
      articleId={id ?? ""}
      previousImage={previousImage}
    />
  );
};

export default ArticleCreateController;
