import { makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IUser } from "../../Settings/Services/SystemUsersService";

export interface IQuestionsGroup {
    id?: string;
    name: string;
    description: string;
    isDelete?: boolean;
    createBy?: string;
    updateBy?: string;
    createAt?: Date;
    updateAt?: Date;
    
}

export interface IQuestionsGroupViewData {
    id?: string;
    name: string;
    description:string;
    isDelete?: boolean;
    createBy?: IUser;
    updateBy?: IUser;
    createAt?: Date;
    updateAt?: Date;
}

export interface IQuestionsGroupCriteria {
    name:string;
    page:number;
    limit:number;
}

export interface IQuestionsGroupView{
    datas: IQuestionsGroupViewData[];
    all: number;
    page: number;
    limit: number;
    total: number;
}


class QuestionsGroupService {
    questionsGroup: IQuestionsGroupView = {} as IQuestionsGroupView;
    constructor() {
        makeAutoObservable(this);
    }

    async getAll() {
        try {
            const response = await apiService.get("/questions/questionGroup");
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getOne(id: string) {
        try {
            const response = await apiService.get(`/questions/questionGroup/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getByCriteria(criteria: IQuestionsGroupCriteria) {
        try {
            const response = await apiService.post("/questions/questionGroup/criteria", criteria);
            this.questionsGroup = {
                datas: response.data,
                all: response.length,
                limit: response.limit,
                page: response.page,
                total: response.total
            }
            return this.questionsGroup;
        } catch (error) {
            throw error;
        }
    }

    async create(data: IQuestionsGroup) {
        try{
            let response = '';
            await apiService.post("/questions/questionGroup", data).then(async (result) => {
                response = result
            })
            return response;
        } catch (error) {
            throw error;
        }
    }

    async update(id:string,data: IQuestionsGroup) {
        try {
            const response = await apiService.put(`/questions/questionGroup/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async delete(id: string) {
        try {
            const data = {isDelete:true}
            const response = await apiService.put(`/questions/questionGroup/delete/${id}`,data);
            return response;
        } catch (error) {
            return error;
        }
    }
}

const questionsGroupService = new QuestionsGroupService();
export default questionsGroupService;