import { makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IUser } from '../../Settings/Services/SystemUsersService';

export interface IQuestion {
    id?: string;
    name?: string;
    description?: string;
    questionGroup?: string;
    questionType?: string;
    isActive?: boolean;
    isDelete?: boolean;
    createAt?: Date;
    updateAt?: Date;
    createBy?: string;
    updateBy?: string;
    detail?: IQuestionDetail[];
}

export interface IQuestionsGroup {
    id: string;
    name: string;
    description: string;
    isDelete: boolean;
    createAt: Date;
    updateAt: Date;
    createBy: string;
    updateBy: string;
}

export interface IQuestionType {
    id: string;
    name: string;
    image: string;
    isDelete: boolean;
    createAt: Date;
    updateAt: Date;
    createBy: string;
    updateBy: string;
}


export interface IQuestionViewData {
    id?: string;
    name: string;
    description: string;
    questionGroup: IQuestionsGroup;
    questionType: IQuestionType;
    isActive?: boolean;
    isDelete?: boolean;
    createAt?: Date;
    updateAt?: Date;
    createBy?: IUser;
    updateBy?: IUser;
    detail?:IQuestionDetail[];
}

export interface IQuestionCriteria {
    name: string;
    questionGroup: string;
    questionType: string;
    isActive: boolean;
    page: number;
    limit: number;
}

export interface IQuestionView {
    datas: IQuestionViewData[];
    all: number;
    limit: number;
    page: number;
    total: number;
}

export interface IQuestionUpdateIsActiveChecked {
    isActive: boolean;
    updateAt: Date;
}

export interface IQuestionDetail{
    id?: string;
    name?: string;
    lang?: string;
    description?: string;
    createAt?: Date;
    updateAt?: Date;
    createBy?: string;
    updateBy?: string;
    isNew?: boolean;
}

class IQuestionsService {
    questions: IQuestionView = {} as IQuestionView;
    questionsGroup: IQuestionsGroup[] = [];
    questionsType: IQuestionType[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    async getAll() {
        try {
            const response = await apiService.get("/questions");
            if (response.length > 0) {
                this.questions = {
                    datas: response.filter((x: IQuestion) => !x.isDelete),
                    all: response.length,
                    limit: 10,
                    page: 1,
                    total: response.length
                };
            }
            return this.questions;
        } catch (error) {
            throw error;
        }
    }

    async getOne(id: string) {
        try {
            const response = await apiService.get(`/questions/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getByCriteria(criteria: IQuestionCriteria) {
        try {
            const response = await apiService.post("/questions/criteria", criteria);
            this.questions = {
                datas: response.data.filter((x: IQuestion) => !x.isDelete),
                all: response.length,
                limit: response.limit,
                page: response.page,
                total: response.total
            };
            return this.questions;
        } catch (error) {
            throw error;
        }
    }

    async create(data: IQuestion) {
        try {
            const response = await apiService.post(`/questions/`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async update(id: string, data: IQuestion) {
        try {
            const response = await apiService.put(`/questions/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async deleteQuestion(id: string) {
        try {
            const data = { isDelete: true };
            const response = await apiService.put(`/questions/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

   
    async updateIsActiveChecked(id: string, data: IQuestionUpdateIsActiveChecked) {
        try {
          const response = await apiService.put(`/questions/${id}`, data);
          return response;
        } catch (error) {
            throw error;
        }
      }



}


const questionsService = new IQuestionsService();
export default questionsService;