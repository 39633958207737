import { FC, useEffect, useState } from "react";
import NotificationTypesListView from "../../Views/notificationsType/NotificationTypesListView";
import { enqueueSnackbar } from "notistack";
import notificationTypesService, {
  INotificationTypesCriteria,
  INotificationTypesView,
} from "../../Services/NotificationTypesService";

interface NotificationTypesListControllerProps {}

const NotificationTypesListController: FC<NotificationTypesListControllerProps> = (
  props
) => {
  const [notificationTypes, setNotificationTypes] =
    useState<INotificationTypesView>();

  useEffect(() => {
    const citeria: INotificationTypesCriteria = {
      name: "",
      page: 0,
      limit: 10,
    };
    searchByCiteria(citeria);
  }, []);

  const searchByCiteria = async (criteria: INotificationTypesCriteria) => {
    try {
      const datas = await notificationTypesService.getByCriteria(criteria);
      if (datas) {
        setNotificationTypes(datas as INotificationTypesView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  return (
    <NotificationTypesListView
      notificationTypes={notificationTypes ?? ({} as INotificationTypesView)}
      onSearch={(criteria: INotificationTypesCriteria) => {
        searchByCiteria(criteria);
      }}
    />
  );
};

export default NotificationTypesListController;
