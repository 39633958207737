import apiService from "../../../services/ApiService";
import { IUserCustomer, IUserCustomerCriteria } from "../../Customers/Services/UserCustomersServices";
import { INotificationViewData,  } from "./NotificationsService";

export interface IUserNotification {
    user: IUserCustomer;
    notification?: INotificationViewData ;
    createAt?: Date;
    createBy?: string;
    updateAt?: Date;
    updateBy?: string;
}

export interface IUserNotificationCriteria {
    notification: string;
    name: string;
    page: number;
    limit: number;
    birthTimeStart?: string;
    birthTimeEnd?: string;
    dateFrom?: string;
    dateTo?: string;
    gender?: string;
    platform?: string;
    id?: string;
    dateType?: string;
}

export interface IUserNotificationView {
    datas: IUserCustomer[];
    all: number;
    limit: number;
    page: number;
    total: number;
}

class NotificationUsersService {
    notificationUsers:IUserNotificationView= {} as IUserNotificationView;

    async getUserInNotificationUsersByCriteria(criteria: IUserCustomerCriteria) {
        try {
            const response = await apiService.post(`/user-notifications/users/criteria`, criteria);
            if (response.users.datas.length > 0) {
                this.notificationUsers = {
                    datas: response.users.datas,
                    all: response.users.all,
                    limit: response.users.limit,
                    page: response.users.page,
                    total: response.users.total
                };
            } else {
                this.notificationUsers = {
                    datas: [],
                    all: 0,
                    limit: criteria.limit,
                    page: criteria.page,
                    total: 0
                };
            }
            return this.notificationUsers;
        } catch (error) {
            throw error;
        } 
    }

    async create(data: IUserNotification) {
        try {
            const response = await apiService.post(`/user-notifications`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async update(id: string, data: IUserNotification) {
        try {
            const response = await apiService.put(`/user-notifications/${id}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async deleteNotification(id: string) {
        try {
            const response = await apiService.delete(`/user-notifications/${id}`);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

const notificationUsersService = new NotificationUsersService();
export default notificationUsersService;
