import { FC, useEffect, useState } from "react";
import PackagesListView from "../Views/PackagesListView";
import {
  IPackageCriteria,
  IPackageType,
  IPackageView,
  IPackageViewData,
} from "../Services/PackagesService";
import PackagesService from "../Services/PackagesService";
import { enqueueSnackbar } from "notistack";

interface PackagesListControllerProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการจัดการข้อมูล
}
const PackagesListController: FC<PackagesListControllerProps> = (props) => {
  const [packages, setPackages] = useState<IPackageView>();
  const [types, setTypes] = useState<IPackageType[]>();

  useEffect(() => {
    const criteria: IPackageCriteria = {
      page: 0,
      limit: 10,
      isActive: true,
      store: "",
      name: "",
      type: 0,
    };
    searchByCriteria(criteria);
    getType();
  }, []);

  const fetchPackages = async () => {
    try {
      const datas = await PackagesService.getAll();
      if (datas) {
        setPackages(datas as IPackageView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const getType = async () => {
    await PackagesService.getPackageTypes()
      .then((res: any) => {
        if (res) {
          setTypes(res);
        } else {
          enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      });
  };

  const searchByCriteria = async (criteria: IPackageCriteria) => {
    try {
      const datas = await PackagesService.getByCriteria(criteria);
      if (datas) {
        setPackages(datas as IPackageView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const updateIsActive = async (id: string, isActive: boolean) => {
    await PackagesService.updateIsActiveChecked(id, {
      isActive,
      updateAt: new Date(),
    })
      .then((res) => {
        if (res) {
          const datas = packages?.datas.find(
            (data: IPackageViewData) => data.id === id
          );
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
          if (datas) {
            datas.isActive = isActive;
            setPackages({
              ...packages,
              datas: packages?.datas,
            } as IPackageView);
          }
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };
  return (
    <PackagesListView
      getAll={fetchPackages}
      packages={packages ?? ({} as IPackageView)}
      onSearch={(criteria: IPackageCriteria) => {
        searchByCriteria(criteria);
      }}
      types={types ?? ([] as IPackageType[])}
      onUpdateIsActive={(id: string, IsActive: boolean) => {
        updateIsActive(id, IsActive);
      }}
    />
  );
};

export default PackagesListController;
