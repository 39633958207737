import React, { FC, MouseEvent, useRef, useState } from 'react';
import { Alert, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface LoginFormProps {
    onSubmit: (username: string, password: string, isRemember: boolean) => void;
    hasError: boolean;
    hasSuccess: boolean;
}

const LoginForm: FC<LoginFormProps> = (props) => {
    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const isRememberRef = useRef<HTMLInputElement>(null);
    const [showPassword, setShowPassword] = useState(false);


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };
    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = () => {
        if (usernameRef.current && passwordRef.current && isRememberRef.current) {
            props.onSubmit(usernameRef.current.value, passwordRef.current.value, isRememberRef.current.checked);
        }
    };

    return (
        <form >
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                inputRef={usernameRef}
            />
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    inputRef={passwordRef}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                            handleSubmit();
                        }
                    }}

                />
            </FormControl>
            <FormControlLabel
                control={
                    <Checkbox
                        inputRef={isRememberRef}
                        color="primary"
                    />
                }
                label="Remember me"
            />
            {props.hasError && <Alert sx={{ my: 1 }} severity="error">Username หรือ Password ไม่ถูกต้อง</Alert>}
            {props.hasSuccess && <Alert sx={{ my: 1 }} severity="success">เข้าสู่ระบบสำเร็จ</Alert>}
            <Button onClick={handleSubmit} fullWidth variant="contained" color="primary">
                เข้าสู่ระบบ
            </Button>
        </form>
    );
};

export default LoginForm;
