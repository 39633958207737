import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { FC, useState } from "react";
import {
  IPackageCriteria,
  IPackageType,
  IPackageView,
} from "../Services/PackagesService";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import APP_STORE from "../../../res/images/APP_STORE.png";
import PLAY_STORE from "../../../res/images/PLAY_STORE.png";

interface PackgesListViewProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการแสดง UI
  getAll: () => void;
  packages: IPackageView;
  onSearch: (criteria: IPackageCriteria) => void;
  types: IPackageType[];
  onUpdateIsActive: (id: string, isActive: boolean) => void;
}

const PackagesListView: FC<PackgesListViewProps> = (props) => {
  const [name, setName] = useState("");
  const [type, setType] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [store, setStore] = useState("all");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const navigate = useNavigate();

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber === 0 ? 0 : pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IPackageCriteria = {
      name: name,
      type: type,
      isActive: isActive,
      store: store === "all" ? "" : store,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSearch(criteria);
  };

  return (
    <Box component={"div"}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>แพ็คเกจ</Typography>
        <Typography variant="h6" color="text.primary">
          รายการ
        </Typography>
      </Breadcrumbs>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel id="store-select-label">Store</InputLabel>
                    <Select
                      labelId="store-select-label"
                      value={store}
                      id="store-select"
                      label="store"
                      onChange={(e) => {
                        setStore(e.target.value);
                      }}
                    >
                      <MenuItem value="all">ทั้งหมด</MenuItem>
                      <MenuItem value="APP_STORE">App Store</MenuItem>
                      <MenuItem value="PLAY_STORE">Play Store</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="ชื่อแพ็คเกจ"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="packageType-select-label">
                      ประเภท
                    </InputLabel>
                    <Select
                      labelId="packageType-select-label"
                      value={type}
                      id="packageType-select"
                      label="ประเภท"
                      onChange={(e) => {
                        setType(e.target.value as number);
                      }}
                    >
                      <MenuItem value={0}>ทั้งหมด</MenuItem>
                      {props.types && props.types.length > 0 ? (
                        props.types.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>ไม่พบข้อมูล</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={2} textAlign={"center"} alignSelf={"center"}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isActive}
                          onChange={(e) => {
                            setIsActive(e.target.checked);
                          }}
                        />
                      }
                      label="ใช้งานอยู่"
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={2} textAlign={"center"} alignSelf={"center"}>
                  <Button
                    variant="contained"
                    onClick={() => searchByCriteria(0)}
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography>
                        ทั้งหมด {props.packages.total} รายการ
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      alignContent={"flex-end"}
                      textAlign={"end"}
                    >
                      <Button
                        variant="contained"
                        startIcon={<NoteAddIcon />}
                        onClick={() => navigate("/package-create")}
                      >
                        สร้าง
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Store</TableCell>
                          <TableCell align="center">ชื่อแพ็คเกจ</TableCell>
                          <TableCell align="center">ประเภท</TableCell>
                          <TableCell align="center">ราคา</TableCell>
                          <TableCell align="center">บันทึก</TableCell>
                          <TableCell align="center">แก้ไข</TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {props.packages.datas &&
                          props.packages.datas.length > 0 ? (
                          props.packages.datas.map((data) => (
                            <TableRow
                              key={data.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="center">
                                {data.store === "APP_STORE" ? (
                                  <Tooltip title="App Store" arrow>
                                    <img
                                      src={APP_STORE}
                                      alt="appstore"
                                      style={{ width: "20px" }}
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Play Store" arrow>
                                    <img
                                      src={PLAY_STORE}
                                      alt="playstore"
                                      style={{ width: "20px" }}
                                    />
                                  </Tooltip>
                                )}
                              </TableCell>
                              <TableCell align="center">{data.name}</TableCell>
                              <TableCell align="center">
                                {data.type?.name}
                              </TableCell>
                              <TableCell align="center">{data.price}</TableCell>

                              <TableCell align="center">
                                <Typography fontWeight={"bold"}>
                                  {data.createBy ? data.createBy.firstName : ""}
                                </Typography>
                                <Typography>
                                  {data.createAt
                                    ? dayjs(data.createAt).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                    : ""}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography fontWeight={"bold"}>
                                  {data.updateBy ? data.updateBy.firstName : ""}
                                </Typography>
                                <Typography>
                                  {data.updateAt
                                    ? dayjs(data.updateAt).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                    : ""}
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Switch
                                  checked={data.isActive}
                                  onChange={() => {
                                    props.onUpdateIsActive(
                                      data.id!,
                                      !data.isActive
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <IconButton
                                  onClick={() =>
                                    navigate(`/package-edit/${data.id}`)
                                  }
                                >
                                  <KeyboardArrowRightIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              <Typography>ไม่พบข้อมูล</Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={props.packages.total ?? 0}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        searchByCriteria(newPage);
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setLimit(e.target.value);
                        setPage(0);
                        searchByCriteria(page, e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PackagesListView;
