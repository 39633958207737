import { FC, useEffect, useState } from "react";
import GodsListView from "../Views/GodsListView";
import GodsService, {
  IGodCriteria,
  IGodView,
  IGodViewData,
} from "../Services/GodsService";
import { enqueueSnackbar } from "notistack";
import { sortOptions } from "../../../constants/sortOptions";

interface GodsListControllerProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการจัดการข้อมูล
}
const GodsListController: FC<GodsListControllerProps> = (props) => {
  const [gods, setGods] = useState<IGodView>();

  useEffect(() => {
    const citeria: IGodCriteria = {
      title: "",
      page: 0,
      limit: 10,
      isFavorite: false,
      sortBy: sortOptions[0],
    };
    searchByCiteria(citeria);
  }, []);

  const searchByCiteria = async (criteria: IGodCriteria) => {
    try {
      const datas = await GodsService.getByCriteria(criteria);
      if (datas) {
        setGods(datas as IGodView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const updateFavorite = async (id: number, isFavorite: boolean) => {
    await GodsService.updateFavoriteChecked(id, {
      isFavorite,
      updateAt: new Date(),
    }).then((res) => {
      if (res) {
        const datas = gods?.datas.find((god: IGodViewData) => god.id === id);
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        if (datas) {
          datas.isFavorite = isFavorite;
          setGods({ ...gods, datas: gods?.datas } as IGodView);
        }
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    }).catch((err) => {
      console.error(err);
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    });
  };

  return (
    <GodsListView
      onUpdateFavorite={(id: number, isFavorite: boolean) => {
        updateFavorite(id, isFavorite);
      }}
      gods={gods ?? ({} as IGodView)}
      onSearch={(criteria: IGodCriteria) => {
        searchByCiteria(criteria);
      }}
    />
  );
};

export default GodsListController;
