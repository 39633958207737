import { FC, useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import questionsService, {
  IQuestionCriteria,
  IQuestionType,
  IQuestionView,
  IQuestionViewData,
} from "../../Services/QuestionsService";
import QuestionsListView from "../../Views/Question/QuestionsListView";
import questionsGroupService, { IQuestionsGroup } from "../../Services/QuestionsGroupService";
import questionTypeService from "../../Services/QuestionTypesService";

interface QuestionsListControllerProps {
  // Define your view props here
}

const QuestionsListController: FC<QuestionsListControllerProps> = (props) => {
  const [questions, setQuestions] = useState<IQuestionView>();
  const [questionGroup, setQuestionGroup] = useState<IQuestionsGroup[]>();
  const [typeName, setTypeName] = useState<IQuestionType[]>();

  useEffect(() => {
    const citeria: IQuestionCriteria = {
      name: "",
      questionGroup: "",
      questionType: "",
      isActive: true,
      page: 0,
      limit: 10,
    };
    searchByCriteria(citeria);
    getQuestionTypes();
    getQuestionGroups();
  }, []);

  const getQuestionTypes = async () => {
      await questionTypeService.getAll().then((res: any) => {
        if (res) {
          setTypeName(res);
        } else {
          enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
        }
      }).catch((error) => {
        console.error(error);
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      })
  };

  const getQuestionGroups = async () => {
    await questionsGroupService
      .getAll()
      .then((res: any) => {
        if (res) {
          setQuestionGroup(res);
        } else {
          enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      });
  };

  const searchByCriteria = async (criteria: IQuestionCriteria) => {
    try {
      const datas = await questionsService.getByCriteria(criteria);
      if (datas) {
        setQuestions(datas as IQuestionView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const updateIsActive = async (id: string, isActive: boolean) => {
    await questionsService
      .updateIsActiveChecked(id, {
        isActive,
        updateAt: new Date(),
      })
      .then((res) => {
        if (res) {
          const datas = questions?.datas.find(
            (data: IQuestionViewData) => data.id === id
          );
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
          if (datas) {
            datas.isActive = isActive;
            setQuestions({
              ...questions,
              datas: questions?.datas,
            } as IQuestionView);
          }
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      }).catch((error) => {
        console.error(error);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      })
  };

  return (
    <QuestionsListView
      questions={questions as IQuestionView}
      questionGroups={questionGroup ?? ([] as IQuestionsGroup[])}
      questionTypes={typeName ?? ([] as IQuestionType[])}
      onSearch={(criteria: IQuestionCriteria) => {
        searchByCriteria(criteria);
      }}
      onUpdateIsActive={(id: string, IsActive: boolean) => {
        updateIsActive(id, IsActive);
      }}
    />
  );
};

export default QuestionsListController;
