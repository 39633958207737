import { FC, useEffect, useState } from "react";
import PromotionsListView from "../Views/PromotionsListView";
import PromotionsService, {
  IPromotionCriteria,
  IPromotionType,
  IPromotionView,
  IPromotionViewData,
} from "../Services/PromotionsService";
import { enqueueSnackbar } from "notistack";

interface PromotionsListControllerProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการจัดการข้อมูล
}

const PromotionsListController: FC<PromotionsListControllerProps> = (props) => {
  const [promotions, setPromotions] = useState<IPromotionView>();
  const [types, setTypes] = useState<IPromotionType[]>();

  useEffect(() => {
    const criteria: IPromotionCriteria = {
      page: 0,
      limit: 10,
      isActive: true,
      name: "",
      type: "",
      startDate: undefined,
      endDate: undefined,
    };
    searchByCriteria(criteria);
    getType();
  }, []);
  const fetchPromotions = async () => {
    try {
      const datas = await PromotionsService.getAll();
      if (datas) {
        setPromotions(datas as IPromotionView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const getType = async () => {
    await PromotionsService.getPromotionTypes()
      .then((res: any) => {
        if (res) {
          setTypes(res);
        } else {
          enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      });
  };

  const searchByCriteria = async (criteria: IPromotionCriteria) => {
    try {
      const datas = await PromotionsService.getByCriteria(criteria);
      if (datas) {
        setPromotions(datas as IPromotionView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const updateIsActive = async (id: string, isActive: boolean) => {
    await PromotionsService.updateIsActiveChecked(id, {
      isActive,
      updateAt: new Date(),
    })
      .then((res) => {
        if (res) {
          const datas = promotions?.datas.find(
            (data: IPromotionViewData) => data.id === id
          );
          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
          if (datas) {
            datas.isActive = isActive;
            setPromotions({
              ...promotions,
              datas: promotions?.datas,
            } as IPromotionView);
          }
        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  return (
    <PromotionsListView
      getAll={fetchPromotions}
      promotions={promotions ?? ({} as IPromotionView)}
      onSearch={(criteria: IPromotionCriteria) => {
        searchByCriteria(criteria);
      }}
      types={types ?? ([] as IPromotionType[])}
      onUpdateIsActive={(id: string, isActive: boolean) => {
        updateIsActive(id, isActive);
      }}
    />
  );
};

export default PromotionsListController;
