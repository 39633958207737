import {
  Box,
  Grid,
  IconButton,
  Breadcrumbs,
  Typography,
  Button,
  Card,
  CardContent,
  TextField,
  Link,
  ButtonProps,
  Divider,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DialogConfirm from "../../../../components/DialogConfirm";
import { INotificationTypes } from "../../Services/NotificationTypesService";
import { checkValidation } from "../../../../utils";
import loginService from "../../../Login/Services/LoginService";
import DeleteIcon from "@mui/icons-material/Delete";

interface NotificationTypeCreateProps {
  onCreate: (data: INotificationTypes) => void;
  onUpdate: (id: string, data: INotificationTypes) => void;
  onDelete: (id: string) => void;
  data: INotificationTypes;
  notificationTypeId: string;
}

const NotificationTypeCreate: FC<NotificationTypeCreateProps> = (props) => {
  const navigate = useNavigate();
  const user = loginService.user;
  const [nameType, setNameType] = useState("");

  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  useEffect(() => {
    if (props.data) {
      setNameType(props.data.name ?? "");
    }
  }, [props.data]);

  const checkValidate = () => {
    if (!checkValidation(nameType, "กรุณากรอกชื่อ")) return false;
    return true;
  };

  const handleSave = () => {
    if (checkValidate()) {
      const data: INotificationTypes = {
        name: nameType,
        createAt: new Date(),
        createBy: user?.id ?? "",
      };
      props.onCreate(data);
    }
  };

  const handleEdit = () => {
    if (checkValidate()) {
      const id = props.notificationTypeId;
      const data: INotificationTypes = {
        name: nameType,
        updateAt: new Date(),
        updateBy: user?.id ?? "",
      };
      props.onUpdate(id, data);
    }
  };

  const handleDelete = () => {
    props.onDelete(props.notificationTypeId ?? "");
  };

  return (
    <Box component={"div"}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/notification-types-list")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>แจ้งเตือน</Typography>
            <Link
              component="button"
              onClick={() => navigate("/notification-types-list")}
            >
              ประเภทการแจ้งเตือน
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.notificationTypeId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"end"}>
          {props.notificationTypeId && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setOpenConfirm({
                  open: true,
                  title: "ลบประเภทการแจ้งเตือน",
                  message: "คุณต้องการลบประเภทการแจ้งเตือนนี้ใช่หรือไม่",
                  color: "error",
                  type: "delete",
                });
              }}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="title"
                    label="ชื่อ"
                    variant="outlined"
                    fullWidth
                    value={nameType}
                    onChange={(e) => {
                      setNameType(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} mt={1} mb={1}>
                  <Divider />
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="right"
                  alignItems="center"
                >
                  <Button
                    variant="outlined"
                    style={{ marginRight: "10px", width: "15%" }}
                    onClick={() => navigate("/notification-types-list")}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    variant="contained"
                    style={{ width: "15%" }}
                    onClick={() => {
                      if (props.notificationTypeId) {
                        setOpenConfirm({
                          open: true,
                          title: "แก้ไขข้อมูล",
                          message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
                          color: "primary",
                          type: "edit",
                        });
                      } else {
                        setOpenConfirm({
                          open: true,
                          title: "บันทึกข้อมูล",
                          message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
                          color: "primary",
                          type: "create",
                        });
                      }
                    }}
                  >
                    บันทึก
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            handleDelete();
          } else if (openConfirm.type === "create") {
            handleSave();
          } else if (openConfirm.type === "edit") {
            handleEdit();
          }
        }}
      />
    </Box>
  );
};

export default NotificationTypeCreate;
