import React, { useEffect, useState } from "react";
import ArticleListView from "../Views/ArticlesListView";
import {
  IArticleCriteria,
  IArticleView,
  IArticleViewData,
} from "../Services/ArticlesService";
import ArticlesService from "../Services/ArticlesService";
import { enqueueSnackbar } from "notistack";
import { sortOptions } from "../../../constants/sortOptions";

interface ArticlesListControllerProps {
  // Define your view props here
}

const ArticlesListController: React.FC<ArticlesListControllerProps> = (
  props
) => {
  const [artiCles, setArtiCles] = useState<IArticleView>();

  useEffect(() => {
    const citeria: IArticleCriteria = {
      title: "",
      content: "",
      isActive: true,
      page: 0,
      limit: 10,
      sortBy: sortOptions[0],
    };
    searchByCiteria(citeria);
  }, []);

  const fetchArticles = async () => {
    try {
      const datas = await ArticlesService.getAll();
      if (datas) {
        setArtiCles(datas as IArticleView);
      } else {
        enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const searchByCiteria = async (criteria: IArticleCriteria) => {
    try{
        const datas = await ArticlesService.getByCriteria(criteria);
    if (datas) {
      setArtiCles(datas as IArticleView);
    } else {
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("ไม่พบข้อมูล", { variant: "error" });
    }
  };

  const updateIsActive = async (id: string, isActive: boolean) => {
    await ArticlesService.updateIsActiveChecked(id, {
      isActive,
      updateAt: new Date(),
    }).then((res) => {
      if (res) {
        const datas = artiCles?.datas.find(
          (data: IArticleViewData) => data.id === id
        );
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        if (datas) {
          datas.isActive = isActive;
          setArtiCles({
            ...artiCles,
            datas: artiCles?.datas,
          } as IArticleView);
        }
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    }).catch((err) => {
      console.error(err);
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
    })
  };

  return (
    <ArticleListView
      getAll={fetchArticles}
      articles={artiCles ?? ({} as IArticleView)}
      searchByCiteria={(criteria: IArticleCriteria) => {
        searchByCiteria(criteria);
      }}
      onUpdateIsActive={(id: string, isActive: boolean) => {
        updateIsActive(id, isActive);
      }}
    />
  );
};

export default ArticlesListController;
