import React from 'react';
import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

interface DialogConfirmProps {
    open: boolean;
    type: ButtonProps['color'];
    title: string;
    message?: string;
    onClose: () => void;
    onSubmit: () => void;
}

const DialogConfirm: React.FC<DialogConfirmProps> = (props: DialogConfirmProps) => {
    return (
        <Dialog open={props.open} fullWidth maxWidth={'xs'}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent >
                <Typography >{props.message || ''}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="text" onClick={() => props.onClose()}>ยกเลิก</Button>
                {/* comment: ใส่ disabled = !props.open เพื่อแก้ปัญหาcreateเบิ้ล */}
                <Button variant="contained" color={props.type} disabled={!props.open} onClick={() => props.onSubmit()}>ยืนยัน</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogConfirm;
