import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  Typography,
  IconButton,
  Card,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import APP_STORE from "../../../res/images/APP_STORE.png";
import PLAY_STORE from "../../../res/images/PLAY_STORE.png";
import {
  IUserPaymentHistoryCriteria,
  IUserPaymentHistoryView,
} from "../Services/UserPaymentsServices";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { IUserCustomer } from "../Services/UserCustomersServices";
import { sortOptions } from "../../../constants/sortOptions";
import { ISortBy } from "../../Gods/Services/GodsService";

interface UserPaymentsHistoryViewProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการแสดง UI
  userPaymentHistory: IUserPaymentHistoryView;
  onSearch: (criteria: IUserPaymentHistoryCriteria) => void;
  userId: string;
  customer: IUserCustomer;
}

const UserPaymentsHistoryView: FC<UserPaymentsHistoryViewProps> = (props) => {
  const filterSortOptions = sortOptions.map((sortOption: ISortBy) => {
    if (sortOption.sortBy === 'createAt') {
      sortOption.name = 'วันที่ซื้อ: ' + (sortOption.sortType === 'DESC' ? 'ล่าสุด' : 'เก่าสุด');
    }
    if (sortOption.sortBy === 'updateAt') {
      sortOption.name = 'วันหมดอายุ: ' + (sortOption.sortType === 'DESC' ? 'ล่าสุด' : 'เก่าสุด');
      sortOption.sortBy = 'expireAt';
    }
    return sortOption;
  });
  const navigate = useNavigate();

  const [searchType, setSearchType] = useState("all");
  const [createAt, setCreateAt] = useState<dayjs.Dayjs>(
    dayjs().startOf("month")
  );
  const [expireAt, setExpireAt] = useState<dayjs.Dayjs>(dayjs());
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState<ISortBy>(filterSortOptions[0]);

  const handleFromDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setCreateAt(date);
    }
  };

  const handleToDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setExpireAt(date);
    }
  };

  const searchByCiteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber === 0 ? 0 : pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const criteria: IUserPaymentHistoryCriteria = {
      userId: props.userId,
      from: searchType === "all" ? undefined : createAt.toDate(),
      to: searchType === "all" ? undefined : expireAt.toDate(),
      page: pageNumber,
      limit: rowLimit ? rowLimit : 10,
      sortBy: sortBy,
    };
    props.onSearch(criteria);
  };

  return (
    <Box component="div">
      <Grid container spacing={2} mt={1}>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/payments-list")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>การชำระเงิน</Typography>
            <Link component="button" onClick={() => navigate("/payments-list")}>
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              ประวัติการซื้อ
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography fontWeight={"bold"}>ชื่อลูกค้า:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography marginLeft={1} variant="body1">
                        {props.customer?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography fontWeight={"bold"}>วันเกิด:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography marginLeft={1} variant="body1">
                        {props.customer.birthDate
                          ? dayjs(props.customer.birthDate).format("DD/MM/YYYY")
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography fontWeight={"bold"}>ช่วงเวลาเกิด:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography marginLeft={1} variant="body1">
                        {props.customer.birthTimeStart
                          ? props.customer.birthTimeStart
                          : ""}
                        -
                        {props.customer.birthTimeEnd
                          ? props.customer.birthTimeEnd
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography fontWeight={"bold"}>เพศ:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1">
                        {props.customer
                          ? props.customer.gender === "M"
                            ? "ชาย"
                            : "หญิง"
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" fontWeight={"semibold"}>
                    ประวัติการซื้อ
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel id="search-select-label">
                          เลือกการค้นหา
                        </InputLabel>
                        <Select
                          labelId="search-select-label"
                          id="search-select"
                          label="เลือกการค้นหา"
                          value={searchType}
                          onChange={(e) => setSearchType(e.target.value)}
                        >
                          <MenuItem value="all">ทั้งหมด</MenuItem>
                          <MenuItem value="Purchase_Date">วันที่ซื้อ</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {searchType === "Purchase_Date" ? (
                      <>
                        <Grid item xs={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="ตั้งแต่วันที่"
                              sx={{ width: "100%" }}
                              format="DD/MM/YYYY"
                              value={createAt}
                              onChange={handleFromDateChange}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="ถึงวันที่"
                              sx={{ width: "100%" }}
                              format="DD/MM/YYYY"
                              value={expireAt}
                              onChange={handleToDateChange}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </>
                    ) : (
null
                    )}
<Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="status-select-label">เรียงตาม</InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="status-select"
                      value={sortBy?.value}
                      label="เรียงตาม"
                      onChange={(event) => {
                        const selectedOption = filterSortOptions.find((option:any) => option.value === event.target.value);
                        if (selectedOption) {
                          setSortBy(selectedOption);
                        }
                      }}
                    >
                      {filterSortOptions.map((item:any, index:number) => (
                        <MenuItem key={index} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                </Grid>
                {searchType !== "Purchase_Date" && (
                  <Grid item xs={4}></Grid>
                )}
                    <Grid item xs={2} textAlign={"center"} alignSelf={"center"}>
                      <Button
                        variant="contained"
                        startIcon={<SearchIcon />}
                        onClick={() => searchByCiteria()}
                      >
                        ค้นหา
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Store</TableCell>
                          <TableCell align="center">ชื่อแพ็คเกจ</TableCell>
                          <TableCell align="center">ราคา</TableCell>
                          <TableCell align="center">วันที่ซื้อ</TableCell>
                          <TableCell align="center">วันหมดอายุ</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.userPaymentHistory &&
                          props.userPaymentHistory.datas &&
                          props.userPaymentHistory.datas.length > 0 ? (
                          props.userPaymentHistory.datas.map((row) => (
                            <TableRow key={row.id}>
                              <TableCell align="center">
                                {row.store === "APP_STORE" ? (
                                  <Tooltip title="App Store" arrow>
                                    <img
                                      src={APP_STORE}
                                      alt="appstore"
                                      style={{ width: "20px" }}
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Play Store" arrow>
                                    <img
                                      src={PLAY_STORE}
                                      alt="playstore"
                                      style={{ width: "20px" }}
                                    />
                                  </Tooltip>
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {row.package.name}
                              </TableCell>

                              <TableCell align="center">
                                {row.package.price}
                              </TableCell>
                              <TableCell align="center">
                                {row.createAt
                                  ? dayjs(row.createAt).format("DD/MM/YYYY")
                                  : "-"}
                              </TableCell>
                              <TableCell align="center">
                                {" "}
                                {row.expireAt
                                  ? dayjs(row.expireAt).format("DD/MM/YYYY")
                                  : "-"}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell align="center" colSpan={5}>
                              ไม่พบข้อมูล
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={
                        (props.userPaymentHistory &&
                          props.userPaymentHistory.total) ??
                        0
                      }
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        searchByCiteria(newPage);
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setLimit(e.target.value);
                        setPage(0);
                        searchByCiteria(page, e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserPaymentsHistoryView;
