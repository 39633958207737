import {
  Box,
  Breadcrumbs,
  Button,
  ButtonProps,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, useMemo, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import JoditConfig from "../../../constants/joditConfig";
import JoditEditor from "jodit-react";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogConfirm from "../../../components/DialogConfirm";
import {
  INotification,
  INotificationDetail,
  INotificationViewData,
} from "../Services/NotificationsService";
import { INotificationTypes } from "../Services/NotificationTypesService";
import { checkValidation } from "../../../utils";
import loginService from "../../Login/Services/LoginService";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/th";
import {
  IUserCustomer,
  IUserCustomerCriteria,
  IUserCustomerView,
} from "../../Customers/Services/UserCustomersServices";
import SearchIcon from "@mui/icons-material/Search";
import FACEBOOK_ICON from "../../../res/images/icon_facebook.png";
import APPLE_ICON from "../../../res/images/icon_apple.png";
import GOOGLE_ICON from "../../../res/images/icon_google.png";
import LINE_ICON from "../../../res/images/icon_line.png";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import langs from "../../../constants/langs";
import platforms from "../../../constants/platforms";
import genders from "../../../constants/genders";
interface NotificationViewProps {
  getOne: (id: string) => void;
  onCreate: (data: INotification) => void;
  onUpdate: (id: string, data: INotification) => void;
  onDelete: (id: string) => void;
  onSearch: (criteria: IUserCustomerCriteria) => void;
  data: INotificationViewData;
  notificationId: string;
  types: INotificationTypes[] | {};
  users: IUserCustomerView;
  id: string;
}

const NotificationCreateView: FC<NotificationViewProps> = (props) => {
  const navigate = useNavigate();
  const user = loginService.user;
  const [title, setTitle] = useState("");
  const [type, setType] = useState(0);
  const [path, setPath] = useState("");
  const [dateType, setDateType] = useState("birthDate");
  const [isDevice, setIsDevice] = useState(true);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs());
  const [dateTo, setDateTo] = useState<Dayjs | null>(dayjs());
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  const [birthTimeStart, setBirthTimeStart] = useState<Dayjs | null>(dayjs());
  const [birthTimeEnd, setBirthTimeEnd] = useState<Dayjs | null>(dayjs());
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isAll, setIsAll] = useState([false]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [platform, setPlatform] = useState("all");
  const [gender, setGender] = useState("all");
  const [isFilter, setIsFilter] = useState(false);
  const [isPublish, setIsPublish] = useState([false]);
  const [name, setName] = useState("");
  const [rowsAll, setRowsAll] = useState<{ [key: number]: IUserCustomer[] }>(
    []
  );
  const [lang, setLang] = useState("all");
  const [value, setValue] = useState("th");
  const [details, setDetails] = useState<INotificationDetail[]>([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (props.data) {
      setTitle(title ? title : props.data.title ?? "");
      if (!props.data.type?.isDelete) {
        setType(type ? type : props.data.type?.id ?? 0);
      }
      setPath(path ? path : props.data.path ?? "");
      setIsPublish([props.data.isPublish ?? false]);
    }
    setIndex(0);
    setValue("th");
    if (props.data?.detail && props.data?.detail?.length > 0) {
      props.data.detail.forEach((detail: any) => {
        const findNotificationIndex = langs.findIndex(
          (lang) => lang.value === detail.lang
        );
        const findDetailIndex = details.findIndex(
          (data) => data.lang === detail.lang
        );
        if (findDetailIndex !== -1) {
          setDetails((prevDetails) => {
            prevDetails[findDetailIndex] = {
              id: detail.id,
              ...prevDetails[findDetailIndex],
              title: detail.title,
              content: detail.content,
            };
            return prevDetails;
          });
        } else {
          details.push({
            lang: langs[findNotificationIndex].value,
            title: "",
            content: "",
          });
        }
      });
    } else {
      langs.forEach((data) => {
        if (details.length < langs.length) {
          details.push({
            lang: data.value,
            title: "",
            content: "",
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(() => {
    if (props.users) {
      if (!props.id) {
        let _data = props.users?.datas?.map((data) => {
          return { ...data, isChecked: false };
        });
        if (isCheckAll) {
          _data = props.users?.datas?.map((data) => {
            return { ...data, isChecked: true };
          });
        }
        setRowsAll((prevRowsAll) => ({
          ...prevRowsAll,
          [page]: _data,
        }));
        langs.forEach((data) => {
          if (details.length < langs.length) {
            details.push({
              lang: data.value,
              title: "",
              content: "",
            });
          }
        });
      } else {
        let _data = props.users?.datas;

        if (isCheckAll && rowsAll[0]?.length > 0) {
          _data = props.users?.datas?.map((data) => {
            return { ...data, isChecked: true };
          });
        }
        setIsCheckAll(props.users?.total === props.users?.all);
        setIsDevice(props.users?.total === props.users?.all && isDevice);
        setIsAll((prevIsAll) => [
          ...prevIsAll,
          props.users?.total === props.users?.all,
        ]);
        setRowsAll((prevRowsAll) => ({
          ...prevRowsAll,
          [page]: _data,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.users]);

  const checkValidate = () => {
    if (!checkValidation(type, "กรุณาเลือกประเภท")) {
      return false;
    }
    if (!checkValidation(path, "กรุณาใส่ Path")) {
      return false;
    }
    const isTitle = details.filter(
      (data: any) => data.title === "" && data.content !== ""
    );
    const isContent = details.filter(
      (data: any) =>
        data.title !== "" && data.content.replace(/<[^>]+>/g, "") === "" //convet html to string
    );
    const _detail = details.filter(
      (data: INotificationDetail) => data.title !== "" && data.content !== ""
    );

    if (!checkValidation(isTitle.length === 0, "กรุณากรอกหัวเรื่อง")) {
      return false;
    }
    if (!checkValidation(isContent.length === 0, "กรุณากรอกเนื้อหา")) {
      return false;
    }
    if (
      !checkValidation(_detail.length > 0, "กรุณากรอกเนื้อหาอย่างน้อย 1 ภาษา")
    ) {
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (checkValidate()) {
      const obj = rowsAll;
      const concatenatedArray = Object.values(obj).flat();
      const child =
        concatenatedArray?.map((data) => {
          return {
            ...data,
            createAt: new Date(),
            createBy: user?.id ?? "",
            user: { ...data },
          };
        }) ?? [];
      const criteria: IUserCustomerCriteria = {
        name: name || "",
        birthTimeStart: isFilter
          ? birthTimeStart?.format("HH:mm:ss")
          : undefined,
        birthTimeEnd: isFilter ? birthTimeEnd?.format("HH:mm:ss") : undefined,
        dateFrom: isFilter ? dateFrom?.format("YYYY-MM-DD") : undefined,
        dateTo: isFilter ? dateTo?.format("YYYY-MM-DD") : undefined,
        gender: isFilter ? gender : "all",
        platform: isFilter ? platform : "all",
        dateType: isFilter ? dateType : "",
        page: 0,
        limit: props?.users?.total,
        id: props.id || undefined,
      };
      const detail: INotificationDetail[] = details.filter(
        (data: INotificationDetail) => data.title !== "" && data.content !== ""
      );
      const data: INotification = {
        type: type,
        path: path,
        createAt: new Date(),
        createBy: user?.id ?? "",
        users: child,
        criteria: criteria,
        isAll: isAll,
        isDevice: isDevice,
        isPublish: isPublish,
        detail: detail,
      };
      props.onCreate(data);
    }
  };

  const handleEdit = () => {
    if (checkValidate()) {
      const id = props.notificationId;
      const obj = rowsAll;
      const concatenatedArray = Object.values(obj).flat();
      const child =
        concatenatedArray?.map((data) => {
          return {
            ...data,
            updateAt: new Date(),
            updateBy: user?.id ?? "",
            createAt: props.data?.createAt ?? new Date(),
            createBy: user?.id ?? "",
            user: { ...data },
          };
        }) ?? [];
      const detail: INotificationDetail[] = details.filter(
        (data: INotificationDetail) => data.title !== "" && data.content !== ""
      );
      const criteria: IUserCustomerCriteria = {
        name: name || "",
        birthTimeStart: isFilter
          ? birthTimeStart?.format("HH:mm:ss")
          : undefined,
        birthTimeEnd: isFilter ? birthTimeEnd?.format("HH:mm:ss") : undefined,
        dateFrom: isFilter ? dateFrom?.format("YYYY-MM-DD") : undefined,
        dateTo: isFilter ? dateTo?.format("YYYY-MM-DD") : undefined,
        gender: isFilter ? gender : "all",
        platform: isFilter ? platform : "all",
        dateType: isFilter ? dateType : "",
        page: 0,
        limit: props?.users?.total,
        id: props.id || undefined,
      };
      const data: INotification = {
        type: type,
        path: path,
        createAt: props.data?.createAt ?? new Date(),
        createBy: user?.id ?? "",
        updateAt: new Date(),
        updateBy: user?.id ?? "",
        users: child,
        criteria: criteria,
        isAll: isAll,
        isDevice: isDevice,
        isPublish: isPublish,
        detail: detail,
      };
      props.onUpdate(id, data);
    }
  };

  const handleDelete = () => {
    props.onDelete(props.notificationId ?? "");
  };

  const config = JoditConfig;
  const editorNotification = useMemo(
    () => {
      return (
        <JoditEditor
          config={config}
          value={details[index]?.content || ""}
          onBlur={(e) => {
            setDetails((pre: any) => {
              const _pre = [...pre];
              _pre[index].content = e;
              return _pre;
            });
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [index, details[index]?.content]
  );

  const handleSelectDevice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDevice(!isDevice);
  };

  const handleSelectDateType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateType(event.target.value);
  };

  const searchByCriteria = (
    pageNumber: number = 0,
    rowLimit?: number,
    isSearch?: boolean,
    _isFilter?: boolean
  ) => {
    setPage(pageNumber === 0 ? 0 : pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const data: IUserCustomerCriteria = {
      name: name || "",
      birthTimeStart: _isFilter
        ? birthTimeStart?.format("HH:mm:ss")
        : undefined,
      birthTimeEnd:
        _isFilter === true ? birthTimeEnd?.format("HH:mm:ss") : undefined,
      dateFrom: _isFilter === true ? dateFrom?.format("YYYY-MM-DD") : undefined,
      dateTo: _isFilter === true ? dateTo?.format("YYYY-MM-DD") : undefined,
      gender: _isFilter === true ? gender : "all",
      platform: _isFilter === true ? platform : "all",
      page: pageNumber,
      limit: rowLimit ?? 10,
      dateType: _isFilter === true ? dateType : "",
      notification: props.id ? props.id : undefined,
      lang: lang,
    };
    if (!rowsAll[pageNumber] || rowsAll[pageNumber].length === 0 || isSearch) {
      if (isSearch) {
        setRowsAll({});
      }
      props.onSearch(data);
    }
  };

  const handleChangeCheckall = (isNotAll?: boolean) => {
    if (isNotAll) {
      setIsCheckAll(false);
    } else {
      setIsCheckAll(!isCheckAll);
      setIsAll((preState) => [...preState, !preState[preState.length - 1]]);

      for (const property in rowsAll) {
        const _data = rowsAll[property]?.map((row) => {
          return { ...row, isChecked: !isCheckAll };
        });
        setRowsAll((prevRowsAll) => ({
          ...prevRowsAll,
          [property]: _data,
        }));
      }
    }
  };

  const handleChangeCheck = (index: number) => {
    if (rowsAll[page][index].isChecked === true) {
      handleChangeCheckall(true);
    }
    rowsAll[page][index].isChecked = !rowsAll[page][index].isChecked;
    setRowsAll((prevRowsAll) => ({
      ...prevRowsAll,
      [page]: rowsAll[page],
    }));
  };

  const handleChangeGender = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGender(event.target.value);
  };

  const handleChangePlatform = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlatform(event.target.value);
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleChangeLang = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLang(event.target.value);
  };

  const handleChangeIsFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFilter(!isFilter);
    searchByCriteria(0, undefined, true, !isFilter);
  };

  const handleChangeIsPublish = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsPublish((preState) => [...preState, !preState[preState.length - 1]]);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setIndex((prevIndex: number) => {
      const findNotificationIndex = details.findIndex(
        (data) => data.lang === newValue
      );
      if (findNotificationIndex !== -1) {
        return findNotificationIndex;
      } else {
        details.push({ lang: newValue, title: "", content: "" });
        return details.length - 1;
      }
    });
  };

  return (
    <Box component={"div"}>
      <Grid container>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/notifications-list")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>แจ้งเตือน</Typography>
            <Link
              component="button"
              onClick={() => navigate("/notifications-list")}
            >
              รายการ
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.notificationId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"end"}>
          {props.notificationId && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setOpenConfirm({
                  open: true,
                  title: "ลบรายการ",
                  message: "คุณต้องการลบรายการนี้ใช่หรือไม่",
                  color: "error",
                  type: "delete",
                });
              }}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel id="notificationGroup-select-label">
                      ประเภท
                    </InputLabel>
                    <Select
                      labelId="notificationGroup-select-label"
                      id="notificationGroup-select"
                      value={type}
                      label="ตำแหน่ง"
                      onChange={(e: any) => {
                        const typeName = e.target.value;
                        setType(typeName);
                      }}
                    >
                      <MenuItem value={0}>กรุณาเลือกประเภท</MenuItem>
                      {Object.values(props.types) &&
                      Object.values(props.types).length > 0
                        ? Object.values(props.types).map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))
                        : []}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={8}>
                  <TextField
                    id="path"
                    label="Path"
                    variant="outlined"
                    fullWidth
                    value={path}
                    onChange={(e) => {
                      setPath(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} mt={1}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    fontWeight={"bold"}
                    fontSize={16}
                  >
                    เนื้อหา
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        {langs.map((item) => (
                          <Tab
                            key={item.value}
                            label={item.name}
                            value={item.value}
                          />
                        ))}
                      </TabList>
                    </Box>
                    {langs.map((item) => (
                      <TabPanel value={item.value}>
                        <Grid item xs={12} mb={2}>
                          <TextField
                            id="title"
                            label="หัวเรื่อง"
                            variant="outlined"
                            fullWidth
                            value={details[index]?.title || ""}
                            onChange={(e) => {
                              setDetails((pre: any) => {
                                const _pre = [...pre];
                                _pre[index].title = e.target.value;
                                return _pre;
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {editorNotification}
                        </Grid>
                      </TabPanel>
                    ))}
                  </TabContext>
                </Grid>

                <Grid item xs={12} mt={1}>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    fontWeight={"bold"}
                    fontSize={16}
                  >
                    การแจ้งเตือน
                  </Typography>
                </Grid>

                <Grid item xs={12} mt={1}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={isDevice}
                      onChange={handleSelectDevice}
                    >
                      <div>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="ทั้งหมด"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="รายบุคคล"
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {!isDevice && (
                  <>
                    <Grid item xs={12} mt={1}>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        fontWeight={"bold"}
                        fontSize={16}
                      >
                        ผู้ใช้งาน
                        <FormControlLabel
                          sx={{
                            marginLeft: "10px",
                          }}
                          control={
                            <Checkbox
                              checked={isFilter}
                              onChange={handleChangeIsFilter}
                              name="isFilter"
                            />
                          }
                          label="กรองผู้ใช้งาน"
                        />
                      </Typography>
                    </Grid>

                    {isFilter && (
                      <>
                        <Grid item xs={12} mt={1} mb={1}>
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={dateType}
                              onChange={handleSelectDateType}
                            >
                              <div>
                                <FormControlLabel
                                  value="birthDate"
                                  control={<Radio />}
                                  label="วันเกิด"
                                />
                                <FormControlLabel
                                  value="packageDate"
                                  control={<Radio />}
                                  label="วันที่แพ็คเกจ"
                                />
                                <FormControlLabel
                                  value="createDate"
                                  control={<Radio />}
                                  label="วันที่บันทึก"
                                />
                              </div>
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid item xs={3} mt={1} mb={1}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="th"
                          >
                            <DemoContainer
                              components={["DatePicker", "DatePicker"]}
                            >
                              <DatePicker
                                label="ตั้งแต่วันที่"
                                value={dateFrom}
                                onChange={(newValue) => setDateFrom(newValue)}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={3} mt={1} mb={1}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="th"
                          >
                            <DemoContainer
                              components={["DatePicker", "DatePicker"]}
                            >
                              <DatePicker
                                label="ถึงวันที่"
                                value={dateTo}
                                onChange={(newValue) => setDateTo(newValue)}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={3} mt={1} mb={1}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="th"
                          >
                            <DemoContainer components={["TimePicker"]}>
                              <TimePicker
                                label="ตั้งแต่เวลา"
                                value={birthTimeStart}
                                onChange={(newValue) =>
                                  setBirthTimeStart(newValue)
                                }
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={3} mt={1} mb={1}>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="th"
                          >
                            <DemoContainer components={["TimePicker"]}>
                              <TimePicker
                                label="ถึงเวลา"
                                value={birthTimeEnd}
                                onChange={(newValue) => {
                                  setBirthTimeEnd(newValue);
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Grid>

                        <Grid item xs={1.5} mt={1} mb={1}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              เพศ
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={gender}
                              label="gender"
                              onChange={(e: any) => handleChangeGender(e)}
                            >
                              <MenuItem key={"all"} value={"all"}>
                                ทั้งหมด
                              </MenuItem>
                              {genders.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={2.5} mt={1} mb={1}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              สมัครผ่านทาง
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={platform}
                              label="platform"
                              onChange={(e: any) => handleChangePlatform(e)}
                            >
                              <MenuItem key={"all"} value={"all"}>
                                ทั้งหมด
                              </MenuItem>
                              {platforms.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={2} mt={1} mb={1}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              ภาษา
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={lang}
                              label="lang"
                              onChange={(e: any) => handleChangeLang(e)}
                            >
                              <MenuItem key={"all"} value={"all"}>
                                ทั้งหมด
                              </MenuItem>
                              {langs.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item xs={4} mt={1} mb={1}>
                          <TextField
                            onChange={(e: any) => handleChangeName(e)}
                            fullWidth
                            label="ชื่อผู้ใช้งาน"
                            value={name}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={2}
                          textAlign={"center"}
                          alignSelf={"center"}
                        >
                          <Button
                            variant="contained"
                            onClick={() =>
                              searchByCriteria(0, undefined, true, true)
                            }
                            startIcon={<SearchIcon />}
                          >
                            ค้นหา
                          </Button>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} mt={1} mb={1}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell width={210}>
                                <Checkbox
                                  value={isCheckAll}
                                  checked={isCheckAll}
                                  onChange={() => {
                                    handleChangeCheckall();
                                  }}
                                />
                                ทั้งหมด {props?.users?.total || 0} คน
                              </TableCell>
                              <TableCell align="center" width={100}>
                                วันเวลาเกิด
                              </TableCell>
                              <TableCell align="center">
                                ชื่อผู้ใช้งาน
                              </TableCell>
                              <TableCell align="center">เพศ</TableCell>
                              <TableCell align="center">แพลตฟอร์ม</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rowsAll[page]?.map((row, index) => (
                              <TableRow
                                key={`user-${index}-${row.id}`}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  <Checkbox
                                    value={row.isChecked}
                                    checked={row.isChecked}
                                    onChange={(e) => {
                                      handleChangeCheck(index);
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="center">{`${row.birthDate} ${row.timeValue}`}</TableCell>
                                <TableCell
                                  align="left"
                                  component="th"
                                  scope="row"
                                >
                                  {row?.name || " "}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.gender === "M"
                                    ? "ชาย"
                                    : row?.gender === "F"
                                    ? "หญิง"
                                    : ""}
                                </TableCell>
                                <TableCell align="center">
                                  <Grid
                                    container
                                    spacing={1}
                                    justifyContent={"center"}
                                  >
                                    {row.google ? (
                                      <Grid item>
                                        <img
                                          src={GOOGLE_ICON}
                                          alt="Google"
                                          height={20}
                                          width={20}
                                        />
                                      </Grid>
                                    ) : null}
                                    {row.apple ? (
                                      <Grid item>
                                        <img
                                          src={APPLE_ICON}
                                          alt="Apple"
                                          height={20}
                                          width={20}
                                        />
                                      </Grid>
                                    ) : null}
                                    {row.line ? (
                                      <Grid item>
                                        <img
                                          src={LINE_ICON}
                                          alt="Line"
                                          height={20}
                                          width={20}
                                        />
                                      </Grid>
                                    ) : null}
                                    {row.facebook ? (
                                      <Grid item>
                                        <img
                                          src={FACEBOOK_ICON}
                                          alt="Facebook"
                                          height={20}
                                          width={20}
                                        />
                                      </Grid>
                                    ) : null}
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box mt={3}>
                        <TablePagination
                          component="div"
                          rowsPerPageOptions={[
                            10,
                            25,
                            50,
                            100,
                            { label: "All", value: -1 },
                          ]}
                          count={props.users.total ?? 0}
                          onPageChange={(e, newPage) => {
                            setPage(newPage);
                            searchByCriteria(
                              newPage,
                              undefined,
                              false,
                              isFilter
                            );
                          }}
                          page={page}
                          rowsPerPage={limit ? limit : 10}
                          onRowsPerPageChange={(e: any) => {
                            setLimit(e.target.value);
                            setPage(0);
                            searchByCriteria(
                              page,
                              e.target.value,
                              true,
                              isFilter
                            );
                          }}
                        />
                      </Box>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} mt={1} mb={1}>
                  <Divider />
                </Grid>
                <Grid
                  container
                  xs={12}
                  mt={1}
                  mb={1}
                  justifyContent="right"
                  alignItems="center"
                >
                  <FormControlLabel
                    sx={{
                      marginLeft: "10px",
                    }}
                    control={
                      <Checkbox
                        checked={isPublish[isPublish.length - 1]}
                        onChange={handleChangeIsPublish}
                        name="isFilter"
                      />
                    }
                    label="แจ้งเตือนผู้ใช้งาน"
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="right"
                  alignItems="center"
                >
                  <Button
                    variant="outlined"
                    style={{ marginRight: "10px", width: "15%" }}
                    onClick={() => navigate("/notifications-list")}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    variant="contained"
                    style={{ width: "15%" }}
                    onClick={() => {
                      if (props.notificationId) {
                        setOpenConfirm({
                          open: true,
                          title: "แก้ไขข้อมูล",
                          message: "คุณต้องการแก้ไขข้อมูลนี้ใช่หรือไม่",
                          color: "primary",
                          type: "edit",
                        });
                      } else {
                        setOpenConfirm({
                          open: true,
                          title: "บันทึกข้อมูล",
                          message: "คุณต้องการบันทึกข้อมูลนี้ใช่หรือไม่",
                          color: "primary",
                          type: "create",
                        });
                      }
                    }}
                  >
                    บันทึก
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            handleDelete();
          } else if (openConfirm.type === "create") {
            handleSave();
          } else if (openConfirm.type === "edit") {
            handleEdit();
          }
        }}
      />
    </Box>
  );
};

export default NotificationCreateView;
